.referral-history-row-container{
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: start;
    margin-top: 16px;

}
.referral-history-row-name{
    color: #2A2A2A;
    font-family: Poppins-Regular;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.referral-history-row-class-date{
    color: #757575;
    font-family: Poppins-Regular;
    font-size: 12px;
    margin-top: 6px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.referral-history-row-karma-points{
    color: #2A2A2A;
    font-family: Poppins-Regular;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    margin-top: 10px;
    line-height: normal;
}

.referral-history-row-karma-points div{
    color: #2A2A2A;
    font-family: Poppins-Regular;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.referral-history-row-reminder{
    color: #2A2A2A;
    font-family: Poppins-Regular;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    width: 303px;
    margin-left: 6px;
    line-height: normal;
}

