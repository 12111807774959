.you-tube-container{
    display: flex;
    width:330px;
    height:284px;
    flex-direction: column;
    justify-content: start;
    align-items: flex-start;
}
.video-details{
    display: flex;
    width:330px;
    margin-top: 12px;
    height:84px;
    flex-direction: column;
    justify-content: start;
    align-items: flex-start;
}
