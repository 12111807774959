.referral-row-points-container{
    display: flex;
    align-items: center;
    margin-top: 16px;

}
.referral-row-points-value{
    color: #2A2A2A;
    font-family: Poppins-Regular;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    width: 303px;
    margin-left: 6px;
    line-height: normal;
}

