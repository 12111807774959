.login-container {
	justify-content: center;
	align-items: center;
	text-align: center;
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 400px;
	margin: auto;
}

.login-cta {
	color: #534de0;
	font-family: Poppins-Regular;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	margin: 16px;
	line-height: 20px; /* 166.667% */
	text-decoration-line: underline;
}

.landing-header {
	display: flex;
	width: 100%;
	max-width: 400px;
	margin-top: 2px;
	padding: 40px 16px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 24px;
	position: relative;
	overflow: hidden !important;
}

.landing-header-image {
	width: 266px;
	height: 56px;
}

.landing-header-title {
	color: #1b1625;
	text-align: center;
	font-family: Galaxie-Copernicus-Bold;
	font-size: 26px;
	font-weight: 500;
	line-height: 32px;
}

.landing-header-title img {
	width: 100%;
}

.landing-header-title span {
	color: #534de0;
}

.landing-header-subtitle {
	width: 65%;
	color: #1b1625;
	text-align: center;
	font-family: Galaxie-Copernicus-Book;
	font-size: 14px;
	margin-top: -22px;
}

.landing-header-description-one {
	color: #3a3a3a;
	text-align: center;
	font-family: Gordita-Medium;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	margin-top: -12px;
	line-height: 24px; /* 150% */
}

.landing-header-description-two {
	color: #3a3a3a;
	text-align: center;
	font-family: Lora-Bold;
	font-size: 18px;
	font-style: normal;
	font-weight: 700;
	margin-left: 16px;
	margin-right: 16px;
	align-self: stretch;
	line-height: 28px; /* 155.556% */
}

.landing-header-description-three {
	color: #3a3a3a;
	text-align: center;
	font-family: Poppins-Medium;
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	flex: 1 0 0;
	margin-left: 16px;
	margin-right: 16px;
	margin-top: 32px;
	line-height: 32px; /* 160% */
}

.landing-testimonials-title {
	color: #000;
	text-align: center;
	font-family: Galaxie-Copernicus-Book;
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: 28px; /* 125% */
	letter-spacing: 0.2px;
}

.landing-testimonials-why-us {
	color: #3a3a3a;
	text-align: center;
	align-self: stretch;
	font-family: Poppins-Bold;
	font-size: 18px;
	font-style: normal;
	font-weight: 700;
	line-height: 28px; /* 155.556% */
}

.landing-join-community-member {
	color: #3a3a3a;
	font-family: Poppins-Regular;
	font-size: 12px;
	margin-top: 12px;
	font-style: italic;
	font-weight: 400;
	line-height: 20px; /* 166.667% */
}
.landing-join-community-habit {
	text-align: center;
	font-style: normal;
	letter-spacing: 0.32px;
	color: #1b1625;
	font-family: Galaxie-Copernicus-Book;
	font-size: 20px;
	font-weight: 500;
	z-index: 5;
	line-height: 28px; /* 140% */
}

.landing-join-community-learn {
	color: #000;
	text-align: center;
	width: 240px;
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	z-index: 5;
	line-height: 28px; /* 125% */
	letter-spacing: 0.32px;
}

.landing-faq-title {
	color: #000;
	text-align: center;
	font-family: Galaxie-Copernicus-Book;
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: 28px; /* 120% */
	letter-spacing: 0.4px;
}

.vertical-info-card {
	display: flex;
	height: 100%;
	width: 90%;
	max-width: 400px;
	justify-content: center;
	flex-direction: column;
	align-items: start;
	border-radius: 12px;
	margin-top: 16px;
	padding-top: 16px;
	padding-left: 12px;
	background: #fff6ec;
}
.card-image {
	height: 224px;
	width: auto;
	align-self: center;
	max-width: 400px;
	margin-top: 12px;
	padding-bottom: 12px;
	z-index: 10;
	object-fit: cover;
	/*background: transparent;*/
}

.landing-card-title {
	color: #1b1625;
	width: 75%;
	text-align: start;
	font-family: Gordita-Medium;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 28px;
	letter-spacing: 0.2px;
}

.landing-card-subtitle {
	text-align: start;
	width: 90%;
	margin-top: 4px;
	/*text-transform: capitalize;*/
	color: var(--Body_Font, #4a4a4a);
	/* Body 2/Regular */
	font-family: Gordita-Regular;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px; /* 142.857% */
}
