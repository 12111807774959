.karma-points-container{
    display: flex;
    padding: 16px;
    margin: 0 auto 0 auto;
    width: 375px;
    flex-direction: column;
    justify-content: start;
    align-items: flex-start;
}
.karma-points-header{
    color: #FFF;
    text-align: center;
    font-family: Poppins-Regular;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px; /* 120% */
}

.karma-points-subheader{
    color: #FFF;
    text-align: left;
    align-self: start;
    font-family: Poppins-Regular;
    margin-left:25px;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 150% */
}

.karma-points-guide{
    color: #1A1A1A;
    font-family: Poppins-Regular;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.karma-points-guide-detail{
    color: #2A2A2A;
    font-family: Poppins-Regular;
    font-size: 14px;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
    margin-left: 4px;
}
