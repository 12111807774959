.view-all-container{
    display: flex;
    max-width: 88px;
    max-height: 32px;
    height: auto;
    flex-direction: row;
    padding: 6px 8px;
    align-items: center;
    border-radius: 12px;
    align-self: start;
    justify-content: space-between;
    border: 1px solid var(--Light-Grey-2, #E4E4E4);
    background: var(--White, #FFF);
}
.view-all-title{
    color: #000;
    font-family: Gordita-Medium;
    text-align: center;
    padding-top:2px;
    align-self: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 133.333% */
}
