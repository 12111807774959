.manage-reminder-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
}

.manage-reminder-header-container{
    width: 375px;
    display: flex;
    margin-left: 24px;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}

.manage-reminder-body-container{
    display: flex;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}


/* Media query for screen height of 812px and width of 375px */
@media screen and (max-width: 414px){
    .manage-reminder-container{
        width: 100%;
        height: auto;
        align-items: center;
    }
    .manage-reminder-body-container {
        width: 90%;
        height: auto;
        margin-left: 16px;
        margin-right: 16px;
        align-items: center;

    }
}

/* Additional media queries for other screen sizes if needed */
@media screen and (min-width: 414px) {
    .manage-reminder-container{
        width: auto;
        height: auto;
        display: flex;
        align-self: center;
        align-items: center;
    }
    .manage-reminder-body-container {
        width: 400px;
        height: auto;
        display: flex;
        align-self: center;
        align-items: center;

    }
}


.manage-reminder-title{
    width: 100%;
    color: #1B1625;
    text-align: start;
    font-family: Galaxie-Copernicus-Book;
    font-size: 24px;
    font-style: normal;
    margin-top: 24px;
    font-weight: 500;
    line-height: 32px; /* 150% */
}

.manage-reminder-header-title{
    width: 100%;
    color: #1B1625;
    text-align: start;
    margin-left: 18px;
    font-family: Gordita-Regular;
    font-size: 16px;
    margin-top: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 150% */
}

.manage-reminder-note{
    color: var(--Black-Shade, #1B1625);
    text-align: start;
    width: 100%;
    /* Label/Medium */
    font-family: Gordita-Medium;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.reminder-changed{
    color: #1A1A1A;
    text-align: center;
    font-family: Lora-Regular;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}


