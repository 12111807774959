.full-screen-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
}

.popup-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    max-width: 600px;
    width: 100%;
}

.popup-header {
    text-align: center;
    margin-bottom: 20px;
}

.popup-body {
    margin-bottom: 20px;
}

.scrollable-content {
    max-height: 400px; /* Adjust the maximum height as needed */
    overflow-y: auto;
}

.popup-footer {
    text-align: center;
    margin-bottom: 32px;
}

.popup-button {
    padding: 10px 20px;
    border-radius: 8px;
    background: var(--Primary-Gradient, linear-gradient(106deg, #4462EF 32.65%, #1B3DD8 105.18%));
    color: #fff;
    border: none;
    cursor: pointer;
}
