:root {
    --default-margin-2px: 2px;
    --default-margin-4px: 4px;
    --default-margin-6px: 6px;
    --default-margin-8px: 8px;
    --default-margin-10px: 10px;
    --default-margin-20px: 20px;
    --default-margin-30px: 30px;
    --default-margin-40px: 40px;
    --default-margin-60px: 60px;
    --default-padding: 10px;
}

.container{
    /*display: flex;*/
    /*flex-direction: column;*/
    /*width: 375px;*/
    /*margin: 56px auto 0 auto;*/
    /*justify-content: start;*/
    /*align-items: flex-start;*/
    /*padding: 16px;*/
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Arial', sans-serif;
}

.margin-top-30px {
    margin-top: var(--default-margin-30px) ;

}
.margin-top-40px {
    margin-top: var(--default-margin-40px) ;

}
.margin-top-60px {
    margin-top: var(--default-margin-40px) ;

}

.margin-top-10px {
    margin-top: var(--default-margin-10px) ;

}

.margin-top-20px {
    margin-top: var(--default-margin-20px) ;
}

.margin-left-8px {
    margin-left: var(--default-margin-8px) ;
}

.margin-right-8px {
    margin-right: var(--default-margin-8px) ;
}

.flex-start {
    display: flex;
    justify-content: flex-start ;
}

.full-width{
    width: 100%;
}

.margin-left-10px {
    margin-top: var(--default-margin-20px) ;

}

.margin-left-2px {
    margin-left: var(--default-margin-2px) ;
}

.horizontal-container{
    display: flex;
    width: 354px;
    flex-direction: row;
}

.vertical-container{
    display: flex;
    width: 50%;
    flex-direction: column;
}


.default-padding {
    padding: var(--default-padding);
}
