.course-description-container {
	display: flex;
	width: 100%;
	margin: auto;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	align-self: center;
	overflow-x: hidden;
}

.course-description-container.homework {
	margin-left: 0;
	padding: 0 0 16px;
}

.course-description-container.dark {
	background-color: #1b1625;
}

.course-description-bg {
	width: 360px;
	height: 360px;
	flex-shrink: 0;
	border-radius: 0;
	z-index: -10;
}

.course-description-image-banner {
	width: 100%;
	border-radius: 12px;
}

.weekly-course-tab-list {
	list-style: none;
	padding: 0;
	width: 95%;
	height: auto;
	display: flex;
	margin-top: 12px;
	background: #f4f4f4;
	border-radius: 6px;
	text-align: center;
	justify-content: space-between;
	align-items: center;
}

.weekly-course-tab-list .react-tabs__tab {
	border: none;
	cursor: pointer;
	padding: 12px;
	width: 33%;
	border-radius: 10px;
	text-align: center;
	align-self: center;
	background: #f4f4f4;
	color: '#1A1A1A';
	font-family: Poppins-Regular;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
}

.weekly-course-tab-list .react-tabs__tab--selected {
	border-radius: 6px;
	background: #faa037;
	width: 33%;
	padding: 12px;
	text-align: center;
	align-self: center;
	box-shadow: 1px 2px 12px 0px rgba(17, 24, 39, 0.04);
	color: white; /* Text color for selected tab */
	font-family: Poppins-Regular;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
}

.weekly-course-tab-item {
	color: #1a1a1a;
	font-family: Poppins-Regular;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 160%; /* 19.2px */
	letter-spacing: 0.2px;
}

.vertical-aligned-component {
	display: flex;
	flex-direction: column;
	align-items: start;
	margin-top: 12px;
	width: 344px;
	padding: 16px;
	border-radius: 14px;
	border-radius: 14px;
	border: 1px solid #e4e4e4;
	background: #fff;
}

.info {
	text-align: start;
	display: flex;
	flex-direction: column;
	align-items: start;
	justify-content: start;
}

.course-title {
	/*color: #8B2EFF;*/
	/*font-family: Poppins-Medium;*/
	/*font-size: 12px;*/
	/*font-style: normal;*/
	/*text-align: start;*/
	/*font-weight: 500;*/
	/*line-height: 16px; !* 133.333% *!*/

	color: #4a4a4a;
	font-family: Gordita-Medium;
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: 16px; /* 133.333% */
}

.tab-name {
	/*color: #2A2A2A;*/
	/*font-family: Poppins-Medium;*/
	/*font-size: 16px;*/
	/*font-style: normal;*/
	/*font-weight: 500;*/
	/*line-height: 24px; !* 150% *!*/

	color: #1b1625;
	font-family: Gordita-Medium;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 24px; /* 150% */
}

.time-slots {
	color: #2a2a2a;
	font-family: Poppins-Regular;
	font-size: 10px;
	font-style: italic;
	font-weight: 400;
	line-height: normal;
}

.description {
	color: #4a4a4a;
	font-family: Gordita-Regular;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px; /* 142.857% */
	margin-top: 8px;
}

.page-section-title {
	color: #4a4a4a;
	font-family: Gordita-Medium;
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: 28px; /* 140% */
	margin-top: 28px;
}

.course-page-section-title {
	color: #ffffff;
	font-family: Gordita-Medium;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	margin-top: 28px;
	width: 100%;
	display: flex;
	align-items: center;
}

@media only screen and (max-width: 420px) {
	.course-description-image-banner {
		width: 344px;
		height: auto;
		object-fit: cover;
		height: 221px;
		align-items: center;
		border-radius: 12px;
	}
}

@media only screen and (min-width: 601px) and (max-width: 2000px) {
	.course-description-image-banner {
		/* Adjust styles for screens with a width between 601px and 900px */
		height: 221px; /* For example, set a different height */
		width: 344px;
		align-self: center;
		object-fit: cover;
	}
}

.course-description-title {
	color: #000;
	font-family: Lora-Medium;
	font-size: 24px;
	font-style: normal;
	font-weight: 500;
	line-height: 32px; /* 133.333% */
}

.course-description-review {
	color: #2a2a2a;
	font-family: Poppins-Medium;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: 26px; /* 144.444% */
}

.course-description-review-count {
	color: #534de0;
	font-family: Poppins-SemiBold;
	font-size: 10px;
	font-style: normal;
	font-weight: 600;
	line-height: 14px; /* 140% */
	padding: 4px 8px;
	align-items: flex-start;
	border-radius: 200px;
	background: #e4e4ff;
}

.course-description-subtitle {
	color: #757575;
	font-family: Poppins-Regular;
	text-align: start;
	font-size: 16px;
	margin-top: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 28px; /* 175% */
}

.course-body-description-title {
	color: #2a2a2a;
	font-family: Poppins-Regular;
	width: 160px;
	text-align: start;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	margin: 0px 8px 0px 12px;
}

.course-body-container {
	display: flex;
	width: 343px;
	flex-direction: row;
	justify-content: start;
	align-items: center;
}

.course-enrolled-toggle-container {
	margin-top: 24px;
	border-radius: 8px;
	border: 1px solid #d6d6d6;
	background: #fff;
	padding: 12px;
	justify-content: space-between;
	align-items: center;
}

.course-plan-description-review-profile {
	display: flex;
	width: 64px;
	height: 64px;
	padding: 8px;
}

.course-plan-description-review-name {
	color: #2a2a2a;
	font-family: Poppins-Medium;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 24px; /* 150% */
}

.course-plan-description-review-period {
	color: #534de0;
	font-family: Poppins-SemiBold;
	font-size: 10px;
	font-style: normal;
	font-weight: 600;
	line-height: 16px; /* 160% */
}

.course-plan-description-review-bio {
	color: #868686;
	font-family: Poppins-Regular;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px; /* 166.667% */
}

.course-plan-description-faq-title {
	color: #000;
	font-family: Lora-Medium;
	font-size: 24px;
	font-style: normal;
	text-align: center;
	font-weight: 500;
	line-height: 32px; /* 133.333% */
}
.course-payment-success-hurray {
	color: var(--Black, #1b1625);
	text-align: center;
	font-family: Gordita-Medium;
	margin-top: 24px;
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: 28px; /* 140% */
}
.course-payment-success-plan-description {
	color: var(--Body-Font, #4a4a4a);
	text-align: center;
	font-family: Gordita-Regular;
	font-size: 16px;
	font-style: normal;
	width: 312px;
	font-weight: 400;
	line-height: 24px; /* 150% */
}

.course-plan-description-instructor-image-container {
	position: relative;
	display: inline-block;
}

.course-plan-description-instructor-image {
	width: 100%;
	height: 100%;
	background-size: cover;
	background-position: center;
}

.course-plan-description-instructor-details {
	position: absolute;
	top: 10px;
	left: 10px;
	display: flex;
	flex-direction: column;
	color: white;
}

.course-plan-description-instructor-experience {
	font-size: 14px;
}

.course-plan-description-instructor-name {
	font-size: 18px;
	margin-top: auto;
	text-align: right;
}

.course-number-of-enrollment {
	color: #a4a4a4;
	font-family: Poppins-Medium;
	font-size: 10px;
	font-style: italic;
	margin-top: 4px;
	font-weight: 500;
	line-height: 16px; /* 160% */
}

.upcoming-course-subheader-container {
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 56px;
	gap: 16px;
	border-radius: 12px;
	background: #fff;
	margin-top: 8px;
	justify-content: center;
	align-items: center;
	gap: 6px;
}

.upcoming-course-subheader-container.dark {
	background-color: transparent;
}

.upcoming-course-subheader-container.homework {
	border: 1px solid #f5eeff;
	gap: 4px;
}

.upcoming-course-subheader-subContainer {
	display: flex;
	flex: 1;
	flex-direction: row;
	height: 54px;
	justify-content: start;
	padding: 2px 8px 10px 8px;
	align-items: center;
	border-radius: 12px;
	border: 1px solid var(--Light-Grey-2, #e4e4e4);
	background: var(--White, #fff);
}

.upcoming-course-subheader-subContainer.dark {
	background-color: #232323;
	border: 1px solid var(--Light-Grey-2, #4a4a4a);
}

.upcoming-course-subheader-subContainer.homework {
	border: none;
	padding: 0;
	justify-content: center;
	align-items: center;
}

.upgrade-course-risk-free-disclaimer {
	display: flex;
	padding: 8px 12px;
	align-items: center;
	gap: 12px;
	flex: 1 0 0;
	margin-top: 24px;
	border-radius: 12px;
	background: #f4f2ff;
	flex-direction: row;
	width: 100%;
	height: 100%;
	justify-content: start;
}

.upcoming-course-subheader-title {
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 100%;
	justify-content: start;
	align-items: start;
	align-self: start;
	margin-top: 6px;
	color: #1b1625;
	font-family: Gordita-Medium;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 20px;
}

.upcoming-course-subheader-title.homework {
	margin-top: 0;
	font-size: 13px;
	margin-bottom: 0;
}

.upcoming-course-subheader-title.dark {
	color: #ffffff;
}

.upcoming-course-subheader-subtitle {
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 100%;
	justify-content: start;
	align-self: start;
	align-items: start;
	color: #2a2a2a;
	font-family: Poppins-Regular;
	align-self: start;
	font-size: 10px;
	font-style: normal;
	font-weight: 400;
	text-align: start;
	line-height: 16px; /* 160% */
}

.upcoming-course-subheader-subtitle.dark {
	color: #a4a4a4;
}

.upcoming-course-page-timing-title {
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 100%;
	justify-content: start;
	align-self: start;
	align-items: start;
	font-style: normal;
	color: #ffffff;
	font-family: Gordita-Medium;
	font-size: 16px;
	font-weight: 500;
	line-height: 24px; /* 150% */
}

.upcoming-course-page-slot-timing {
	display: flex;
	padding: 8px;
	gap: 6px;
	flex-direction: row;
	flex-wrap: wrap;
	width: 100%;
	height: auto;
	margin-top: 8px;
	margin-bottom: 28px;
	border-left: 2.4px solid #faa53d;
	background: #232323;
	justify-content: start;
	align-self: start;
	align-items: start;
	font-style: normal;
	color: var(--Body-Font, #ffffff);
	font-family: Gordita-Medium;
	font-size: 16px;
	font-weight: 500;
	line-height: 24px; /* 150% */
	white-space: normal;
}

.upcoming-course-page-slot-value {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin: 0;
	justify-content: center;
	align-self: center;
	align-items: center;
	font-style: normal;
	color: #ffffff;

	font-family: Gordita-Medium;
	font-size: 12px;
	/*margin-left: 4px;*/
	font-weight: 500;
	line-height: 16px; /* 133.333% */
}

.svg-divider {
	/*margin-left: 0px;*/
	margin-right: 8px;
	align-self: center;
}

.upcoming-course-page-timing-subtitle {
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 100%;
	justify-content: start;
	align-self: start;
	align-items: start;
	color: var(--Body-Font, #ffffff);
	font-family: Gordita-Regular;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px; /* 142.857% */
}

.module-course-card-collapsed {
	display: flex;
	width: 345px;
	padding: 12px;
	margin-top: 12px;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
	gap: 8px;
	border-radius: 12px;
	background: #fff;
	/*box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.08);*/
	/*border-radius: 12px;*/
	border: 1.6px solid #e4e4e4;
	/*background: #FFF;*/
}

.module-course-card-expanded {
	display: flex;
	width: 345px;
	padding: 12px;
	margin-top: 12px;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
	gap: 8px;
	border-top: 2px solid #ef9e3a;
	background: #fff6ec;
}
.module-course-card-single-week {
	display: flex;
	padding: 12px;
	margin-top: 8px;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 10px;
	align-self: stretch;
	border-radius: 14px;
	width: 100%;
	border: 1px solid #f4f4f4;
	background: #fff;
}

.upcoming-course-highlights {
	color: #2a2a2a;
	font-family: Poppins-Regular;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	margin-top: 8px;
	line-height: 20px; /* 142.857% */
}

.highlights-point {
	color: #2a2a2a;
	width: 312px;
	font-family: Poppins-Regular;
	font-size: 12px;
	padding-right: 12px;
	margin-left: 4px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px; /* 166.667% */
}
.upcoming-course-description {
	color: #2a2a2a;
	font-family: Poppins-Medium;
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

.upcoming-course-day-by-day-schedule {
	color: #2a2a2a;
	font-family: Lora-Regular;
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	margin-top: 32px;
	line-height: 28px; /* 140% */
}
.upcoming-course-about-kundalini {
	color: #2a2a2a;
	font-family: Lora-Regular;
	font-size: 20px;
	font-style: normal;
	text-align: start;
	font-weight: 400;
	line-height: 28px;
	margin-top: 32px;
}
.upcoming-course-details-kundalini {
	color: #2a2a2a;
	font-family: Lora-Regular;
	font-size: 14px;
	font-style: normal;
	text-align: start;
	font-weight: 400;
	margin-top: 10px;
	line-height: 24px;
}
.upcoming-course-target-title {
	color: #2a2a2a;
	font-family: Lora-Medium;
	font-size: 16px;
	width: 100%;
	font-style: normal;
	font-weight: 500;
	margin-top: 12px;
	line-height: 28px;
}
.upcoming-course-target-subtitle {
	color: #2a2a2a;
	font-family: Lora-Regular;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	margin-top: -12px;
	width: 100%;
	line-height: 24px;
}

.course-whatsapp-bottom {
	width: 101%;
	margin-left: -4%;
	margin-bottom: 96px;
	margin-top: 16px;
}

.course-anger-handbook {
	width: 343px;
	padding: 12px;
	background-color: #fffaf3;
	border-radius: 12px;
	display: flex;
	margin-top: 24px;
}

.course-anger-handbook-info p {
	margin-bottom: 0;
}

.course-anger-handbook-info p:not(:last-child) {
	margin-bottom: 4px;
}

.course-anger-handbook-info-heading {
	color: #4a4a4a;
	font-family: Gordita-Regular;
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
}

.course-anger-handbook-info-title {
	color: #1b1625;
	font-family: Gordita-Medium;
	font-size: 20px;
	font-weight: 500;
	line-height: 28px;
}

.course-anger-handbook-info-subtitle {
	color: #4a4a4a;
	font-family: Gordita-Regular;
	font-size: 12px;
	font-weight: 400;
	line-height: 16px;
}

.course-anger-handbook-img img {
	width: 126px;
	transform: rotate(15deg);
}

.course-slider-container {
	max-width: 402px;
	padding: 0 29px;
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	border-radius: 12px 12px 0 0;
	z-index: 10000000000;
	top: -2px;
	cursor: pointer;
	background-color: #1b1625;
}

.course-slider {
	width: 100%;
}

.course-slider-tab {
	padding: 4px 0;
	margin-top: 16px;
	border-bottom: 2px solid transparent;
}

.course-slider-tab.active {
	border-bottom: 2px solid #faa53d;
}

.course-slider-tab-title {
	font-family: Gordita-Medium;
	font-size: 14px;
	color: #d6d6d6;
	margin-bottom: 0;
}

.course-slider-tab.active .course-slider-tab-title {
	color: #faa53d;
}

.course-slider .swiper-slide {
	max-width: max-content !important;
}

.page-section-title-scroll {
	scroll-margin: 90px;
}

.page-section-title-scroll:first-child {
	scroll-margin: 106px;
}

.course-what-you-get {
	padding: 12px;
	border-radius: 8px;
	border: 0.5px solid #4a4a4a;
	background: linear-gradient(138deg, #242751 23.7%, #232323 57.18%);
}

.course-get-recordings {
	position: relative;
}

.course-get-recordings img {
	width: 100%;
}

.course-get-recordings > div {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	padding: 8px;
	display: flex;
	height: 100%;
	flex-direction: column;
	justify-content: space-between;
}

.course-get-recordings-title {
	font-family: Gordita-Medium;
	color: #1b1625;
	font-size: 20px;
	line-height: 1.4;
	margin-bottom: 0;
}

.course-get-recordings-amount {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.course-get-recordings-amount p {
	margin-bottom: 0;
}

.course-get-recordings-offer {
	font-family: Gordita-Medium;
	color: #1b1625;
	font-size: 16px;
}

.course-get-recordings-actual {
	font-family: Gordita-Medium;
	color: #4a4a4a;
	font-size: 12px;
	text-decoration: line-through;
}

.course-get-recordings-button {
	border: 1px solid #4462ef;
	padding: 4px 8px 2px;
	border-radius: 8px;
	background-color: #ffffff;
	color: #4462ef;
	font-family: Gordita-Medium;
	font-size: 12px;
	display: flex;
	align-items: center;
	gap: 4px;
}

.course-get-recordings-button span {
	font-size: 16px;
	display: block;
	width: max-content;
}

.course-page-benefits {
	width: 100%;
	max-width: 344px;
	margin-top: 16px;
	display: flex;
	gap: 12px;
	flex-wrap: wrap;
}

.course-page-benefits-row {
}

.course-page-benefits-row-item {
	background-color: #232323;
	color: white;
	font-family: 'Gordita-Medium';
	text-align: center;
	border-radius: 8px;
	padding: 8px;
	width: calc((344px - 12px) / 2);
}

.course-page-benefits-row-item p {
	margin-bottom: 0;
}

.course-what-you-get-item {
	display: flex;
	gap: 12px;
	color: white;
}

.course-what-you-get-item-icon {
	min-width: 41px;
	max-width: 41px;
	width: 41px;
	height: 41px;
}

.course-what-you-get-item-icon img {
	width: 100%;
	height: 100%;
}

.course-what-you-get-item-info p {
	margin-bottom: 0;
	color: white;
}

.course-what-you-get-item-info-title {
	font-family: Gordita-Medium;
	font-size: 16px;
}

.course-what-you-get-item-info-subtitle {
	font-family: Gordita-Regular;
	font-size: 14px;
}

.course-what-you-get-item-divider {
	width: 100%;
	border-bottom: 1px dashed #4a4a4a;
	margin: 12px 0;
}

.course-curriculum-week {
	margin-top: 16px;
	padding: 12px;
	width: 344px;
	border-radius: 8px;
	border: 0.5px solid #4a4a4a;
	overflow: hidden;
	transition: all 0.4s;
	background: linear-gradient(138deg, #242751 23.7%, #232323 57.18%);
}

.course-curriculum-week.light img {
	filter: brightness(0) saturate(100%) invert(31%) sepia(30%) saturate(5714%)
		hue-rotate(224deg) brightness(98%) contrast(91%);
}

.course-curriculum-week-header {
	display: flex;
	justify-content: space-between;
	align-items: start;
}

.course-curriculum-week-header img {
	margin-top: 2px;
}

.course-curriculum-week-title {
	margin-bottom: 16px;
	font-family: 'Gordita-Medium';
	color: white;
	font-size: 20px;
}

.course-curriculum-week-day-title {
	font-family: 'Gordita-Medium';
	font-size: 16px;
	color: #b8acf6;
	margin-bottom: 0;
}

.course-curriculum-week-day-divider {
	border-bottom: 1px dashed #4a4a4a;
	margin: 16px 0;
}

.course-curriculum-week-day-module {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 8px;
}

.course-curriculum-week-day-module-text-title {
	color: #a4a4a4;
	text-transform: uppercase;
	font-size: 12px;
	font-family: 'Gordita-Medium';
	margin-bottom: 0;
}

.course-curriculum-week-day-module-text-title.light {
	font-family: 'Gordita-Regular';
	color: #4462ef;
}

.course-curriculum-week-day-module-text-subtitle {
	color: #fff;
	font-size: 16px;
	font-family: 'Gordita-Medium';
	margin-bottom: 0;
}

.course-curriculum-week-day-module-text-subtitle.light {
	color: #1b1625;
}

.course-curriculum-week-day-module-tag {
	padding: 2px 6px;
	font-size: 10px;
	font-family: 'Gordita-Medium';
	color: #1b1625;
	border-radius: 12px;
	min-width: 90px;
	text-align: center;
	margin-left: 8px;
}

.course-curriculum-week-day-bio {
	font-family: 'Gordita-Regular';
	color: #e4e4e4;
	font-size: 14px;
	margin-top: 8px;
}

.course-curriculum-week-day-bio.light {
	color: #4a4a4a;
}

.course-curriculum-week-day-details {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 8px;
}

.course-curriculum-week-day-details div {
	display: flex;
	align-items: center;
}

.course-curriculum-week-day-details div p {
	margin-bottom: 0;
	color: #e4e4e4;
	font-family: 'Gordita-Regular';
	font-size: 12px;
	margin-left: 4px;
}

.course-curriculum-week-day-details div p.light {
	color: #4a4a4a;
}

.course-curriculum-week-day-info {
	margin-top: 8px;
	display: flex;
	gap: 8px;
	flex-wrap: wrap;
}

.course-curriculum-week-day-info-item {
	width: 154px;
	border-radius: 8px;
}

.course-curriculum-week-day-info-item.light {
	width: calc(50% - 10px);
}

.course-curriculum-week-day-info-item-title {
	margin-bottom: 0;
	color: #ffffff;
	font-size: 14px;
	font-family: 'Gordita-Regular';
}

.course-curriculum-week-day-info-item-title.light {
	color: #1b1625;
}

.course-curriculum-week-day-info-item-subtitle {
	margin-bottom: 0;
	color: #a4a4a4;
	font-family: 'Gordita-Regular';
	font-size: 12px;
}

.course-curriculum-week-day-info-item-subtitle.light {
	color: #4a4a4a;
}

.course-curriculum-download {
	display: flex;
	justify-content: end;
	margin-top: 16px;
	width: 344px;
}

.course-curriculum-download-button {
	background-color: #232323;
	border: 1px solid #4a4a4a;
	padding: 8px 12px;
	display: flex;
	align-items: center;
	gap: 4px;
	border-radius: 8px;
}

.course-curriculum-download-button p {
	font-family: 'Gordita-Medium';
	font-size: 12px;
	margin-bottom: 0;
	color: #ffffff;
}
