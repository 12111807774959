.account-created-container{
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    max-width: 400px;
    margin-top: 148px;

}
.account-header-container {
    display: flex;
    width: 95%;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
}

.account-created-body-container{
    display: flex;
    width: 90%;
    background:#F4F4F4;
    border-radius: 12px;
    padding: 16px;
    justify-content: center;
    flex-direction: column;
    align-items: start;
}


/* Media query for screen height of 812px and width of 375px */
@media screen and (max-width: 414px) and (min-height: 640px) and (max-height: 740px){
    .account-created-container{
        width: 100%;
        height: auto;
        justify-content: center;
        align-items: center;
    }
    .account-header-container {
        width: 95%;
        height: auto;
        justify-content: space-between;
        align-items: center;

    }
    .account-created-body-container{
        width: 90%;
        background:#F4F4F4;
        align-items: flex-start;
        margin-top: 40px;
        justify-content: center;

    }
}





/* Media query for screen height of 812px and width of 375px */
@media screen and (max-width: 414px) and (min-height: 740px){
    .account-created-container{
        width: 100%;
        height: auto;
        justify-content: center;
        align-items: center;
    }
    .account-header-container {
        width: 95%;
        height: auto;
        justify-content: space-between;
        align-items: center;

    }
    .account-created-body-container {
        width: 90%;
        background:#F4F4F4;
        align-items: flex-start;
        margin-top: 64px;
        justify-content: center;

    }
}


/* Media query for screen height of 812px and width of 375px */
@media screen and (min-width: 414px) and (min-height: 912px){
    .account-created-container{
        width: 400px;
        height: auto;
        justify-content: center;
        align-items: center;
    }
    .account-header-container {
        width: 375px;
        height: auto;
        display: flex;
        align-self: center;
        align-items: center;

    }
    .account-created-body-container {
        width: 375px;
        max-width: 400px;
        margin-top: 92px;
        display: flex;
        align-self: center;
        align-items: flex-start;

    }
}

.account-title{
    color: #1B1625;
    text-align: center;
    font-family: Gordita-Medium;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 140% */
}

.account-description{
    color: #2A2A2A;
    text-align: center;
    font-family: Gordita-Regular;
    font-size: 16px;
    width: 284px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-left: 6px;
}
.enrolled-toggle-container{
    margin-top: 24px;
    border-radius: 8px;
    border: 1px solid #D6D6D6;
    background: #FFF;
    padding: 12px;
    justify-content: space-between;
    align-items: center;
}

