.animated-text-title{
    font-style: normal;
    letter-spacing: 0.32px;
    font-weight: 500;
    z-index: 5;
    color: #EF3A3A;
    text-align: center;
    font-family: Gordita-Medium;
    font-size: 28px;
    line-height: 36px; /* 128.571% */
}
