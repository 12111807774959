.attendance-card-container {
    border-radius: 16px;
    width: 90%;
    max-width: 400px;
    height: auto;
    margin-top: 8px;
    display: flex;
    flex-direction: column;
}

.attendance-title {
    color: var(--Black, #1B1625);
    font-family: Gordita-Medium;
    font-size: 16px;
    font-style: normal;
    text-align: start;
    font-weight: 500;
    line-height: 24px; /* 150% */
}

.attendance-subtitle {
    color: var(--Body-Font, #4A4A4A);
    font-family: Gordita-Medium;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 166.667% */
}
