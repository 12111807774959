.back-button-container {
	display: flex;
	max-width: 84px;
	max-height: 32px;
	height: auto;
	flex-direction: row;
	padding: 8px 16px;
	align-items: center;
	border-radius: 12px;
	align-self: start;
	justify-content: space-between;
	border: 1px solid var(--Light-Grey-2, #e4e4e4);
	background: var(--White, #fff);
}

.back-button-container.dark {
	background-color: #00000000;
}

.back-button-container.dark .back-button-title {
	color: #fff;
}

.back-button-title {
	color: #000;
	font-family: Gordita-Medium;
	text-align: center;
	padding-top: 2px;
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: 16px; /* 133.333% */
}
