.course-gift-modal-image {
	width: 80%;
	margin-left: 10%;
	margin-top: 24px;
}

.course-gift-modal-title {
	color: #ffffff;
	text-align: center;
	font-family: Gordita-Medium;
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	margin-top: 16px;
	margin-bottom: 0;
}

.course-gift-modal-subtitle {
	color: #a4a4a4;
	text-align: center;
	font-family: Gordita-Medium;
	font-size: 12px;
	font-weight: 500;
	margin-top: 2px;
	margin-bottom: 24px;
}

.course-gift-modal-close {
	position: absolute;
	top: 16px;
	right: 16px;
}
