.seek-guru-card-container {
    display: flex;
    padding: 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    border-radius: 12px;
    margin-top: 16px;
    background: var(--White, #FFF);
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);


}

.seek-guru-card-image-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 84px;
    height: 64px;
    border-radius: 8px;
    /*height: auto;*/
}

.seek-guru-card-horizontal{
    display: flex;
    align-items: center;
}

.seek-guru-card-image-overlay {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 84px;
    min-height: 84px;
    height: auto;
    opacity: 0.5;
}


/* Media query for screen height of 812px and width of 375px */
@media screen and (max-width: 414px){
    .seek-guru-card-container{
        width: 95%;
        height: auto;
    }
    .seek-guru-card-image-container {
        width: 112px;
        height: 84px;
        align-items: center;
    }
    .seek-guru-card-image-overlay{
        width: 84px;
        height: 84px;
        align-items: center;
        background: rgba(0, 0, 0, 0.5); /* Transparent overlay */
    }
}

/* Additional media queries for other screen sizes if needed */
@media screen and (min-width: 414px) {
    .seek-guru-card-container{
        width: 400px;
        height: auto;
    }
    .seek-guru-card-image-container {
        width: 112px;
        height: 84px;
    }
}


.seek-guru-image-wrapper {
    position: relative;
}


.online-indicator {
    position: absolute;
    bottom:4px;
    right: -8px;
}


.seek-guru-card-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-self: flex-start;
    margin-left: 12px;
    /*margin-top: 12px;*/

}


.seek-guru-card-title {
    color: var(--Black, #1B1625);
    font-family: Gordita-Medium;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    align-self: flex-start;
    text-align: start;
    /*line-height: 24px; !* 150% *!*/
}

.seek-guru-card-subtitle {
    color: #4A4A4A;
    font-family: Gordita-Regular;
    font-size: 12px;
    font-style: italic;
    align-self: flex-start;
    text-align: start;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
}

.seek-guru-card-description{
    color: #2A2A2A;
    font-family: Gordita-Regular;
    align-self: flex-start;
    text-align: start;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
}
.seek-guru-card-icons-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around; /* Distribute space around the items */
}

.seek-guru-card-buttons-container {
    display: flex;
    justify-content: space-around;
    margin-top: 10px;
    width: 100%;
}

.seek-guru-card-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.seek-guru-card-button:hover {
    background-color: #0056b3;
}
.top-left-content {
    position: absolute;
    top: 24px; /* Adjust this value to position vertically */
    left: 24px; /* Adjust this value to position horizontally */
    border-radius: 4px;
    border: 0.54px solid #FA3D3D;
    background: #FFE4E4;
    padding: 5px 10px; /* Adjust padding as needed */
    color: #000; /* Text color */
    font-size: 14px; /* Font size */
}



.seek-guru-centre-most-content {
    position: absolute;
    top: 25%;
    left: 16%;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    transform: translate(-50%, -50%);
    border-radius: 4px;
    padding: 5px 10px; /* Adjust padding as needed */
    color: #000; /* Text color */
    font-size: 14px; /* Font size */
}
