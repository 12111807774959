.plan-description-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	align-self: center;
	margin-top: 12px;
	width: 100%;
}

.plan-description-title {
	color: #000;
	font-family: Lora-Regular;
	font-size: 24px;
	font-style: normal;
	font-weight: 500;
	line-height: 32px; /* 133.333% */
}

.plan-description-component-wrapper {
	display: flex;
	width: 100%;
	padding-bottom: 16px;
	flex-direction: column;
	align-items: center;
	/*max-width: 400px;*/
	/*background: var(--Light-Grey-1, #F4F4F4);*/
}
.plan-description-review {
	color: #2a2a2a;
	font-family: Poppins-Regular;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: 26px; /* 144.444% */
}

.plan-description-review-count {
	color: #534de0;
	font-family: Poppins-Regular;
	font-size: 10px;
	font-style: normal;
	font-weight: 600;
	line-height: 14px; /* 140% */
	padding: 4px 8px;
	align-items: flex-start;
	border-radius: 200px;
	background: #e4e4ff;
}

.plan-description-subtitle {
	color: #757575;
	font-family: Poppins-Regular;
	text-align: start;
	font-size: 16px;
	margin-top: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 28px; /* 175% */
}

.body-description-title {
	color: #2a2a2a;
	font-family: Poppins-Regular;
	width: 160px;
	text-align: start;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	margin: 0px 8px 0px 12px;
}
.body-container {
	display: flex;
	width: 343px;
	flex-direction: row;
	justify-content: start;
	align-items: center;
}

.enrolled-toggle-container {
	margin-top: 24px;
	border-radius: 8px;
	border: 1px solid #d6d6d6;
	background: #fff;
	padding: 12px;
	justify-content: space-between;
	align-items: center;
}

.plan-description-review-profile {
	display: flex;
	width: 64px;
	height: 64px;
	padding: 8px;
}

.plan-description-review-name {
	color: var(--Black, #1b1625);
	font-family: Gordita-Medium;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 20px; /* 142.857% */
}

.plan-description-review-period {
	color: var(--Font-grey, #a4a4a4);
	font-family: Gordita-Medium;
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: 16px; /* 133.333% */
}

.plan-description-review-bio {
	color: var(--Font-grey, #a4a4a4);
	font-family: Gordita-Regular;
	font-size: 14px;
	width: 80%;
	font-style: normal;
	font-weight: 400;
	margin-top: 8px;
	line-height: 20px; /* 142.857% */
}

.plan-description-faq-title {
	color: var(--Black, #1b1625);
	font-family: Galaxie-Copernicus-Book;
	font-size: 20px;
	margin-top: 32px;
	font-style: normal;
	font-weight: 500;
	line-height: 28px; /* 140% */
}

.plan-description-instructor-image-container {
	position: relative;
	display: inline-block;
}

.plan-description-instructor-image {
	width: 100%;
	height: 100%;
	background-size: cover;
	background-position: center;
}

.plan-description-instructor-details {
	position: absolute;
	top: 10px;
	left: 10px;
	display: flex;
	flex-direction: column;
	color: white;
}

.plan-description-instructor-experience {
	font-size: 14px;
}

.plan-description-instructor-name {
	font-size: 18px;
	margin-top: auto;
	text-align: right;
}

.plan-description-people-stats {
	margin-top: 16px;
	max-width: 375px;
	width: 100%;
}

.plan-description-people-stats-row {
	display: flex;
	width: 100%;
	max-width: 375px;
	margin: 18px auto;
	gap: 16px;
}

.plan-description-people-stats-card {
	padding: 12px;
	border-radius: 12px;
	border: 1px solid #e4e4e4;
	background: linear-gradient(to bottom, #ffffff, #f4f4f4);
	flex: 1;
}

.plan-description-people-stats-card .percentage {
	font-family: Gordita-Bold;
	font-size: 28px;
	margin-bottom: 0;
}

.plan-description-people-stats-card .percentage-text {
	font-family: Gordita-Regular;
	font-size: 12px;
	margin-bottom: 16px;
	margin-top: -4px;
}

.plan-description-people-stats-card .text {
	color: #4a4a4a;
	font-family: Gordita-Regular;
	font-size: 12px;
	margin-bottom: 0;
	text-align: left;
}

.plan-description-people-stats-card .subtext {
	font-family: Gordita-Medium;
	color: #4a4a4a;
	font-size: 12px;
	margin-bottom: 0;
	text-align: left;
}

.plan-fixed-bottom-container {
	position: fixed;
	bottom: 0;
	/*left: 0;*/
	width: 100%;
	max-width: 420px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-self: center;
	align-items: center;
	border-radius: 12px 12px 0px 0px;
	background: #fff;
	padding: 12px 16px 24px 16px;
	z-index: 10000000001;
	flex-wrap: wrap;
	box-shadow: 0px -4px 12px 0px rgba(0, 0, 0, 0.08);
}

.plan-fixed-bottom-recordings {
	width: 100%;
	background-color: #1b1625;
	border-radius: 12px;
	padding: 12px;
	margin-bottom: 12px;
	display: flex;
	justify-content: space-between;
}

.plan-fixed-bottom-recordings-text p {
	margin-bottom: 0;
}

.plan-fixed-bottom-recordings-text-duration {
	font-size: 16px;
	color: #fff;
	font-family: Gordita-Regular;
}

.plan-fixed-bottom-recordings-text-price {
	font-size: 14px;
	color: #e28f29;
	font-family: Gordita-Bold;
}

.plan-fixed-bottom-recordings-text-price span {
	font-size: 12px;
	color: #fff;
	font-family: Gordita-Medium;
	text-decoration: line-through;
}

.plan-fixed-bottom-recordings-text-benefits {
	font-size: 12px;
	color: #fff;
	font-family: Gordita-Medium;
	text-decoration: underline;
}

.plan-fixed-bottom-recordings-button {
	display: flex;
	align-items: center;
}

.plan-fixed-bottom-button {
	margin-bottom: 10px; /* Add some space below the button */
	padding: 10px 20px;
	border-radius: 8px;
	/*background: var(--Primary-Color, #4462EF);*/
	background: var(
		--Primary-Gradient,
		linear-gradient(106deg, #4462ef 32.65%, #1b3dd8 105.18%)
	);
	border: none;
	width: 50%;
	text-align: center;
	cursor: pointer;
	color: #fff;
	font-family: Gordita-Medium;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 20px; /* 142.857% */
}

button[disabled].plan-fixed-bottom-button {
	background: #a4a4a4 !important;
}

.plan-fixed-bottom-button:hover {
	background-color: #0056b3;
}

.plan-fixed-bottom-price {
	color: var(--Black, #1b1625);
	font-family: Gordita-Medium;
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: 28px; /* 140% */
}
.plan-fixed-bottom-duration {
	color: var(--Font-grey, #a4a4a4);
	font-family: Gordita-Medium;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 20px; /* 142.857% */
}

.plan-description-people-stats-img {
	width: 70%;
}

.plan-description-pointer:not(:first-child) {
	display: flex;
	gap: 8px;
	align-items: start;
	margin-top: 24px;
}

.plan-description-pointer:last-child {
	gap: 4px;
}

.plan-description-pointer img {
	margin-top: 1px;
	width: 20px;
}

.plan-description-pointer:last-child img {
	width: 24px;
	animation: blip 1s infinite;
}

.plan-description-pointer p {
	font-family: 'Gordita-Regular';
	font-size: 14px;
	color: '#4a4a4a';
	margin-bottom: 0;
}

.plan-description-pointer p:last-child {
	font-family: 'Gordita-Medium';
	font-size: 12px;
	color: '#4a4a4a';
}

.plan-description-track {
	position: absolute;
	top: 14%;
	height: 76%;
	left: 10px;
	border-right: 1px dashed #dddddd;
	z-index: -1;
}

@keyframes blip {
	0% {
		transform: scale(0.8);
	}
	50% {
		transform: scale(1);
	}
	100% {
		transform: scale(0.8);
	}
}
