.empty-referral-page-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.empty-referral-page-title{
    color: #000;
    font-family: Lora-Regular;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 40px; /* 166.667% */
    letter-spacing: 0.48px;
    text-align: center;
    width: 343px;
}

.empty-referral-page-subtitle{
    color: #2A2A2A;
    font-family: Poppins-Regular;
    font-size: 16px;
    font-style: normal;
    text-align: center;
    margin-top: 6px;
    font-weight: 400;
    margin-bottom: 10px;
    line-height: 24px; /* 150% */
}
