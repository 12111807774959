.leaderboard-points-row {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    height: 40px;
    padding-left: 12px;
    padding-right: 12px;
    flex: 5;
}

.leaderboard-current-user{
    width: 316px;
    padding-left: 4px;
    /*padding-right: 4px;*/
    margin-left: -4px;
    align-self: center;
}

.leaderboard-name{
    color: #4A4A4A;
    font-family: Gordita-Regular;
    font-size: 16px;
    flex: 3;
    font-style: normal;
    text-align: start;
    margin-left: -24px;
    align-items: center;
    font-weight: 400;
    line-height: normal;

}

.leaderboard-points-value {
    text-align: center;
    align-self: center;
    color: #2A2A2A;
    width: 60px;
    font-family: Poppins-Regular;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.leaderboard-points-surname {
    color: #2A2A2A;
    font-family: Poppins-Regular;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.leaderboard-you-surname {
    color: #2A2A2A;
    font-family: Poppins-Regular;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.leaderboard-rank{
    color: #2A2A2A;
    font-family: Gordita-Medium;
    font-size: 14px;
    flex: 1;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.leaderboard-icon {
    width: 24px;
    height: 24px;
    /*margin-right: 18px;*/
}
