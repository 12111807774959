@keyframes borderFill1 {
	0% {
		height: 0;
	}
	20% {
		height: 100%;
	}
	21% {
		height: 0%;
	}
	100% {
		height: 0%;
	}
}

@keyframes borderFill2 {
	0% {
		height: 0;
	}
	20% {
		height: 0%;
	}
	40% {
		height: 87%;
	}
	41% {
		height: 0%;
	}
	100% {
		height: 0%;
	}
}

@keyframes borderFill3 {
	0% {
		height: 0;
	}
	40% {
		height: 0%;
	}
	60% {
		height: 87%;
	}
	61% {
		height: 0%;
	}
	100% {
		height: 0%;
	}
}

@keyframes borderFill4 {
	0% {
		height: 0;
	}
	60% {
		height: 0%;
	}
	80% {
		height: 87%;
	}
	81% {
		height: 0%;
	}
	100% {
		height: 0%;
	}
}

@keyframes borderFill5 {
	0% {
		height: 0;
	}
	80% {
		height: 0%;
	}
	99% {
		height: 87%;
	}
	100% {
		height: 0;
	}
}
