.referral-history-container{
    display: flex;
    padding: 16px;
    margin: 32px auto 0 auto;
    width: 375px;
    flex-direction: column;
    justify-content: start;
    align-items: flex-start;
}

.referral-history-title{
    text-align: start;
    align-self: start;
    color: #1A1A1A;
    font-family: Poppins-Regular;
    font-size: 18px;
    margin-top: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.referral-history-subtitle{
    text-align: start;
    color: #2A2A2A;
    font-family: Poppins-Regular;
    font-size: 14px;
    margin-top: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
}
