.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.bold-text-grey {
	color: #8a8a8a;
	text-align: center;
	font-family: Lora-Regular;
	font-size: 20px;
	font-style: normal;
	align-self: center;
	margin-top: 18px;
	font-weight: 400;
	line-height: 28px; /* 140% */
}

.bold-subtitle {
	color: #2a2a2a;
	text-align: center;
	font-family: Lora-Regular;
	font-size: 28px;
	text-align: center;
	align-self: center;
	font-style: normal;
	font-weight: 400;
	margin-top: 2px;
	line-height: 38px; /* 135.714% */
}

.bold-subtitle-text {
	color: #2a2a2a;
	text-align: center;
	font-family: Poppins-Regular;
	font-size: 14px;
	font-style: normal;
	align-self: center;
	margin-top: 24px;
	font-weight: 400;
	line-height: 18px; /* 171.429% */
}

.login-button {
	margin-top: 18px;
	display: flex;
	flex-direction: row;
	padding: 6px;
	width: 180px;
	border-radius: 6px;
	background-color: white;
	align-items: center;
	justify-content: center;
}

.login-form-container {
	display: flex;
	width: 375px;
	max-height: 100%;
	flex-direction: column;
	justify-content: start;
	align-items: flex-start;
	padding: 16px;
	overflow: hidden;
	box-sizing: border-box;
	background: #f4f4f4;
}

.header-container {
	display: flex;
	flex-direction: column;
	margin-right: 220px;
}

.login-page-form-title {
	color: #1b1625;
	font-family: Galaxie-Copernicus-Book;
	font-size: 24px;
	text-align: center;
	font-style: normal;
	font-weight: 500;
	line-height: 32px; /* 125% */
	letter-spacing: 0.64px;
	text-transform: capitalize;
}

.login-page-form-subtitle {
	width: 320px;
	flex-shrink: 0;
	margin-top: 8px;
	text-align: center;
	color: #4a4a4a;
	font-family: Gordita-Regular;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 22px; /* 157.143% */
}

.login-page-input-title {
	color: #4a4a4a;
	font-family: Gordita-Regular;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.login-success-text {
	color: #1b1625;
	width: 100%;
	text-align: center;
	font-family: Gordita-Medium;
	font-size: 20px;
	margin-top: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: 28px;
}

.login-success-subtitle {
	color: #4a4a4a;
	text-align: center;
	font-family: Gordita-Regular;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
}

.login-failure-text {
	color: #1b1625;
	width: 100%;
	text-align: center;
	font-family: Gordita-Medium;
	font-size: 20px;
	margin-top: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: 28px;
}

.login-failure-subtitle {
	color: #4a4a4a;
	text-align: center;
	font-family: Gordita-Regular;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
}

/* Responsive Media Queries */

/* Larger screens */
@media only screen and (max-width: 1200px) {
	.header-container {
		margin-right: 120px; /* Adjust as needed */
	}
}

/* Medium screens */
@media only screen and (max-width: 992px) {
	.login-container {
		/* ... */
	}

	.header-container {
		margin-right: 20px; /* Adjust as needed */
	}

	.login-form-container {
		width: 90%; /* Adjust as needed */
		height: 100%;
	}
}

/* Small screens */
@media only screen and (max-width: 768px) {
	.login-container {
		/* ... */
	}

	.header-container {
		margin-right: 10px; /* Adjust as needed */
	}

	.login-form-container {
		width: 100%; /* Full width */
		/*margin: 16px auto 0 auto;*/
	}
}

/* Extra small screens */
@media only screen and (max-width: 576px) {
	.login-container {
		/* ... */
	}

	.header-container {
		margin-right: 0; /* No margin */
	}

	.login-form-container {
		width: 100%; /* Full width */
		/*margin: 16px auto 0 auto;*/
	}
}

.login-form-signup-text {
	color: var(--Body_Font, #4a4a4a);
	text-align: center;
	font-family: Gordita-Regular;
	font-size: 14px;
	font-style: normal;
	align-self: center;
	font-weight: 400;
	line-height: 20px; /* 142.857% */
}

.login-form-signup-text span {
	font-family: Gordita-Medium;
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: 20px;
	text-decoration-line: underline;
	/*background-color: var(--Primary-Gradient, linear-gradient(106deg, #4462EF 32.65%, #1B3DD8 105.18%));*/
	color: #4462ef;
}
