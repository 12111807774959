/* styles.css */

.video-animation-page-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	align-self: center;
	height: 100%;
	flex: 1;
	padding-bottom: 100px;
	/*margin: 0px;*/
	background: #fff !important;
}

.video-animation-header-container {
	display: flex;
	width: 95%;
	margin-top: 24px;
	justify-content: space-between;
	flex-direction: row;
	align-items: center;
	position: relative;
}

.video-animation-volume {
	position: absolute;
	right: 0;
	z-index: 100;
}

.video-animation-body-container {
	display: flex;
	width: 95%;
	height: 100%;
	position: relative;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	/*background: #575bdd !important;*/
	/*width: 100%;*/
	/*height: auto;*/
	/*flex-shrink: 0;*/
	/*border-radius: 16px;*/
	/*margin-bottom: 120px;*/
	/*display: flex;*/
	/*padding-left: 16px;*/
	/*padding-top: 16px;*/
	/*justify-content: start;*/
	/*align-items: start;*/
	/*flex-direction: column;*/
	/*border: 1px solid #534DE0;*/
	background: rgba(255, 255, 255, 0.64);
	/*backdrop-filter: blur(13px);*/
}

/* Media query for screen height of 812px and width of 375px */
@media screen and (max-width: 414px) and (max-height: 700px) {
	.video-animation-page-container {
		width: 100%;
		height: 100%;
		align-items: center;
	}
	.video-animation-body-container {
		width: 95%;
		height: 100%;
		align-items: center;
	}
	.video-animation-header-container {
		width: 95%;
		align-items: center;
	}
}

/* Media query for screen height of 812px and width of 375px */
@media screen and (max-width: 414px) and (min-height: 712px) {
	.video-animation-page-container {
		width: 100%;
		height: 100%;
		align-items: center;
	}
	.video-animation-body-container {
		width: 95%;
		height: 100%;
		margin-top: 12%;
		align-items: center;
	}
	.video-animation-header-container {
		width: 95%;
		align-items: center;
	}
}

/* Additional media queries for other screen sizes if needed */
@media screen and (min-width: 414px) and (min-height: 912px) {
	.video-animation-page-container {
		width: 400px;
		height: 100%;
		display: flex;
		align-self: center;
		align-items: center;
	}
	.video-animation-body-container {
		width: 375px;
		height: 100%;
		margin-top: 24%;
		display: flex;
		align-self: center;
		align-items: center;
	}
	.video-animation-header-container {
		width: 375px;
		display: flex;
		align-self: center;
		align-items: center;
	}
}

.video-animation-title {
	color: #231dd7;
	text-align: center;
	font-family: Lora-Regular;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

.video-animation-subtitle {
	color: #1a1a1a;
	text-align: center;
	font-family: Poppins-Regular;
	font-size: 14px;
	margin-top: 8px;
	font-style: italic;
	font-weight: 400;
	line-height: normal;
}

.video-animation-description {
	text-align: start;
	color: #1a1a1a;
	font-family: Poppins-Regular;
	font-size: 14px;
	margin-top: 12px;
	font-style: italic;
	font-weight: 400;
	padding: 0px 8px 0px 0px;
	line-height: 20px; /* 142.857% */
}
