.course-registration-container {
	display: flex;
	/*padding: 16px;*/
	padding: 60px 16px;
	width: 100%;
	max-width: 400px;
	flex-direction: column;
	justify-content: start;
	align-items: flex-start;
	background: #f4f4f4;
}

.course-registration-header-container {
	display: flex;
	flex-direction: column;
	/*margin-right: 220px;*/
	margin-top: -32px;
}

.course-registration-title {
	color: #000;
	font-family: Lora-Regular;
	text-align: center;
	font-size: 24px;
	font-style: normal;
	font-weight: 400;
	line-height: 40px; /* 125% */
	letter-spacing: 0.64px;
	margin-top: 24px;
	/*text-transform: capitalize;*/
}

.course-registration-subtitle {
	width: 300px;
	flex-shrink: 0;
	text-align: center;
	margin-top: 6px;
	color: #757575;
	font-family: Poppins-Light;
	font-size: 14px;
	font-style: normal;
	font-weight: 300;
	line-height: 22px; /* 157.143% */
}

.course-registration-form-title {
	color: #757575;
	font-family: Poppins-Regular;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.optional {
	color: #757575;
	font-family: Poppins-Regular;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.live-classes {
	color: #757575;
	font-family: Poppins-Medium;
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

.referral-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

.time-slot-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	justify-content: flex-start;
}

.email-notification-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	justify-content: flex-start;
	border-radius: 8px;
	padding: 12px;
	border: 1px solid #d6d6d6;
	margin-top: 24px;
}
.signup-failure-text {
	color: #2a2a2a;
	width: 100%;
	text-align: center;
	font-family: Poppins-Medium;
	font-size: 24px;
	margin-top: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}
.signup-failure-subtitle {
	color: #2a2a2a;
	text-align: center;
	text-align: center;
	font-family: Poppins-Regular;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}
