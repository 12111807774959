



.otp-input input {
	height: 40px;
	width: 40px;
	text-align: center;
	border-bottom: 2px solid blue;
	border-top: none;
	border-left: none;
	border-right: none;
	background: transparent;
	border-radius: 0;
	box-sizing: border-box;
	font-size: 16px;
	font-family: Gordita-Regular;
}
.otp-input input:-webkit-autofill,
.otp-input input:-webkit-autofill:focus {
	-webkit-box-shadow: 0 0 0px 1000px white inset; /* or your desired background color */
	box-shadow: 0 0 0px 1000px white inset;
	transition: background-color 5000s ease-in-out 0s;
}
.otp-input input[type="text"],
.otp-input input[type="tel"] {
	background-color: transparent;
	caret-color: black; /* Keeps caret visible */
}


.otp-input input:focus-within,
.otp-input input:visited,
.otp-input input:hover {
	background-color: transparent;
	box-shadow: none;
	border-color: blue;
}
