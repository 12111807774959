/* styles.css */

.course-not-enrolled-page-container {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center horizontally */
    justify-content: center; /* Center vertically */
    width: 375px;
    margin: 32px auto 0 16px;
    padding: 16px 16px;
}

.course-not-enrolled-header-container {
    width: 375px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}
.course-not-enrolled-body-container {
    width: 375px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    flex-direction: column;
}

.course-not-enrolled-title {
    margin-top: 24px;
    color: #2A2A2A;
    text-align: center;
    font-family: Lora-Regular;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px; /* 125% */
}

.course-not-enrolled-subtitle {
    width: 375px;
    color: #2A2A2A;
    text-align: center;
    font-family: Poppins-Regular;
    margin-top: 12px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
}

.course-not-enrolled-subtitle_two {
    color: #2A2A2A;
    text-align: center;
    font-family: Poppins-Regular;
    font-size: 14px;
    width: 334px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
}

.course-not-enrolled-icon-container {
    width: 30px;
    height: 30px;
    background-color: #3498db; /* Example background color for the icon */
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff; /* Example color for the icon */
    border-radius: 50%;
}

