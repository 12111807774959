.place-input-container{
    display: flex;
    height: 52px;
    /*padding: 8px 16px;*/
    align-items: center;
    /*gap: 16px;*/
    flex: 1 0 0;
    border-radius: 12px;
    border: 1px solid #D6D6D6;
    background: #FFF;
    margin-top: 12px;
}

