.nudge-container {
	display: flex;
	width: auto;
	padding: 8px;
	flex-direction: row;
	justify-content: space-between;
	overflow: hidden;
	align-items: center;
	/*gap: 24px;*/
	border-radius: 12px;
	border: 1px solid #008080;
	background: #fff;
	box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
}

.nudge-icon {
	width: 24px;
	height: 24px;
	/*margin-left: 8px;*/
	display: flex;
	align-items: center;
	justify-content: center;
}

.nudge-content {
	display: flex;
	flex-direction: column;
}

.nudge-title {
	color: #1b1625;
	font-family: Gordita-Regular;
	font-size: 14px;
	font-style: normal;
	margin-left: 10px;
	align-self: center;
	font-weight: 400;
	line-height: 20px; /* 142.857% */
}

.nudge-tag {
	color: #1b1625;
	font-family: Gordita-Medium;
	font-size: 14px;
	align-self: center;
	font-style: normal;
	font-weight: 500;
	line-height: 20px;
}

.anger-nudge-shimmer {
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	transform: translateX(-100%);
	animation: shimmer 2.5s infinite;
}

@keyframes shimmer {
	0% {
		left: 0;
	}
	40% {
		left: 150%;
	}
	100% {
		left: 150%;
	}
}
