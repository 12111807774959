.otp-container {
	display: flex;
	flex-direction: column;
	width: 375px;
	margin: 0px auto 0 auto;
	justify-content: start;
	align-items: flex-start;
	padding: 0 16px 0 16px;
}

.otp-header-container {
	display: flex;
	flex-direction: column;
}

.otp-title {
	color: #1b1625;
	width: 100%;
	text-align: center;
	font-family: Galaxie-Copernicus-Book;
	font-size: 24px;
	/*margin-top: 12px;*/
	font-style: normal;
	font-weight: 500;
	line-height: 40px; /* 125% */
	letter-spacing: 0.64px;
}
.otp-subtitle {
	color: #4a4a4a;
	font-family: Gordita-Regular;
	font-size: 16px;
	width: 100%;
	text-align: center;
	/*margin-top: 8px;*/
	font-style: normal;
	font-weight: 400;
	line-height: 24px; /* 157.143% */
}
.otp-subtitle span {
	color: #4a4a4a;
	font-family: Gordita-Medium;
	font-size: 16px;
	font-style: normal;
	margin-left: 4px;
	font-weight: 500;
	line-height: 24px;
}

.otp-enter {
	color: #757575;
	font-family: Poppins-Regular;
	font-size: 16px;
	font-style: normal;
	margin-top: 24px;
	align-self: flex-start;
	font-weight: 600;
	line-height: 22px; /* 157.143% */
}

.otp-resend {
	color: #4a4a4a;
	text-align: center;
	font-family: Gordita-Medium;
	font-size: 14px;
	flex-direction: row;
	margin-top: 26px;
	width: 100%;
	align-self: center;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

.otp-resend span {
	color: #d6d6d6;
	text-align: center;
	font-family: Gordita-Bold;
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	align-self: center;
	line-height: normal;
}
