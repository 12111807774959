.founder-container{
    display: flex;
    padding: 0 16px 0 16px;
    margin: 38px auto 0 auto;
    width: 375px;
    flex-direction: column;
    justify-content: start;
    align-items: flex-start;
}
.founder-description{
    color: #3A3A3A;
    font-family:Poppins-Regular;
    font-size: 14px;
    margin-top: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.founder-description div{
    color: #534DE0;
    font-family: Poppins-Regular;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 171.429% */
}
