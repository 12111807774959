.horizontal-chip-icon-title-container {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
}

.horizontal-chip-icon {
	display: flex;
	align-items: center;
	justify-content: center;
	align-self: center;
	text-align: center;
	width: 24px;
	height: 24px;
}

.horizontal-chip-title {
	display: flex;
	align-items: center;
	justify-content: center;
	align-self: center;
	text-align: center;
	vertical-align: center;
	height: 100%;
	color: var(--Body-Font, #4a4a4a);
	font-family: Gordita-Medium;
	font-size: 12px;
	margin-left: 3px;
	margin-top: 3px;
	font-style: normal;
	font-weight: 500;
	line-height: 16px; /* 133.333% */
}

.horizontal-chip-subtitle {
	display: flex;
	align-items: center;
	justify-content: center;
	align-self: center;
	text-align: center;
	vertical-align: center;
	height: 100%;
	color: var(--Body-Font, #4a4a4a);
	font-family: Gordita-Medium;
	font-size: 12px;
	margin-left: 2px;
	margin-top: 2px;
	font-style: normal;
	font-weight: 500;
	line-height: 16px; /* 133.333% */
}
