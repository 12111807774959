.login-form-popup-container {
	display: flex;
	width: 100%;
	flex-direction: column;
	justify-content: center;
	align-items: start;
	align-self: center;
	max-width: 420px;
}

.login-form-title {
	color: #1b1625;
	font-family: Galaxie-Copernicus-Book;
	font-size: 24px;
	text-align: center;
	font-style: normal;
	font-weight: 500;
	line-height: 32px;
	letter-spacing: 0.64px;
}

.login-form-subtitle {
	width: 320px;
	flex-shrink: 0;
	margin-top: 8px;
	text-align: center;
	color: #4a4a4a;
	font-family: Gordita-Regular;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 22px;
}

.form-title {
	color: #4a4a4a;
	font-family: Gordita-Regular;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.login-success-text {
	color: #1b1625;
	width: 100%;
	text-align: center;
	font-family: Gordita-Medium;
	font-size: 20px;
	margin-top: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: 28px;
}
.login-success-subtitle {
	color: #4a4a4a;
	text-align: center;
	font-family: Gordita-Regular;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
}

.login-failure-text {
	color: #1b1625;
	width: 100%;
	text-align: center;
	font-family: Gordita-Medium;
	font-size: 20px;
	margin-top: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: 28px;
}

.login-failure-subtitle {
	color: #4a4a4a;
	text-align: center;
	font-family: Gordita-Regular;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
}
.payment-success-hurray {
	color: var(--Black, #1b1625);
	text-align: center;
	font-family: Gordita-Medium;
	font-size: 20px;
	margin-top: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: 28px;
}

.payment-success-plan-description {
	color: var(--Body-Font, #4a4a4a);
	text-align: center;
	font-family: Gordita-Regular;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
}
.payment-success-plan-description span {
	color: var(--Black, #1b1625);
	font-family: Gordita-Medium;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 24px;
}

.login-form-popup-signup-text {
	color: var(--Body_Font, #4a4a4a);
	text-align: center;
	font-family: Gordita-Regular;
	font-size: 14px;
	font-style: normal;
	align-self: center;
	font-weight: 400;
	line-height: 20px; /* 142.857% */
}

.login-form-popup-signup-text span {
	color: #4462ef;
	font-family: Gordita-Medium;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 20px;
	text-decoration: underline;
}

.popup-small-loader {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
}

.popup-loader-circle {
	position: absolute;
	margin-top: 50%;
	border: 4px solid rgba(0, 0, 0, 0.1);
	border-left-color: #534de0;
	border-radius: 50%;
	width: 32px;
	height: 32px;
	z-index: 1;
	/*top: 0;*/
	/*bottom: 25%;*/
	animation: spin 1s linear infinite;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
