.live-class-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	align-self: center;
}

.live-class-component-wrapper {
	display: flex;
	width: 100%;
	padding-left: 16px;
	flex-direction: column;
	align-items: center;
	background: #fff;
	margin-top: 12px;
}

.live-class-gradient {
	background: url('../../assets/radiant-effect.svg'),
		linear-gradient(14deg, #fff 27.67%, #534de0 95.29%);
	background-size: 100%;
	background-repeat: no-repeat;
	height: 60vh;
	display: flex;
	align-items: center;
	width: 100%;
	align-self: center;
	padding: 0 16px 0 16px;
	flex-direction: column;
	align-self: center;
}

.payment-successful-title {
	color: #2a2a2a;
	width: 100%;
	text-align: center;
	font-family: Poppins-Regular;
	font-size: 24px;
	margin-top: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

.payment-successful-subtitle {
	color: #2a2a2a;
	text-align: center;
	font-family: Poppins-Regular;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.consistency-popup-title {
	color: #1a1a1a;
	text-align: center;
	font-family: Lora-Regular;
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	margin-top: 16px;
	line-height: 28px; /* 133.333% */
}

.consistency-popup-subtitle {
	color: #1a1a1a;
	text-align: center;
	font-family: Poppins-Regular;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px; /* 171.429% */
}

.time-to-renew {
	color: #534de0;
	text-align: center;
	font-family: Poppins-Regular;
	font-size: 24px;
	font-style: normal;
	font-weight: 700;
	line-height: 36px; /* 150% */
}

.time-to-renew-subtitle {
	color: #757575;
	text-align: center;
	font-family: Poppins-Regular;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 24px; /* 150% */
}

.join-class-through-popup {
	display: flex;
	padding: 16px 12px;
	width: 157px;
	height: 54px;
	justify-content: center;
	align-items: center;
	gap: 4px;
	flex: 1 0 0;
	border-radius: 8px;
	border: 1px solid #534de0;
	background: #fff;
	color: #534de0;
	margin-right: 4px;
	text-align: center;
	font-family: Poppins-Regular;
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}

.renew-plan-through-popup {
	display: flex;
	padding: 16px 12px;
	width: 157px;
	height: 54px;
	color: white;
	justify-content: center;
	align-items: center;
	gap: 4px;
	flex: 1 0 0;
	margin-left: 4px;
	border-radius: 8px;
	background: #534de0;
	text-align: center;
	font-family: Poppins-Regular;
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}

.karma-points {
	color: #ffffff;
	font-family: Lora-Regular;
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: 20px; /* 125% */
}

.karma-points-title {
	color: #534de0;
	font-family: Poppins-Regular;
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: 20px;
}

.live-class-username {
	color: #fff;
	text-align: center;
	font-family: Lora-Regular;
	margin-top: 26px;
	align-self: center;
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	line-height: 28px; /* 155.556% */
	letter-spacing: 0.36px;
	text-transform: capitalize;
}

.live-class-greetings {
	color: #fff;
	text-align: center;
	font-family: Poppins-Regular;
	align-self: center;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px; /* 142.857% */
}

.live-class-empty-Attendance {
	color: #1a1a1a;
	font-family: Poppins-Regular;
	font-size: 16px;
	font-style: normal;
	text-align: center;
	align-self: center;
	font-weight: 600;
	line-height: normal;
}

.live-class-floating-video {
	position: fixed;
	bottom: 80px;
	left: 12px;
	width: 30%;
	border-radius: 8px;
	transition: all 0.4s;
}

.live-class-floating-video video {
	width: 100%;
	border-radius: 8px;
}

.live-class-floating-video img {
	position: absolute;
	top: 0;
	right: 0;
	display: none;
	width: 24px;
	transform: translate(50%, -50%);
}
