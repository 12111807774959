.complete-profile-container {
	width: 100%;
	height: 100%;
	max-width: 400px;
	overflow-y: hidden;
	background: #f4f4f4;
	display: flex;
	flex-direction: column;
	justify-content: center; /* Center content vertically */
	align-items: center; /* Center content horizontally */
	padding: 16px;
	box-sizing: border-box;
	position: relative;
	/*transform: translate(-50%, -50%);  !* Center the container *!*/
}

/* Media query for screen height of 812px and width of 375px */
@media screen and (max-width: 414px) {
	.complete-profile-container {
		width: 100%;
		height: auto;
		align-items: center;
		justify-content: center;
		max-width: 400px;
	}
	.complete-profile-header {
		width: 95%;
		align-items: center;
		flex-direction: row;
		max-width: 400px;
	}
}

/* Additional media queries for other screen sizes if needed */
@media screen and (min-width: 414px) {
	.complete-profile-container {
		width: 400px;
		height: auto;
		display: flex;
		/*margin-left: 40%;*/
		align-items: center;
		justify-content: center;
	}
	.complete-profile-header {
		width: 375px;
		display: flex;
		flex-direction: row;
		align-items: center;
	}
	.complete-profile-cta {
		margin-left: 1.5%;
	}
}

.complete-profile-title {
	color: #1a1a1a;
	text-align: center;
	font-family: Galaxie-Copernicus-Book;
	font-size: 24px;
	font-style: normal;
	font-weight: 500;
	line-height: 48px; /* 150% */
}

.complete-profile-form-title {
	color: #4a4a4a;
	font-family: Gordita-Regular;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	text-align: left;
	align-self: flex-start;
	line-height: normal;
	margin-top: 24px;
}

.complete-profile-flex-row-container {
	display: flex;
	margin-top: 4px;
	position: relative;
	flex-direction: row;
	width: 100%;
	height: 100%;
	justify-content: space-between;
	align-self: center;
}
.optional-field-container {
	margin-left: 10px;
	align-self: center;
	color: #a4a4a4;
	font-family: Gordita-Regular;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	margin-top: 8px;
	line-height: 20px; /* 166.667% */
}

.complete-profile-cta {
	display: flex;
	height: 50px;
	width: 80%;
	max-width: 400px;
	padding: 12px;
	justify-content: center;
	align-items: center;
	align-self: stretch;
	margin-bottom: 24px;
	border-radius: 12px;
	background: var(
		--Primary_Gradient,
		linear-gradient(106deg, #4462ef 32.65%, #1b3dd8 105.18%)
	);
}

.complete-profile-age-body-container {
	display: flex;
	width: 95%;
	justify-content: center;
	flex-direction: column;
	align-items: center;
}
.complete-profile-age-title {
	width: 375px;
	color: #1b1625;
	text-align: center;
	font-family: Galaxie-Copernicus-Book;
	font-size: 24px;
	font-style: normal;
	margin-top: 24px;
	font-weight: 500;
	line-height: 32px; /* 150% */
}

.complete-profile-age-icon-with-title {
	display: flex;
	align-items: center;
	align-self: center;
	height: 56px;
	width: 100%;
	max-width: 327px;
	padding: 12px 16px;
	margin-top: 16px;
	gap: 8px;
	flex: 1 0 0;
	justify-content: center;
	border-radius: 12px;
	border: 1px solid var(--Inactive-Color, #d6d6d6);
	background: var(--White, #fff);
}

.complete-profile-selected-age-icon-with-title {
	display: flex;
	align-items: center;
	align-self: center;
	height: 56px;
	width: 100%;
	max-width: 327px;
	padding: 12px 16px;
	margin-top: 16px;
	gap: 8px;
	flex: 1 0 0;
	justify-content: center;
	border-radius: 12px;
	border: 1px solid var(--Primary-Color, #4462ef);
	background: var(--Primary-Background, #e6eaff);
}

.complete-profile-age-row-title {
	color: #1a1a1a;
	font-family: Gordita-Regular;
	font-size: 16px;
	width: 100%;
	text-align: center;
	font-style: normal;
	font-weight: 400;
	line-height: 24px; /* 157.143% */
	text-transform: capitalize;
}
.complete-profile-selected-age-row-title {
	color: var(--Black-Shade, #1b1625);
	text-align: center;
	/* Body 1/Medium */
	font-family: Gordita-Medium;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 24px; /* 150% */
}
