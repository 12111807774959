.poc-checkout-container {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center horizontally */
    justify-content: center; /* Center vertically */
    width: 100%;
    max-width: 400px;
}

.poc-checkout-details{
    color: #1B1625;
    font-family: Galaxie-Copernicus-Book;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    width:95%;
    max-width: 400px;
    margin-top: 24px;
    text-align: start;
    line-height: normal;
}

.poc-checkout-plan-start-date{
    color: var(--Body-Font, #4A4A4A);
    font-family: Gordita-Medium;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
}

.poc-checkout-plan-start-date-value{
    color: var(--Black, #1B1625);
    text-align: right;
    font-family: Gordita-Medium;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.poc-checkout-note{
    color: var(--Black, #1B1625);
    font-family: Gordita-Regular;
    font-size: 14px;
    margin-top: 16px;
    max-width: 400px;
    margin-left: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}
.poc-checkout-note span{
    color: var(--Black, #1B1625);
    font-family: Gordita-Medium;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
}



.poc-checkout-plan-details-container{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content:space-between;
}

.poc-checkout-plan-billing-container{
    display: flex;
    padding: 12px;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    align-self: center;
    border-radius: 12px;
    width: 344px;
    margin-top: 12px;
    border: 1px solid #E2E0FF;
}

.poc-checkout-plan-billing-row-container{
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    width: 324px;


}

.poc-checkout-total-amount-pay{
    color: #2A2A2A;
    align-self: center;
    font-family: Poppins-Regular;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.poc-checkout-plan-name{
    /*width: 60%;*/
    color: #1A1A1A;
    font-family: Poppins-Regular;
    font-size: 14px;
    width: 220px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

}

.poc-checkout-plan-discount {
    color: #6A6A6A;
    font-family: Poppins-Regular;
    font-size: 10px;
    font-style: italic;
    font-weight: 500;
    line-height: normal;

}


.poc-checkout-monthly-plan-price{
    color: #3A3A3A;
    font-family: Poppins-Regular;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.poc-checkout-monthly-plan-price span{
    color: #3A3A3A;
    font-family: Poppins-Regular;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}


.poc-checkout-plan-price{
    color: #3A3A3A;
    text-align: right;
    /*font-family: DM Sans;*/
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.poc-checkout-plan-price-total{
    color: #534DE0;
    text-align: right;
    /*font-family: DM Sans;*/
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.poc-checkout-plan-offer{
    color: var(--Black, #1B1625);
    font-family: Gordita-Medium;
    font-size: 14px;
    font-style: normal;
    align-self: center;
    margin-left: 12px;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
}

.poc-billing-details {
    color: #1A1A1A;
    font-family: Poppins-Regular;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    margin-top: 36px;
    margin-left: 12px;
    text-align: center;
    align-self: center;
    line-height: normal;
}

.poc-checkout-title {
    color: #2A2A2A;
    font-family: Poppins-Regular;
    margin-left: 4px;
    font-size: 14px;
    text-align: start;
    font-style: normal;
    font-weight: 400;
}

.poc-toolbar-title {
    color: #1A1A1A;
    font-family: Poppins-Medium;
    font-size: 17px;
    font-style: normal;
    white-space: nowrap;
    font-weight: 500;
    line-height: 24px; /* 141.176% */
}

.poc-icon {
    border-radius: 4px;
}


.poc-fixed-bottom-container {
    position: fixed;
    bottom: 0;
    /*left: 0;*/
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-self: center;
    align-items: center;
    border-radius: 12px 12px 0px 0px;
    background: #FFF;
    padding: 12px 16px 32px 16px;
    z-index: 1000;
    box-shadow: 0px -4px 12px 0px rgba(0, 0, 0, 0.08);
}

.poc-fixed-bottom-button {
    margin-bottom: 10px; /* Add some space below the button */
    padding: 10px 20px;
    border-radius: 8px;
    /*background: var(--Primary-Color, #4462EF);*/
    background: var(--Primary-Gradient, linear-gradient(106deg, #4462EF 32.65%, #1B3DD8 105.18%));
    border: none;
    width: 50%;
    text-align: center;
    cursor: pointer;
    color: #FFF;
    font-family: Gordita-Medium;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
}

.poc-fixed-bottom-button:hover {
    background-color: #0056b3;
}

.poc-fixed-bottom-price {
    color: var(--Black, #1B1625);
    font-family: Gordita-Medium;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 140% */
}

