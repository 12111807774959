.recordings-modal {
	width: 100%;
}

.recordings-modal-text {
	margin: 16px 0;
}

.recordings-modal-title {
	color: #ffffff;
	text-align: center;
	font-family: Gordita-Medium;
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	margin-bottom: 0;
}

.recordings-modal-title > span {
	text-align: center;
	font-family: Gordita-Bold;
	font-size: 18px;
	font-style: normal;
	color: #faa53d;
	display: block;
}

.recordings-modal-title > span > span {
	font-family: Gordita-Medium;
	color: #faa53d;
	text-decoration: line-through !important;
	display: inline;
}

.recordings-modal-subtitle {
	color: #d6d6d6;
	text-align: center;
	font-family: Gordita-Medium;
	font-size: 12px;
	font-weight: 500;
	margin-top: 2px;
}

.recordings-modal-points {
	margin-bottom: 24px;
	display: flex;
	flex-direction: column;
	gap: 12px;
}

.recordings-modal-points-item {
	display: flex;
	align-items: center;
	justify-content: start;
	gap: 8px;
}

.recordings-modal-points-item img {
	width: 32px;
	height: 32px;
}

.recordings-modal-points-item p {
	color: #d6d6d6;
	font-family: Gordita-Regular;
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
	margin-bottom: 0;
}

.recordings-modal-skip {
	text-align: center;
	font-family: Gordita-Bold;
	font-size: 14px;
	text-decoration-line: underline;
	color: #4462ef;
}
