.error-container {
    display: flex;
    flex-direction: row;
    align-items: center; /* Center items vertically */
    justify-content: center; /* Center items horizontally */
    position: fixed; /* Fix the position at the top */
    top: 0; /* Align to the top of the screen */
    left: 0; /* Align to the left edge */
    width: 95%; /* Full width of the screen */
    height: 50px; /* Fixed height */
    border-radius: 0 0 8px 8px; /* Rounded corners at the bottom */
    background: #E9354D;
    align-self: center;
    color: #FFF;
    font-family: Gordita-Regular;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
    /*z-index: 1000; !* Ensure it is on top of other elements *!*/
}
