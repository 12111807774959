.secondary-button {
    color: white; /* White text color */
    cursor: pointer;
    display: flex;
    padding: 12px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    flex: 1 0 0;
    margin-left: 4px;
    border-radius: 8px;
    border: 1px solid #E4E4E4;
    background: #FFF;
}

.secondary-button:hover {
    opacity: 0.8;
}

.secondary-button-title{
    color: var(--Black, #1B1625);
    text-align: center;
    font-feature-settings: 'dlig' on;
    font-family: Gordita-Medium;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 133.333% */
}
