/* AppLayout.css */

.app-layout {
	position: relative;
}

.background-image {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 200px; /* Adjust the height as needed */
	/*background-image: url("../assets/top_backgroun.svg"); !* Add the path to your SVG or background image *!*/
	/*background-size: cover; !* Adjust the background size as needed *!*/
	/*background-repeat: no-repeat;*/
	/*background-position: center;*/
	z-index: -1; /* Move the background to the back */
}
