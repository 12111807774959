.favourite-class-description-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
}


.favourite-class-player-container{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    margin-top: 16px;
    width: 100%;
    position: relative;
}


.favourite-class-subheader-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 64px;
    margin-right: 16px;
    border-radius: 12px;
    border: 1px solid #F5EEFF;
    background: #FFF;
    padding: 12px;
    margin-top: 16px;
    justify-content: center;
    align-items: center;
    gap: 6px;
}

.favourite-class-subheader-subContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.favourite-class-body-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    width: 100%;
    position: relative;
}



.favourite-class-subheader-title {
    color: #1B1625;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    font-family: Gordita-Medium;
    align-self: start;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    margin-top: 6px;
    line-height: 18px; /* 150% */
}



.favourite-class-subheader-subtitle {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    justify-content: start;
    align-self: start;
    align-items: start;
    color: #2A2A2A;
    font-family: Poppins-Regular;
    align-self: start;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    text-align: start;
    line-height: 16px; /* 160% */
}

.favourite-class-highlights-title{
    color: #1B1625;
    font-family: Gordita-Medium;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
}

.favourite-class-highlights-subtitle{
    color: #4A4A4A;
    font-family: Gordita-Regular;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    align-self: flex-start;
    line-height: 20px; /* 142.857% */
}



.favourite-image-banner{
    width: 90%;
    height: auto;
    /*max-height: 212px;*/
    object-fit: cover;
}



/* Media query for screen height of 812px and width of 375px */
@media screen and (max-width: 360px){
    .favourite-class-description-container{
        width: 100%;
        height: auto;
        align-items: center;
    }
    .favourite-class-body-container {
        width: 90%;
        height: auto;
        margin-left: 16px;
        margin-right: 16px;
        align-items: center;

    }
    .favourite-image-banner{
        width: 90%;
        min-height: 213px;
        height: auto;
        align-items: center;
        object-fit: cover;
    }
}

/* Media query for screen height of 812px and width of 375px */
@media screen and (min-width: 360px) and (max-width: 414px){
    .favourite-class-description-container{
        width: 100%;
        height: auto;
        align-items: center;
    }
    .favourite-class-body-container {
        width: 90%;
        height: auto;
        align-items: center;

    }
    .favourite-image-banner{
        width: 90%;
        min-height: 213px;
        height: auto;
        align-items: center;
        object-fit: cover;
    }
}

/* Additional media queries for other screen sizes if needed */
@media screen and (min-width: 414px) {
    .favourite-class-description-container{
        width: auto;
        height: auto;
        display: flex;
        align-self: center;
        align-items: center;
    }
    .favourite-class-body-container{
        width: 375px;
        height: auto;
        display: flex;
        align-self: center;
        align-items: center;
    }
    .favourite-image-banner{
        width: 375px;
        height: 238px;
        align-items: center;
    }
}





