.flag-dropdown {
	height: 52px;
	top: 9px !important;
	border-radius: 6px 0 0 6px !important;
}

.flag-dropdown .flag-dropdown ul.country-list {
	border: 1px solid #d6d6d6;
	box-shadow: none !important;
}

.react-tel-input .selected-flag:focus:before {
	border: none;
	box-shadow: none;
}
