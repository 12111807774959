.signup-form-container{
    display: flex;
    width: 375px;
    max-height: 100%;
    flex-direction: column;
    justify-content: start;
    align-items: flex-start;
    padding: 16px;
    overflow: hidden;
    box-sizing: border-box;
    background: #F4F4F4;
}

.signup-header-container{
    display: flex;
    flex-direction: row;
    /*margin-right: 220px;*/
}

/* Medium screens */
@media only screen and (max-width: 992px) {
    .signup-header-container {
        margin-right: 20px; /* Adjust as needed */
    }

    .signup-form-container {
        width: 90%; /* Adjust as needed */
        height: 100%;
    }
}

/* Small screens */
@media only screen and (max-width: 768px) {

    .signup-header-container {
        margin-right: 10px; /* Adjust as needed */
    }

    .signup-form-container {
        width: 100%; /* Full width */
        /*margin: 16px auto 0 auto;*/
    }
}
/* Extra small screens */
@media only screen and (max-width: 576px) {


    .signup-header-container {
        margin-right: 0; /* No margin */
    }

    .signup-form-container {
        width: 100%; /* Full width */
        /*margin: 16px auto 0 auto;*/
    }
}


.signup-title{
    color: #1B1625;
    font-family:Galaxie-Copernicus-Book;
    font-size: 24px;
    text-align: center;
    font-style: normal;
    font-weight: 500;
    line-height: 32px; /* 125% */
    letter-spacing: 0.64px;
    text-transform: capitalize;
}

.signup-subtitle{
    width: 320px;
    flex-shrink: 0;
    margin-top: 8px;
    text-align: center;
    color: #4A4A4A;
    font-family: Gordita-Regular;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
}

.signup-form-title{
    color: #4A4A4A;
    font-family: Gordita-Regular;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.optional{
    color: #757575;
    font-family: Poppins-Regular;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.live-classes{
    color: #757575;
    font-family: Poppins-Regular;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.referral-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.time-slot-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: flex-start;
}

.email-notification-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: flex-start;
    border-radius: 8px;
    padding: 12px;
    border: 1px solid #D6D6D6;
    margin-top: 24px;
}
.signup-failure-text{
    color: #1B1625;
    width: 100%;
    text-align: center;
    font-family: Gordita-Medium;
    font-size: 20px;
    margin-top: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28;
}
.signup-failure-subtitle{
    color: #4A4A4A;
    text-align: center;
    font-family: Gordita-Regular;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

