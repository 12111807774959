.slot-chip-container{
    display: flex;
    width: auto;
    height: 24px;
    flex-direction: row;
    overflow-wrap: normal;
    justify-content: center;
    align-items: center;
    padding-left: 8px;
    padding-right: 8px;
}
.slot-title{
    color: var(--Font-grey, #A4A4A4);
    font-family: Gordita-Regular;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
}
.slot-title.available{
    color: var(--Black, #1B1625);
    font-family: Gordita-Medium;
    font-weight: 500;
}
