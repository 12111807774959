.header-v2-container {
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	padding: 10px 20px; /* Adjust padding as needed */
	background-color: white; /* Header background color */
	border-radius: 0px 0px 16px 16px;
	box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.08);
}

.header-v2-container.dark {
	background-color: #1b1625;
	box-shadow: 0px 6px 25.6px 0px rgba(255, 255, 255, 0.08);
}

.header-v2-logo {
	display: flex;
	min-width: 32px;
	min-height: 32px;
	justify-content: center;
	align-items: center;
	gap: 10px;
	border-radius: 8px;
	background-color: white;
}

.header-v2-icon-title-container {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	/*display: flex;*/
	padding: 6px;
	/*align-items: center;*/
	gap: 6px;
	border-radius: 12px;
	border: 1px solid var(--Light-Grey-2, #e4e4e4);
	background: transparent;
}

.header-v2-left-content {
	flex: 1; /* Take up available space */
}

.header-v2-right-content {
	display: flex;
	align-items: center;
}

/* Media query for screen height of 812px and width of 375px */
@media screen and (max-width: 414px) {
	.header-v2-logo {
		min-width: 32px;
		min-height: 32px;
	}
}

/* Additional media queries for other screen sizes if needed */
@media screen and (min-width: 414px) {
	.header-v2-logo {
		min-width: 48px;
		min-height: 48px;
	}
}
