body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}




@font-face {
  font-family: "Lora-Regular";   /*Can be any text*/
  src: local("AirbnbCerealLight"),
  url("../src/fonts/Lora-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Lora-Medium";   /*Can be any text*/
  src: local("AirbnbCerealLight"),
  url("../src/fonts/Lora-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Lora-SemiBold";   /*Can be any text*/
  src: local("AirbnbCerealLight"),
  url("../src/fonts/Lora-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Lora-Bold";   /*Can be any text*/
  src: local("AirbnbCerealLight"),
  url("../src/fonts/Lora-Bold.ttf") format("truetype");
}


@font-face {
  font-family: "Poppins-Regular";   /*Can be any text*/
  src: local("AirbnbCerealLight"),
  url("../src/fonts/Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Medium";   /*Can be any text*/
  src: local("AirbnbCerealLight"),
  url("../src/fonts/Poppins-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-SemiBold";   /*Can be any text*/
  src: local("AirbnbCerealLight"),
  url("../src/fonts/Poppins-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Bold";   /*Can be any text*/
  src: local("AirbnbCerealLight"),
  url("../src/fonts/Poppins-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Light";   /*Can be any text*/
  src: local("AirbnbCerealLight"),
  url("../src/fonts/Poppins-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Gordita-Light";   /*Can be any text*/
  src: local("AirbnbCerealLight"),
  url("../src/fonts/Gordita-Light.otf") format("truetype");
}

@font-face {
  font-family: "Gordita-Bold";   /*Can be any text*/
  src: local("AirbnbCerealLight"),
  url("../src/fonts/Gordita-Bold.otf") format("truetype");
}

@font-face {
  font-family: "Gordita-Medium";   /*Can be any text*/
  src: local("AirbnbCerealLight"),
  url("../src/fonts/Gordita-Medium.otf") format("truetype");
}
@font-face {
  font-family: "Gordita-Regular";   /*Can be any text*/
  src: local("AirbnbCerealLight"),
  url("../src/fonts/Gordita-Regular.otf") format("truetype");
}

@font-face {
  font-family: "Galaxie-Copernicus-Book";   /*Can be any text*/
  src: local("AirbnbCerealLight"),
  url("../src/fonts/Galaxie-Copernicus-Book.ttf") format("truetype");
}
