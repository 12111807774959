.course-cover {
	position: relative;
	width: 100%;
}

.course-cover-image {
	width: calc(100% - 32px);
	border-radius: 8px 8px 0 0;
	margin: 16px;
	margin-bottom: 0;
}

.course-cover-image-tag {
	position: absolute;
	bottom: 8px;
	left: 24px;
	background-color: #00000077;
	border-radius: 4px;
	padding: 4px;
	color: #fff;
	font-family: 'Gordita-Medium';
}

.course-cover-image-tag p {
	margin-bottom: 0;
	font-size: 14px;
}

.course-cover-info {
	padding: 16px;
	margin: 0 16px;
	background-color: #232323;
	border-radius: 0 0 8px 8px;
}

.course-cover-info-header {
	display: flex;
	justify-content: space-between;
	color: white;
	align-items: center;
	margin-bottom: 8px;
}

.course-cover-info-header p {
	margin-bottom: 0;
}

.course-cover-info-header-title {
	margin-bottom: 0;
	font-family: Gordita-Medium;
	font-size: 20px;
}

.course-cover-info-header-rating {
	display: flex;
	align-items: center;
}

.course-cover-info-header-rating img {
	width: 14px;
	height: 14px;
	margin: 0 4px;
}

.course-cover-info-header-rating-number {
	color: #e4e4e4;
	font-family: Gordita-Regular;
	font-size: 14px;
}

.course-cover-info-header-rating-count {
	color: #a4a4a4;
	font-family: Gordita-Regular;
	font-size: 14px;
}

.course-cover-info-date {
	display: flex;
	align-items: center;
	gap: 4px;
	margin-bottom: 4px;
}

.course-cover-info-date img {
	width: 22px;
	height: 22px;
}

.course-cover-info-date p {
	color: #e4e4e4;
	margin-bottom: 0;
	font-size: 14px;
	font-family: Gordita-Regular;
}

.course-cover-info-details {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 8px;
}

.course-cover-info-details-item {
	display: flex;
	gap: 4px;
	align-items: center;
}

.course-cover-info-details-item p {
	margin-bottom: 0;
	color: #e4e4e4;
	font-size: 14px;
	font-family: Gordita-Regular;
}

.course-cover-info-details-divider {
	width: 1px;
	height: 16px;
	background: #4a4a4a;
}

.course-cover-info-bio {
	border-bottom: 1px dashed #4a4a4a;
	margin-bottom: 8px;
}

.course-cover-info-bio p {
	color: #a4a4a4;
	font-size: 16px;
	font-family: Gordita-Regular;
	margin-bottom: 12px;
}

.course-cover-info-footer {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.course-cover-info-footer-price-offer {
	margin-bottom: 0;
	font-family: Gordita-Medium;
	font-size: 16px;
	color: #ffffff;
}

.course-cover-info-footer-price-actual {
	margin-bottom: 0;
	text-decoration: line-through;
	font-family: Gordita-Medium;
	font-size: 12px;
	color: #a4a4a4;
}
