.ques-page-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    margin-top: 24px;
}

.ques-header-container {
    display: flex;
    width: 95%;
    justify-content: center;
    flex-direction: row;
    align-items: center;
}


.ques-body-container {
    display: flex;
    width: 95%;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}


/* Media query for screen height of 812px and width of 375px */
@media screen and (max-width: 360px){
    .ques-page-container{
        width: 100%;
        height: auto;
        align-items: center;
    }
    ques-body-container {
        width: 95%;
        height: auto;
        align-items: center;

    }
    .ques-header-container {
        width: 95%;
        align-items: center;

    }
}

/* Media query for screen height of 812px and width of 375px */
@media screen and (min-width: 360px) and (max-width: 414px){
    .ques-page-container{
        width: 100%;
        height: auto;
        align-items: center;
    }
    .ques-body-container {
        width: 95%;
        height: auto;
        align-items: center;

    }
    .ques-header-container{
        width: 95%;
        align-items: center;

    }
}

/* Additional media queries for other screen sizes if needed */
@media screen and (min-width: 414px) {
    .ques-page-container{
        width: 400px;
        height: auto;
        display: flex;
        align-self: center;
        align-items: center;
    }
    .ques-body-container {
        width: 375px;
        height: auto;
        display: flex;
        align-self: center;
        align-items: center;

    }
    .ques-header-container {
        width: 375px;
        display: flex;
        align-self: center;
        align-items: center;

    }
}



.ques-header-title {
    width: 375px;
    color: #1B1625;
    text-align: start;
    font-family: Gordita-Medium;
    font-size: 16px;
    margin-left: 18px;
    margin-top: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 150% */
}



.ques-title {
    color: #1B1625;
    text-align: center;
    width: 343px;
    /* Heading 1/Regular */
    font-family: Galaxie-Copernicus-Book;
    font-size: 24px;
    margin-top: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px; /* 133.333% */
}

.ques-subtitle {
    width: 323px;
    color: #1A1A1A;
    text-align: center;
    font-family: Poppins-Regular;
    font-size: 14px;
    font-style: normal;
    margin-top: 12px;
    font-weight: 300;
    line-height: 24px; /* 171.429% */
}

.ques-subtitle span {
    color: #231DD7;
    font-family: Poppins-Regular;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

.ques-option{
    color: #111;
    font-family: Poppins-Regular;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}
.ques-option span{
    color: #534DE0;
    font-family: Poppins-Regular;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 171.429% */
}
.question-on-signup{
    color:#1B1625;
    text-align: center;
    width: 327px;
    /* Sub Heading/Medium */
    font-family: Gordita-Medium;
    font-size: 20px;
    margin-top: 26px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 140% */
}
.question-button{
    display: flex;
    height: 56px;
    width: 120px;
    margin-left: 12px;
    margin-right: 12px;
    padding: 8px 12px;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 12px;
    flex: 1 0 0;
    border-radius: 12px;
    border: 1px solid #D6D6D6;
    background: var(--White, #FFF);
}

.question-button-selected{
    display: flex;
    height: 56px;
    width: 120px;
    margin-left: 12px;
    margin-right: 12px;
    padding: 8px 12px;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 12px;
    flex: 1 0 0;
    border-radius: 12px;
    border: 1px solid var(--Primary-Color, #4462EF);
    background: var(--Primary-Background, #E6EAFF);
}

.question-select-option{
    color: var(--Black-Shade, #1B1625);
    text-align: center;
    /* Body 1/Medium */
    font-family: Gordita-Medium;
    font-size: 16px;
    margin-left: -8px;
    margin-top: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
}
