.setting-list-item-container{
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: auto;
    padding: 8px 0px;
}
.setting-list-item-title{
    color: var(--Body-Font, #4A4A4A);
    font-family: Gordita-Medium;
    font-size: 14px;
    margin-left: 8px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.2px;
}
