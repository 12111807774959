.logout-container{
    display: flex;
    width: 160px;
    padding: 12px 16px;
    margin-top: 20px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-bottom: 84px;
    border-radius: 12px;
    border: 1px solid var(--Error, #EB5757);
}
.logout-title{
    color: var(--Error, #EB5757);
    font-family: Gordita-Medium;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 125% */
}
