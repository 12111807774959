.bottom-nav-container {
	width: 100%;
	height: 100%;
	min-height: 84px;
	position: relative;
	padding-bottom: 84px;
	box-sizing: border-box;
}

.bottom-nav-tab-list-three {
	list-style: none;
	padding: 0 0 12px 0;
	width: 100%;
	height: 84px;
	display: flex;
	text-align: center;
	justify-content: space-between;
	align-items: center;
	z-index: 1000; /* Ensure the navigation is above other content */
	border-radius: 12px 12px 0px 0px;
	background: #fff;
	box-shadow: 0px -4px 12px 0px rgba(0, 0, 0, 0.08);
	position: fixed;
	bottom: -24px;
}

.bottom-nav-tab-list-three.dark {
	background: #232323;
}

.bottom-nav-tab-list-three .react-tabs__tab {
	border: none;
	cursor: pointer;
	/*padding: 12px;*/
	width: 33%;
	border-radius: 10px;
	text-align: center;
	align-self: center;
	background: white;
	/*font-family: Poppins-Regular;*/
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
}

.bottom-nav-tab-list-three.dark .react-tabs__tab {
	background-color: #232323;
}

.bottom-nav-tab-list-three .react-tabs__tab--selected {
	border-radius: 6px;
	width: 33%;
	/*padding: 12px;*/
	text-align: center;
	align-self: center;
	/*border: 1px solid #4690FF;*/
	/*background: #4690FF;*/
	/*box-shadow: 0px 2px 8px 0px rgba(70, 144, 255, 0.32);*/
	color: white; /* Text color for selected tab */
	/*font-family: Poppins-Regular;*/
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
}

.bottom-nav-card-tag-title {
	color: var(--Body-Font, #1b1625);
	font-family: Gordita-Regular;
	font-size: 12px;
	font-style: normal;
	margin-top: 6px;
	font-weight: 400;
	line-height: 16px; /* 133.333% */
}

.bottom-nav-tab-list-three.dark .bottom-nav-card-tag-title {
	color: #a4a4a4;
}

.bottom-nav-tab-list-three.dark .react-tabs__tab svg {
	opacity: 0.5;
}

.bottom-nav-tab-list-three.dark .react-tabs__tab--selected svg {
	opacity: 1;
}

.course-tab-badge {
	background-color: #eb5757;
	color: var(--White, #fff);
	font-family: Gordita-Medium;
	font-size: 7px;
	font-weight: 500;
	padding: 2.5px;
	position: absolute;
	top: -6px;
	border-radius: 2.4px;
	left: 50%;
	transform: translateX(25%);
	margin-bottom: 0;
}

.bottom-nav-tab-list-three.dark
	.react-tabs__tab--selected
	.bottom-nav-card-tag-title {
	color: #ffffff;
}

.bottom-nav-card-tag-title.selected {
	color: var(--Black, #1b1625);
	font-family: Gordita-Medium;
	font-weight: 500;
}

.bottom-nav-tab-list-three .react-tabs__tab--selected {
	border: none !important;
	outline: none !important;
	box-shadow: none !important;
}
