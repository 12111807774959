.payment-success-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100vh;
	align-self: center;
	width: 100%;
	margin-top: -10%;
	overflow: hidden;
	position: relative;
}
.loader-spinner {
	border: 4px solid #f3f3f3;
	border-top: 4px solid #D6D6D6;
	border-radius: 50%;
	width: 40px;
	height: 40px;
	animation: spin 1s linear infinite;
	margin: 20px auto;
}

@keyframes spin {
	0% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}