/* styles.css */

.customer-experience-page-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	align-self: center;
	overflow-x: hidden;
	margin-top: 24px;
}
.customer-experience-page-container-white-bg {
	display: flex;
	overflow-x: hidden;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	align-self: center;
	background: white !important;
	margin-top: -24px;
	background-size: cover;
}

.customer-experience-volume {
	position: absolute;
	z-index: 1000;
	top: 32px;
	right: 28px;
}

.customer-experience-header-container {
	display: flex;
	width: 95%;
	justify-content: space-between;
	flex-direction: row;
	align-items: center;
}

.customer-experience-header-container-white-bg {
	margin-top: 46px;
	display: flex;
	width: 95%;
	justify-content: space-between;
	flex-direction: row;
	align-items: center;
}

.customer-experience-body-container {
	display: flex;
	width: 95%;
	justify-content: center;
	flex-direction: column;
	align-items: center;
}

/* Media query for screen height of 812px and width of 375px */
@media screen and (max-width: 414px) {
	.customer-experience-page-container {
		width: 100%;
		height: auto;
		align-items: center;
	}
	.customer-experience-page-container-white-bg {
		width: 100%;
		height: auto;
		align-items: center;
		background: white !important;
		margin-top: -24px;
		background-size: cover; /* Adjust the background size as needed */
	}
	.customer-experience-header-container {
		width: 95%;
		height: auto;
		justify-content: space-between;
		align-items: center;
	}
	.customer-experience-header-container-white-bg {
		width: 95%;
		margin-top: 46px;
		height: auto;
		justify-content: space-between;
		align-items: center;
	}
	.customer-experience-body-container {
		width: 95%;
		align-items: center;
	}
}

/* Additional media queries for other screen sizes if needed */
@media screen and (min-width: 414px) {
	.customer-experience-page-container {
		width: 400px;
		height: auto;
		align-items: center;
	}
	.customer-experience-page-container-white-bg {
		width: 400px;
		height: auto;
		align-items: center;
		background: white !important;
		margin-top: -24px;
		background-size: cover; /* Adjust the background size as needed */
	}
	.customer-experience-header-container {
		width: 375px;
		height: auto;
		justify-content: space-between;
		align-items: center;
	}
	.customer-experience-header-container-white-bg {
		width: 375px;
		margin-top: 46px;
		height: auto;
		justify-content: space-between;
		align-items: center;
	}
	.customer-experience-body-container {
		width: 375px;
		align-items: center;
	}
}

.customer-experience-benefits-container {
	display: flex;
	width: 327px;
	/*max-height: 292px;;*/
	padding: 16px 16px 0px 16px;
	flex-direction: column;
	justify-content: center;
	align-self: center;
	align-items: start;
	border-radius: 12px;
	background: #f4f4f4;
}

.customer-experience-benefits-title {
	color: #1b1625;
	/* Sub Heading/Medium */
	font-family: Gordita-Medium;
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: 28px; /* 140% */
}

.morning-slots-title {
	color: var(--Black-Shade, #1b1625);
	/* Body 2/Medium */
	font-family: Gordita-Medium;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 20px; /* 142.857% */
}

.whatsapp-reminder-time {
	color: var(--Body_Font, #4a4a4a);
	text-align: center;

	/* Body 1/Regular */
	font-family: Gordita-Regular;
	font-size: 16px;
	margin-top: 148px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px; /* 150% */
}

.attend-any-time {
	color: var(--Body_Font, #4a4a4a);
	/* Body 1/Medium */
	font-family: Gordita-Medium;
	font-size: 16px;
	margin-top: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: 24px; /* 150% */
}
.morning-slots-timings {
	color: var(--Body_Font, #4a4a4a);
	/* Body 2/Regular */
	font-family: Gordita-Regular;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px; /* 142.857% */
}

.customer-experience-animation-container {
	display: flex;
	width: 327px;
	height: 287px;
	padding: 24px 12px 8px 12px;
	flex-direction: column;
	align-items: flex-start;
	gap: 12px;
	flex-shrink: 0;
	border-radius: 12px;
	border: 1px solid #d6d6d6;
	background: #fff;
}

.customer-experience-body-title {
	width: 337px;
	color: #1b1625;
	z-index: 2;
	text-align: center;
	font-family: Galaxie-Copernicus-Book;
	font-size: 24px;
	font-style: normal;
	margin-top: 32px;
	font-weight: 500;
	line-height: 32px; /* 150% */
}

.customer-experience-body-animation-subtitle {
	color: var(--Black-Shade, #1b1625);
	text-align: center;
	margin-top: 27px;
	/* Body 2/Regular */
	font-family: Gordita-Regular;
	font-size: 14px;
	font-style: normal;
	width: 100%;
	max-width: 327px;
	font-weight: 400;
	line-height: 20px; /* 142.857% */
}

.customer-experience-body-animation-title {
	color: #1b1625;
	/* Sub Heading/Medium */
	font-family: Gordita-Medium;
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: 28px; /* 140% */
}

.customer-experience-header-title {
	width: 343px;
	color: #1b1625;
	text-align: start;
	font-family: Gordita-Medium;
	font-size: 16px;
	margin-left: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: 22px; /* 150% */
}

.customer-experience-subtitle {
	width: 343px;
	color: #1a1a1a;
	text-align: center;
	font-family: Poppins-Regular;
	font-size: 14px;
	font-style: normal;
	margin-top: 28px;
	font-weight: 300;
	line-height: 24px; /* 171.429% */
}

.customer-experience-icon-with-title {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 56px;
	width: 100%;
	max-width: 343px;
	padding: 12px 16px;
	margin-top: 16px;
	gap: 8px;
	flex: 1 0 0;
	border-radius: 12px;
	border: 1px solid var(--Inactive-Color, #d6d6d6);
	background: #fff;
}

.selected-customer-experience-icon-with-title {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 56px;
	width: 100%;
	max-width: 343px;
	padding: 12px 16px;
	margin-top: 16px;
	gap: 8px;
	flex: 1 0 0;
	border-radius: 12px;
	border: 1px solid #4462ef;
	background: #e6eaff;
}

.icon-container {
	width: 30px;
	height: 30px;
	background-color: #3498db; /* Example background color for the icon */
	margin-right: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fff; /* Example color for the icon */
	border-radius: 50%;
}

.customer-experience-title {
	color: var(--Black-Shade, #1b1625);
	align-self: start;
	align-items: start;
	/* Body 1/Regular */
	font-family: Gordita-Regular;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px; /* 150% */
}

.selected-customer-experience-title {
	color: var(--Black-Shade, #1b1625);
	text-align: center;
	/* Body 1/Medium */
	font-family: Gordita-Medium;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 24px; /* 150% */
}

.customer-experience-thankYou-body-container {
	display: flex;
	height: auto;
	width: 100%;
	max-width: 375px;
	flex-direction: column;
	align-items: flex-start;
	gap: 12px;
	margin-left: 16px;
	flex-shrink: 0;
	margin-top: 192px;
}
.customer-experience-thankyou-title {
	color: var(--Body_Font, #4a4a4a);
	/* Sub Heading/Medium */
	font-family: Gordita-Medium;
	font-size: 20px;
	margin-top: 24px;
	max-width: 343px;
	width: 100%;
	font-style: normal;
	font-weight: 500;
	line-height: 28px;
}

.customer-experience-thankyou-subtitle {
	color: var(--Body_Font, #4a4a4a);
	/* Sub Heading/Regular */
	font-family: Gordita-Regular;
	font-size: 20px;
	width: 100%;
	margin-top: -4px;
	max-width: 343px;
	font-style: normal;
	font-weight: 400;
	line-height: 28px; /* 140% */
}

.customer-experience-body-animation {
	display: flex;
	/*justify-content: center;*/
	/*align-items: center;*/
	margin-top: -16px;
	height: 100vh;
	flex-direction: column;
}

.customer-experience-body-week {
	color: var(--Body_Font, #4a4a4a);
	/* Label/Medium */
	font-family: Gordita-Medium;
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

@keyframes draw {
	from {
		stroke-dashoffset: 1000; /* Length of the line */
	}
	to {
		stroke-dashoffset: 0; /* No offset, line fully drawn */
	}
}

.drawn-line {
	stroke-dasharray: 1000; /* Length of the line */
	animation: draw 10s ease forwards; /* Duration and timing function */
}

.fade-enter {
	opacity: 0;
	transition: opacity 2s ease-in;
}
.fade-enter-active {
	opacity: 1;
	/*transition: opacity 2s ease-in;*/
}
.fade-exit {
	opacity: 1;
	transition: opacity 2s ease-out;
}
.fade-exit-active {
	opacity: 0;
	/*transition: opacity 2s ease-out;*/
}
