.shimmer-wrapper {
	width: 100%;
	max-width: 400px;
	height: 237px; /* Adjust the height as needed */
	background: #f6f7f8;
	position: relative;
	overflow: hidden;
}

.shimmer-wrapper.dark {
	background-color: #1b1625;
}

.shimmer {
	width: 100%;
	height: 100%;
	margin-top: 16px;
	background: linear-gradient(
		to right,
		#f6f7f8 0%,
		#e0e0e0 20%,
		#f6f7f8 40%,
		#f6f7f8 100%
	);
	background-size: 200% 100%;
	animation: shimmer 2s infinite;
}

.shimmer-wrapper.dark .shimmer {
	background: linear-gradient(
		to right,
		#dddddd99 0%,
		#dddddd33 20%,
		#dddddd99 40%,
		#dddddd99 100%
	);
	background-size: 200% 100%;
}

@keyframes shimmer {
	0% {
		background-position: -200% 0;
	}
	100% {
		background-position: 200% 0;
	}
}
