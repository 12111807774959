/* styles.css */
.priorities-page-container {
    display: flex;
    overflow-x: hidden;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    margin-top: 24px;
}

.priorities-header-container {
    display: flex;
    width: 95%;
    justify-content: center;
    flex-direction: row;
    align-items: center;
}


.priorities-body-container {
    display: flex;
    width: 95%;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}



/* Media query for screen height of 812px and width of 375px */
@media screen and (max-width: 360px){
    .priorities-page-container{
        width: 100%;
        height: auto;
        align-items: center;
    }
    .priorities-body-container {
        width: 95%;
        height: auto;
        align-items: center;
        align-self: center;

    }
    .priorities-header-container {
        width: 95%;
        align-items: center;

    }
}

/* Media query for screen height of 812px and width of 375px */
@media screen and (min-width: 360px) and (max-width: 414px){
    .priorities-page-container{
        width: 100%;
        height: auto;
        align-items: center;
    }
    .priorities-body-container {
        width: 95%;
        height: auto;
        align-items: center;

    }
    .priorities-header-container{
        width: 95%;
        align-items: center;

    }
}

/* Additional media queries for other screen sizes if needed */
@media screen and (min-width: 414px) {
    .priorities-page-container{
        width: 400px;
        height: auto;
        display: flex;
        align-self: center;
        align-items: center;
    }
    .priorities-body-container {
        width: 375px;
        height: auto;
        display: flex;
        align-self: center;
        align-items: center;

    }
    .priorities-header-container {
        width: 375px;
        display: flex;
        align-self: center;
        align-items: center;

    }
}


.priorities-header-title {
    width: 375px;
    color: #1B1625;
    text-align: start;
    font-family: Gordita-Medium;
    font-size: 16px;
    align-self: center;
    align-items: center;
    margin-left: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 150% */
}

.priorities-title {
    width: 375px;
    color: #1B1625;
    text-align: center;
    font-family: Galaxie-Copernicus-Book;
    font-size: 24px;
    font-style: normal;
    margin-top: 24px;
    font-weight: 500;
    line-height: 32px; /* 150% */
}

.priorities-subtitle {
    width: 375px;
    color: #4A4A4A;
    text-align: center;
    font-family: Gordita-Regular;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
}


.priorities-icon-with-title {
    display: flex;
    align-items: center;
    align-self: center;
    height: 56px;
    width: 100%;
    max-width: 327px;
    /*margin-left: 24px;*/
    padding: 12px 16px;
    margin-top: 16px;
    gap: 8px;
    flex: 1 0 0;
    justify-content: space-between;
    border-radius: 12px;
    border: 1px solid var(--Inactive-Color, #D6D6D6);
    background: var(--White, #FFF);
}

.selected-priorities-icon-with-title {
    display: flex;
    align-items: center;
    align-self: center;
    height: 56px;
    width: 100%;
    padding: 12px 16px;
    margin-top: 16px;
    gap: 8px;
    flex: 1 0 0;
    border-radius: 12px;
    border: 1px solid var(--Primary-Color, #4462EF);
    background: var(--Primary-Background, #E6EAFF);
}

.icon-container {
    width: 30px;
    height: 30px;
    background-color: #3498db; /* Example background color for the icon */
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff; /* Example color for the icon */
    border-radius: 50%;
}

.title {
    color: #1A1A1A;
    font-family: Poppins-Regular;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
    text-transform: capitalize;
}

.disabled-option{
    opacity: 0.4;
    pointer-events: none; /* Prevent user interaction */
}


.priorities-row-title {
    color: #1A1A1A;
    font-family: Gordita-Medium;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    margin-left: 12px;
    line-height: 22px; /* 157.143% */
    text-transform: capitalize;
}

