.plan-status-banner-card {
    position: relative;
    max-width: 400px; /* Limit max width */
    margin: 0 auto; /* Center the container */
    /*overflow: hidden; !* Hide overflowing content *!*/
    display: flex;
    width: 100%;
    height: auto;
    padding: 12px;
    flex-direction: column;
    align-items: flex-start;
    /*gap: 12px;*/
    border: none;
    border-radius: 12px;
    background: var(--Secondary-Color-LIght-Backgound, #FFF3E4);
}


.plan-status-banner-content-wrapper{
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;
}


.plan-status-banner-animation{
    position: absolute;
    top: 4px;
    left: 144px;
}

/* Media query for screen height of 812px and width of 375px */
@media screen and (max-width: 414px){
    .plan-status-banner-card{
        width: 90%;
        height: auto;
    }
    .plan-status-banner-animation{
        position: absolute;
        top: 4px;
        left: 144px;
    }

}

/* Additional media queries for other screen sizes if needed */
@media screen and (min-width: 414px) {
    .plan-status-banner-card{
        width: 400px;
        height: auto;
    }
    .plan-status-banner-animation{
        position: absolute;
        bottom: 132px;
        left: 31%;
    }
}

.plan-status-banner-title {
    color: var(--Body_Font, #4A4A4A);
    text-align: start;
    font-family: Gordita-Regular;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
}

.plan-status-banner-subtitle {
    color: var(--Body-Font, #4A4A4A);
    text-align: start;
    font-family: Gordita-Medium;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    margin-top: 2px;
    line-height: 28px; /* 142.857% */
}

.plan-status-banner-subtext{
    color: var(--Secondary-Color-1, #4A4A4A);
    font-family: Gordita-Medium;
    text-align: start;
    font-size: 12px;
    margin-top: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 133.333% */
 }


.plan-status-banner-cta{
    border-radius: 8px;
    background: var(--Primary-Gradient, linear-gradient(106deg, #4462EF 32.65%, #1B3DD8 105.18%));
    display: flex;
    width: 40%;
    margin-top: 12px;
    padding: 12px;
    justify-content: center;
    align-items: center;
    /*gap: 6px;*/
    flex: 1 0 0;
}

