.change-slot-page-container {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center horizontally */
    justify-content: center; /* Center vertically */
    width: 375px;
    margin: 32px auto 0 auto;
    padding: 16px 16px;
}

.change-slot-header-container {
    width: 375px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}
.change-slot-body-container {
    width: 375px;
    display: flex;
    margin-top: 18px;
    justify-content: space-between;
    flex: 3;
    align-items: center;
    align-self: center;
    flex-direction: row;
}

.change-slot-title {
    width: 375px;
    color: #1A1A1A;
    text-align: center;
    font-family: Lora-Regular;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    margin-top: 24px;
    line-height: 36px; /* 150% */
}

.change-slot-subtitle {
    color: #000;
    text-align: center;
    font-family: Poppins-Regular;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    margin-top: 64px;
    line-height: 28px; /* 140% */
    text-transform: capitalize;
}

.change-slot-bottom-container{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 343px;
    margin-top: 32px;
}

.change-slot-description {
    color: #1A1A1A;
    text-align: center;
    font-family: Poppins-Regular;
    font-size: 14px;
    align-items: end;
    padding-bottom:120px;
    width: 345px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px; /* 171.429% */
}
.change-slot-description span{
    color: #534DE0;
    font-family: Poppins-Regular;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}


.change-slot-icon-with-title {
    display: flex;
    height: 56px;
    width: 56px;
    flex: 1;
    justify-content: center;
    align-self: center;
    margin:8px;
    background: #F5EEFF;
}

.selected-change-slot-icon-with-title {
    display: flex;
    height: 56px;
    width: 56px;
    flex: 1;
    justify-content: center;
    align-self: center;
    margin:8px;
    border: 1px solid #8B2EFF;
    background: #E7D3FF;
}


.icon-container {
    width: 30px;
    height: 30px;
    background-color: #3498db; /* Example background color for the icon */
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff; /* Example color for the icon */
    border-radius: 50%;
}

.title {
    color: #1A1A1A;
    font-family: Poppins-Regular;
    font-size: 14px;
    font-style: normal;
    text-align: center;
    align-self: center;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
    text-transform: capitalize;
}
