.upgrade-banner-card {
    position: relative;
    max-width: 400px; /* Limit max width */
    margin: 0 auto; /* Center the container */
    overflow: hidden; /* Hide overflowing content */
    display: flex;
    width: 100%;
    height: auto;
    padding: 12px;
    flex-direction: column;
    align-items: flex-start;
    /*gap: 12px;*/
    border-radius: 12px;
    border: 1px solid var(--Light-Grey-2, #E4E4E4);
    background: var(--White, #fff);
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);
}

.upgrade-banner-animation{
    position: absolute;
    top: 4px;
    left: 144px;
}

/* Media query for screen height of 812px and width of 375px */
@media screen and (max-width: 414px){
    .upgrade-banner-card{
        width: 90%;
        height: auto;
    }
    .upgrade-banner-animation{
        position: absolute;
        top: 4px;
        left: 144px;
    }

}

/* Additional media queries for other screen sizes if needed */
@media screen and (min-width: 414px) {
    .upgrade-banner-card{
        width: 400px;
        height: auto;
    }
    .upgrade-banner-animation{
        position: absolute;
        bottom: 132px;
        left: 31%;
    }
}

.upgrade-banner-title {
    color: var(--Body_Font, #4A4A4A);
    text-align: center;
    /* Sub Heading/Medium */
    font-family: Gordita-Medium;
    font-size: 20px;
    z-index: 10;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 140% */
}


.upgrade-banner-subtitle {
    color: var(--Body-Font, #4A4A4A);
    text-align: center;
    font-family: Gordita-Regular;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-top: 4px;
    line-height: 20px; /* 142.857% */
}
.upgrade-banner-cta{
    border-radius: 8px;
    background: var(--Primary-Gradient, linear-gradient(106deg, #4462EF 32.65%, #1B3DD8 105.18%));
    display: flex;
    width: 100%;
    margin-top: 12px;
    padding: 12px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    flex: 1 0 0;
}

