.karma-points-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #E4E4E4;;
    padding-bottom: 12px;
    padding-top: 12px;
}

.karma-date{
    color: #2A2A2A;
    text-align: center;
    font-family: Poppins-Regular;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.karma-points-value {
    text-align: center;
    align-self: center;
    width: 80px;
    color: #2A2A2A;
    font-family: Poppins-Regular;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.karma-points-surname {
    color: #2A2A2A;
    font-family: Poppins-Regular;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}



.karma-icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
}
