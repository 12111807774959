.congrats-page-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    margin-top: 32px;
    max-width: 400px;
}

.congrats-header-container {
    display: flex;
    width: 95%;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
}

.congrats-body-container {
    display: flex;
    width: 95%;
    align-self: center;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}


/* Media query for screen height of 812px and width of 375px */
@media screen and (max-width: 414px) and (min-height: 640px) and (max-height: 740px){
    .congrats-page-container{
        width: 100%;
        height: auto;
        justify-content: center;
        align-items: center;
        max-width: 400px;
    }
    .congrats-header-container {
        width: 95%;
        height: auto;
        justify-content: space-between;
        align-items: center;

    }
    .congrats-body-container{
        width: 95%;
        align-items: flex-start;
        margin-top: 36%;
        justify-content: center;
        align-self: center;

    }
}

/* Media query for screen height of 812px and width of 375px */
@media screen and (max-width: 414px) and (min-height: 740px){
    .congrats-page-container{
        width: 100%;
        height: auto;
        justify-content: center;
        align-items: center;
    }
    .congrats-header-container {
        width: 95%;
        height: auto;
        justify-content: space-between;
        align-items: center;

    }
    .congrats-body-container{
        width: 95%;
        align-items: flex-start;
        margin-top: 36%;
        justify-content: center;
        align-self: center;

    }
}


/* Media query for screen height of 812px and width of 375px */
@media screen and (min-width: 414px){
    .congrats-page-container{
        width: 400px;
        height: auto;
        justify-content: center;
        align-items: center;
    }
    .congrats-header-container {
        width: 375px;
        height: auto;
        display: flex;
        align-self: center;
        align-items: center;

    }
    .congrats-body-container{
        width: 375px;
        margin-top: 40%;
        display: flex;
        align-self: center;
        align-items: center;

    }
}

.congrats-title {
    color: var(--Body_Font, #4A4A4A);
    text-align: center;
    align-self: center;
    /* Body 1/Regular */
    font-family: Gordita-Regular;
    max-width: 343px;
    width: 100%;
    /*margin-left: 12px;*/
    margin-top: 2px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
}

.congrats-subtitle {
    color: var(--Body_Font, #4A4A4A);
    /* Sub Heading/Regular */
    font-family: Gordita-Regular;
    font-size: 20px;
    max-width: 343px;
    width: 100%;
    text-align: center;
    align-self: center;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 140% */
}
