/* styles.css */

.customer-testimonial-page-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    margin-top: 24px;
}

.customer-testimonial-header-container {
    display: flex;
    width: 95%;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
}
.customer-testimonial-body-container {
    display: flex;
    width: 95%;
    height: 292px;
    margin-top: 24px;
    padding: 16px 12px;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    flex-shrink: 0;
    border-radius: 12px;
    background: var(--Secondary-Background-1, #F4F4F4);
    background-image: url('../../../assets/stars.png');
    background-repeat: no-repeat; /* Prevent image from repeating */
    background-position: center 104px;
}



/* Media query for screen height of 812px and width of 375px */
@media screen and (max-width: 414px){
    .customer-testimonial-page-container{
        width: 100%;
        height: 100%;
        align-items: center;
    }
    .customer-testimonial-body-container{
        width: 95%;
        height: 300px;
        align-items: center;

    }
    .customer-testimonial-header-container{
        width: 95%;
        align-items: center;

    }
}

/* Additional media queries for other screen sizes if needed */
@media screen and (min-width: 414px) {
    .customer-testimonial-page-container{
        width: 400px;
        height: 100%;
        display: flex;
        align-self: center;
        align-items: center;
    }
    .customer-testimonial-body-container{
        width: 375px;
        height: 292px;
        margin-top: 8%;
        display: flex;
        align-self: center;
        align-items: center;

    }
    .customer-testimonial-header-container {
        width: 375px;
        display: flex;
        align-self: center;
        align-items: center;
    }
}


.customer-testimonial-title {
    color:#1B1625;
    text-align: center;
    width: 343px;
    height: auto;
    padding-left: 16px;
    padding-right: 16px;
    font-family: Galaxie-Copernicus-Book;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    z-index: 2;
    margin-top: 32px;
    line-height: 32px; /* 133.333% */
}

.customer-testimonial-name {
    color: var(--Black-Shade, #1B1625);
    /* Sub Heading/Medium */
    align-self: start;
    font-family: Gordita-Medium;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 140% */
}

.customer-testimonial-description {
    color: var(--Body_Font, #4A4A4A);
    /* Body 2/Regular */
    font-family: Gordita-Regular;
    font-size: 14px;
    margin-top: -12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
}

.slider {
    display: flex;
    transition: transform 0.5s ease-in-out;
}

.content {
    flex: 1 0 100%;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

.content.active {
    opacity: 1;
}



