.post-ob-container{
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    max-width: 400px;
    margin-top: 64px;
    align-self: center;
    background: white;

}
.post-ob-header-container {
    display: flex;
    width: 95%;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
}

.post-ob-body-container{
    display: flex;
    width: 90%;
    background:#F4F4F4;
    border-radius: 12px;
    padding: 16px;
    justify-content: center;
    flex-direction: column;
    align-items: start;
}


/* Media query for screen height of 812px and width of 375px */
@media screen and (max-width: 414px) and (min-height: 640px) and (max-height: 740px){
    .post-ob-container{
        width: 100%;
        height: auto;
        justify-content: center;
        align-items: center;
    }
    .post-ob-header-container {
        width: 95%;
        height: auto;
        justify-content: space-between;
        align-items: center;

    }
    .post-ob-body-container{
        width: 90%;
        background:#F4F4F4;
        align-items: flex-start;
        margin-top: 40px;
        justify-content: center;

    }
}





/* Media query for screen height of 812px and width of 375px */
@media screen and (max-width: 414px) and (min-height: 740px){
    .post-ob-container{
        width: 100%;
        height: auto;
        justify-content: center;
        align-items: center;
    }
    .post-ob-header-container {
        width: 95%;
        height: auto;
        justify-content: space-between;
        align-items: center;

    }
    .post-ob-body-container {
        width: 90%;
        background:#F4F4F4;
        align-items: flex-start;
        margin-top: 64px;
        justify-content: center;

    }
}


/* Media query for screen height of 812px and width of 375px */
@media screen and (min-width: 414px) and (min-height: 912px){
    .post-ob-container{
        width: 400px;
        height: auto;
        justify-content: center;
        align-items: center;
    }
    .post-ob-header-container {
        width: 375px;
        height: auto;
        display: flex;
        align-self: center;
        align-items: center;

    }
    .post-ob-body-container {
        width: 375px;
        max-width: 400px;
        margin-top: 92px;
        display: flex;
        align-self: center;
        align-items: flex-start;

    }
}


.post-ob-account-title{
    color: #1B1625;
    text-align: start;
    font-family: Galaxie-Copernicus-Book;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
}

.post-ob-video-animation-body-container {
    display: flex;
    width: 95%;
    height: 100%;
    position:relative;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    /*background: #575bdd !important;*/
    /*width: 100%;*/
    /*height: auto;*/
    /*flex-shrink: 0;*/
    /*border-radius: 16px;*/
    /*margin-bottom: 120px;*/
    /*display: flex;*/
    /*padding-left: 16px;*/
    /*padding-top: 16px;*/
    /*justify-content: start;*/
    /*align-items: start;*/
    /*flex-direction: column;*/
    /*border: 1px solid #534DE0;*/
    background: rgba(255, 255, 255, 0.64);
    /*backdrop-filter: blur(13px);*/
}

