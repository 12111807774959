.community-container {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center horizontally */
    justify-content: center; /* Center vertically */
    width: 375px;
    margin: 24px auto 0 auto;
    padding: 16px;
}

.community-details{
    color: #1A1A1A;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    width: 343px;
    margin-left: 8px;
    margin-top: 26px;
    text-align: start;
    line-height: normal;
}

.community-toolbar-title{
    color: #1A1A1A;
    text-align: start;
    align-self: start;
    font-family: Poppins;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 141.176% */
}
.community-toolbar-subtitle{
    color: #2A2A2A;
    font-family: Lora;
    font-size: 20px;
    align-items: center; /* Center horizontally */
    justify-content: center; /* Center vertically */
    margin-top: 28px;
    text-align: center;
    align-self: center;
    font-style: normal;
    margin-right: 248px;
    font-weight: 400;
    line-height: 28px; /* 140% */
}

