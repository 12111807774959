/* CustomComponent.css */

.custom-component {
    position: relative;
    width: 343px;
    height: 108px;
    margin-top: 16px;
    border-radius: 16px; /* Add border-radius for rounded corners if needed */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add box shadow for elevation */
}

