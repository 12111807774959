.toggle-component {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding: 12px 12px 14px;
	align-items: flex-start;
	margin-top: 12px;
	border-radius: 12px;
	border: 1px solid #e4e4e4;
}

.toggle-component.with-title {
	border: 1px solid var(--purple-gradient, #e4e4e4);
}

.left-content {
	align-items: center;
	width: 80%;
}

.right-content {
	text-align: right;
	align-self: center;
	padding-top: 6px;
}

.toggle-title {
	color: #1a1a1a;
	font-family: Gordita-Regular;
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 28px; /* 140% */
}

.selected-toggle-title {
	color: #1a1a1a;
	font-family: Gordita-Medium;
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: 28px; /* 140% */
}

.toggle-subtitle {
	color: #1a1a1a;
	font-family: Gordita-Regular;
	font-size: 12px;
	margin-top: -6px;
	font-style: italic;
	font-weight: 400;
	margin-bottom: 0;
	line-height: 16px; /* 133.333% */
}

/* Styles for the switch toggle */
.switch {
	position: relative;
	display: inline-block;
	width: 52px;
	height: 28px;
}

.switch input {
	opacity: 0;
	width: 0;
	height: 0;
}

.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	transition: 0.4s;
	border-radius: 20px;
}

.slider:before {
	position: absolute;
	content: '';
	height: 16px;
	width: 16px;
	left: 2px;
	bottom: 2px;
	background-color: white;
	transition: 0.4s;
	border-radius: 50%;
}

input:checked + .slider {
	background-color: #4462ef;
}

input:checked + .slider:before {
	transform: translateX(20px);
}

/* Rounded sliders */
.slider.round {
	border-radius: 34px;
}

.slider.round:before {
	border-radius: 50%;
}

/* Added styles for reducing opacity when 4 toggle buttons are selected */
.toggle-component.selected-four .left-content,
.toggle-component.selected-four .right-content {
	opacity: 0.4; /* Adjust opacity value as needed */
}
