.plan-addon-bottom-sheet {
	max-height: 80vh;
	overflow-y: scroll;
	overscroll-behavior: none;
	padding-bottom: 144px;
}

.plan-addon-bottom-sheet::-webkit-scrollbar {
	display: none;
}

.plan-addon-bottom-sheet-banner {
	position: relative;
	padding-top: 24px;
	background-image: linear-gradient(180deg, #201c55, #964fa7, #ffffff);
}

.plan-addon-bottom-sheet-banner-bg {
	width: 100%;
	position: absolute;
	top: 0;
	z-index: -1;
}

.plan-addon-bottom-sheet-banner-card {
	width: 90%;
	margin-left: 5%;
}

.plan-addon-bottom-sheet-banner-timer-wrapper {
	display: flex;
	justify-content: center;
}

.plan-addon-bottom-sheet-banner-timer {
	font-family: Gordita-Medium;
	text-align: center;
	color: white;
	font-size: 14px;
	background-color: #eb5757;
	display: inline;
	padding: 8px 16px;
	border-radius: 50px;
}

.plan-addon-bottom-sheet-banner-header {
	font-family: Gordita-Bold;
	text-align: center;
	color: white;
	font-size: 20px;
	margin-bottom: 0;
}

.plan-addon-bottom-sheet-banner-subtitle {
	font-family: Gordita-Medium;
	text-align: center;
	color: #ffd39e;
	font-size: 16px;
}

.plan-addon-bottom-sheet-benefits .swiper {
	padding-bottom: 20px;
}

.plan-addon-bottom-sheet-benefits-slide {
	background-color: #f3f5f8;
	padding: 8px;
	border-radius: 8px;
	font-family: Gordita-Regular;
	font-size: 14px;
}

.plan-addon-bottom-sheet-benefits .swiper-pagination {
	top: 60px !important;
}

.plan-addon-bottom-sheet-benefits .swiper-pagination-bullet {
	border: 1px solid #d6d6d6;
	background-color: #fff;
	opacity: 1 !important;
}

.plan-addon-bottom-sheet-benefits .swiper-pagination-bullet-active {
	border: 1px solid #4462ef;
}

.plan-addon-bottom-sheet-get-wrapper {
	padding-top: 20px;
	padding: 16px;
	padding-bottom: 24px;
	margin-top: 165px;
	position: relative;
}

.plan-addon-bottom-sheet-get {
	background-color: white;
	padding: 24px 16px 72px;
	border-radius: 12px;
	border: 1px solid #e4e4e4;
	position: relative;
}

.plan-addon-bottom-sheet-get-creative {
	position: absolute;
	top: 0%;
	left: 25%;
	transform: translateY(-75%);
	width: 50%;
	z-index: 1;
}
.plan-addon-bottom-sheet-get-cloud-1 {
	position: absolute;
	width: 50%;
	top: 0;
	left: 0;
	transform: translateY(-75%);
	animation: cloud 5s infinite;
}
.plan-addon-bottom-sheet-get-cloud-2 {
	position: absolute;
	width: 50%;
	top: 0;
	right: 0;
	transform: translateY(-80%);
	animation: cloud 6s infinite;
}

@keyframes cloud {
	0% {
		transform: translateY(-80%) translateX(0);
	}
	50% {
		transform: translateY(-80%) translateX(20px);
	}
	100% {
		transform: translateY(-80%) translateX(0px);
	}
}

.plan-addon-bottom-sheet-get-footer {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	padding: 8px 16px;
	display: flex;
	background-color: #fff3e4;
	border-radius: 0 0 12px 12px;
	justify-content: space-between;
}

.plan-addon-bottom-sheet-get-footer-text {
	margin-bottom: 0;
	font-family: 'Gordita-Regular';
	font-size: 14px;
	color: #4a4a4a;
}

.plan-addon-bottom-sheet-get-footer-price {
	margin-bottom: 0;
	font-family: 'Gordita-Medium';
	font-size: 14px;
	color: #4a4a4a;
}

.plan-addon-bottom-sheet-get-footer-price span {
	font-family: 'Gordita-Regular';
	margin-left: 4px;
	text-decoration: line-through;
}

.plan-addon-bottom-sheet-get-title {
	width: 80%;
	margin-left: 10%;
	margin-bottom: 16px;
}

.plan-addon-bottom-sheet-get-item {
	display: flex;
	gap: 12px;
	margin: 0 4px;
}

.plan-addon-bottom-sheet-get-item-title {
	font-family: Gordita-Medium;
	font-size: 16px;
	color: #000000;
	margin: 0;
	margin-top: 4px;
}

.plan-addon-bottom-sheet-get-item-subtitle {
	font-family: Gordita-Regular;
	font-size: 14px;
	color: #4a4a4a;
	margin: 0;
}

.plan-addon-bottom-sheet-testimonials {
	background-color: #f4f4f4;
	padding: 32px 16px;
	margin-top: 16px;
	position: relative;
}

.plan-addon-bottom-sheet-testimonials-header {
	display: flex;
	justify-content: space-between;
	margin-bottom: 16px;
}

.plan-addon-bottom-sheet-testimonials-header-title {
	font-family: Gordita-Medium;
	font-size: 16px;
	color: #000000;
	margin: 0;
}

.plan-addon-bottom-sheet-testimonials-header-rating-box {
	display: flex;
}

.plan-addon-bottom-sheet-testimonials-header-rating {
	font-family: Gordita-Medium;
	font-size: 14px;
	color: #000000;
	margin: 0;
}

.plan-addon-bottom-sheet-testimonials-header-rating-box img {
	margin: 0 4px;
	width: 16px;
	margin-bottom: 6px;
}

.plan-addon-bottom-sheet-testimonials-header-number {
	font-family: Gordita-Regular;
	font-size: 14px;
	color: #4a4a4a;
	margin: 0;
}

.plan-addon-bottom-sheet-testimonials-cloud-up {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
}

.plan-addon-bottom-sheet-testimonials-cloud-down {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
}

.plan-addon-bottom-sheet-testimonials-slide {
	padding: 16px;
	border-radius: 8px;
	background-color: #ffffff;
	border: 1px solid #d6d6d6;
	margin-bottom: 16px;
}

.plan-addon-bottom-sheet-testimonials-slide-content {
	font-family: Gordita-Regular;
	font-size: 18px;
	color: #4a4a4a;
}

.plan-addon-bottom-sheet-testimonials-slide-name {
	font-family: Gordita-Medium;
	font-size: 12px;
	color: #4a4a4a;
	margin: 0;
}

.plan-addon-bottom-sheet-testimonials-slide-exp {
	font-family: Gordita-Regular;
	font-size: 12px;
	color: #4a4a4a;
	margin: 0;
}

.plan-addon-bottom-sheet-title {
	font-family: Gordita-Medium;
	font-size: 20px;
	color: #4a4a4a;
	margin-top: 16px;
	margin-bottom: 0;
}

.plan-addon-bottom-sheet-course {
	padding: 16px;
}

.plan-addon-bottom-sheet-instructor {
	padding: 0 16px;
}

.plan-addon-bottom-sheet-faqs {
	padding: 0 16px;
}
