.plan-description-review-name {
	color: var(--Black, #1b1625);
	font-family: Gordita-Medium;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 20px; /* 142.857% */
}

.plan-description-review-period {
	color: var(--Font-grey, #a4a4a4);
	font-family: Gordita-Medium;
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: 16px; /* 133.333% */
}

.plan-description-review-bio {
	color: var(--Font-grey, #a4a4a4);
	font-family: Gordita-Regular;
	font-size: 14px;
	width: 100%;
	font-style: normal;
	font-weight: 400;
	margin-top: 8px;
	line-height: 20px; /* 142.857% */
}
