.poc-book-slot-container {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center horizontally */
    justify-content: center; /* Center vertically */
    width: 100%;
    max-width: 400px;
}
.poc-slot-form-title {
    color: #4A4A4A;
    text-align: start;
    align-self: start;
    font-family: Gordita-Regular;
    font-size: 14px;
    max-width: 400px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
}

.poc-book-slot-heading-title {
    color: #1B1625;
    font-family:Galaxie-Copernicus-Book;
    font-size: 24px;
    width: 320px;
    text-align: center;
    align-self: center;
    font-style: normal;
    font-weight: 500;
    margin-top: 32px;
    line-height: 32px; /* 125% */
}


.selected-slot{
    display: flex;
    height: 48px;
    padding: 8px 12px;
    align-items: center;
    flex: 1;
    max-width: 200px;
    border-radius: 12px;
    border: 1px solid var(--Primary-Color, #4462EF);
    background: var(--Primary-Background, #E6EAFF);
    color: var(--Black-Shade, #1B1625);
    text-align: center;
    align-self: center;
    /* Body 1/Medium */
    font-family: Gordita-Medium;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
}
.un-selected-slot{
    display: flex;
    height: 48px;
    flex: 1;
    padding: 8px 12px;
    align-items: center;
    border-radius: 12px;
    border: 1px solid #D6D6D6;
    background: var(--White, #FFF);
    color: var(--Black-Shade, #1B1625);
    text-align: center;
    align-self: center;
    align-items: center;
    max-width: 200px;
    /* Body 1/Regular */
    font-family: Gordita-Regular;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
}

.selected-slot-text{
    text-align: center;
    align-self: center;
    align-items: center;
    flex: 1;
    width: 100%;
}

.slot-custom-select {
    width: 95%;
    max-width: 400px;
    font-size: 16px;
    display: flex;
    height: 52px;
    margin-top: 8px;
    padding: 8px 12px;
    align-items: center;
    gap: 16px;
    flex: 1 0 0;
    border-radius: 12px;
    border: 1px solid #D6D6D6;
    background: #FFF;
}

.slot-custom-select:focus {
    border-color: #007bff;
    outline: none;
}

.slot-custom-option {
    padding: 8px;
    background-color: #fff;
    color: var(--Body_Font, #4A4A4A);
    font-family: Gordita-Regular;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.slot-custom-option:hover {
    background-color: #007bff;
    color: #fff;
}
