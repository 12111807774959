.exp-page-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    margin-top: 24px;
}

.exp-header-container {
    display: flex;
    width: 95%;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
}
.exp-body-container {
    display: flex;
    width: 95%;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}


/* Media query for screen height of 812px and width of 375px */
@media screen and (max-width: 360px){
    .exp-page-container{
        width: 100%;
        height: auto;
        align-items: center;
    }
    .exp-header-container {
        width: 95%;
        height: auto;
        justify-content: space-between;
        align-items: center;

    }
    .exp-body-container {
        width: 95%;
        align-items: center;

    }
}

/* Media query for screen height of 812px and width of 375px */
@media screen and (min-width: 360px) and (max-width: 414px){
    .exp-page-container{
        width: 100%;
        height: auto;
        align-items: center;
    }
    .exp-header-container {
        width: 95%;
        height: auto;
        justify-content: space-between;
        align-items: center;

    }
    .exp-body-container{
        width: 90%;
        align-items: center;

    }
}

/* Additional media queries for other screen sizes if needed */
@media screen and (min-width: 414px) {
    .exp-page-container{
        width: 400px;
        height: auto;
        display: flex;
        align-self: center;
        align-items: center;
    }
    .exp-header-container {
        width: 375px;
        height: auto;
        display: flex;
        align-self: center;
        align-items: center;

    }
    .exp-body-container {
        width: 375px;
        display: flex;
        align-self: center;
        align-items: center;

    }
}



.exp-title {
    color: #1B1625  ;
    text-align: center;
    /* Heading 1/Regular */
    font-family: Galaxie-Copernicus-Book;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    margin-top: 32px;
    line-height: 32px; /* 133.333% */
}

.exp-subtitle {
    width: 323px;
    color: #4A4A4A;
    text-align: center;
    /* Body 1/Regular */
    font-family: Gordita-Regular;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.exp-subtitle span {
    color: var(--Black-Shade, #1B1625);
    text-align: center;
    /* Body 1/Medium */
    font-family: Gordita-Medium;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
}

.exp-option{
    color: #111;
    font-family: Poppins-Regular;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}
.exp-option span{
    color: #534DE0;
    font-family: Poppins-Regular;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 171.429% */
}
