.course-checkout-container {
	display: flex;
	flex-direction: column;
	align-items: center; /* Center horizontally */
	width: 100%;
	margin: auto 0 auto;
	min-height: 100vh;
	padding: 16px;
	background-color: #1b1625;
}

.course-checkout-details {
	width: 95%;
	max-width: 375px;
	margin-top: 24px;
	text-align: start;
	color: #ffffff;
	font-family: 'Galaxie-Copernicus-Book';
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: 28px; /* 140% */
}

.course-checkout-plan-start-date {
	color: #ffffff;
	font-family: Gordita-Medium;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 20px; /* 142.857% */
}

.course-checkout-plan-start-date-value {
	color: #ffffff;
	text-align: right;
	font-family: Gordita-Medium;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

.course-checkout-recordings {
	display: flex;
	padding: 12px;
	align-items: center;
	justify-content: space-between;
	gap: 8px;
	border-radius: 12px;
	background: #232323;
	max-width: 375px;
	width: 100%;
	margin-top: 12px;
}

.course-checkout-recordings-text {
}

.course-checkout-recordings-text p {
	margin-bottom: 0;
}

.course-checkout-recordings-text-title {
	color: #ffffff;
	font-family: Gordita-Regular;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
}

.course-checkout-recordings-text-price {
}

.course-checkout-recordings-text-price-offer {
	font-family: Gordita-Bold;
	font-size: 14px;
	line-height: 20px;
	background: var(
		--secondary-grad,
		linear-gradient(108deg, #faa53d 36%, #c36f09 90.58%)
	);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.course-checkout-recordings-text-price-actual {
	color: #a4a4a4;
	font-family: Gordita-Medium;
	font-size: 12px;
	line-height: 16px;
	text-decoration: line-through;
	margin-left: 4px;
}

.course-checkout-recordings-text-benefits {
	font-family: Gordita-Bold;
	font-size: 12px;
	line-height: 24px;
	text-decoration: underline;
	color: #ffffff;
}

.course-checkout-recordings-cta {
	display: flex;
	align-items: center;
}

.apply-karma-points-container {
	margin-top: 24px;
	padding-left: 4px;
	width: 100%;
	max-width: 375px;
	height: auto;
	border-radius: 12px;
}

.apply-karma-points-container input[type='range']::-webkit-slider-thumb {
	width: 14px;
	-webkit-appearance: none;
	height: 14px;
	border-radius: 1000px;
	cursor: ew-resize;
	background: #ffffff;
}

.switch {
	position: relative;
	display: inline-block;
	width: 40px;
	height: 20px;
	margin-top: -16px;
}

.switch input {
	opacity: 0;
	width: 0;
	height: 0;
}

.course-checkout-plan-details-container {
	display: flex;
	flex-direction: row;
	gap: 8px;
	justify-content: space-between;
	margin-top: 12px;
	border-radius: 8px;
}

.course-checkout-plan-price-container {
	width: 95%;
	max-width: 375px;
	margin-top: 16px;
	justify-content: space-between;
	background-color: #232323;
	padding: 16px;
	border-radius: 12px;
	border: 1px solid #4a4a4a;
}

.course-checkout-plan-price-discount {
	color: var(--Success, #11bc59);
	text-align: right;
	font-family: Gordita-Medium;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

.course-checkout-plan-billing-container {
	display: flex;
	flex-direction: column;
	width: 375px;
	padding: 12px 16px;
	gap: 8px;
	justify-content: space-between;
	margin-top: 12px;
	border-radius: 8px;
	background: transparent;
}

.course-checkout-plan-billing-row-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.course-checkout-total-amount-pay {
	color: #2a2a2a;
	align-self: center;
	font-family: Poppins-SemiBold;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}

.course-checkout-plan-name {
	letter-spacing: 0.2px;
	width: 218px;

	color: #e4e4e4;
	font-family: Gordita-Medium;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 24px; /* 150% */
}

.course-checkout-plan-discount {
	color: #6a6a6a;
	font-family: Poppins-Medium;
	font-size: 10px;
	font-style: italic;
	font-weight: 500;
	line-height: normal;
}

.course-checkout-monthly-plan-price {
	color: #3a3a3a;
	font-family: Poppins-Bold;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}
.course-checkout-monthly-plan-price span {
	color: #3a3a3a;
	font-family: Poppins-Bold;
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

.course-checkout-plan-price {
	color: #3a3a3a;
	text-align: right;
	/*font-family: DM Sans;*/
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

.course-checkout-plan-price-total {
	color: #534de0;
	text-align: right;
	/*font-family: DM Sans;*/
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}
.course-checkout-plan-offer {
	color: var(--grey-900, #1a202c);
	margin-left: 12px;
	font-family: Poppins-Regular;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px; /* 171.429% */
}

.course-billing-details {
	color: #1a1a1a;
	font-family: Poppins-Medium;
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	margin-top: 36px;
	margin-left: 12px;
	text-align: center;
	align-self: center;
	line-height: normal;
}

.course-checkout-title {
	color: #2a2a2a;
	font-family: Poppins-Regular;
	margin-left: 4px;
	font-size: 14px;
	text-align: start;
	font-style: normal;
	font-weight: 400;
}

.course-toolbar-title {
	color: #1a1a1a;
	font-family: Poppins-Medium;
	font-size: 17px;
	font-style: normal;
	white-space: nowrap;
	font-weight: 500;
	line-height: 24px; /* 141.176% */
}

.course-risk-free-title {
	color: #f5732d;
	text-align: center;
	font-family: Poppins-SemiBold;
	font-size: 20px;
	font-style: normal;
	font-weight: 600;
	line-height: 32px; /* 160% */
}
.course-risk-free-subtitle {
	color: #1a1a1a;
	text-align: center;
	font-family: Poppins-Regular;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 160%; /* 22.4px */
	letter-spacing: 0.2px;
}
