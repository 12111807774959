.course-vertical-card-container {
	display: flex;
	align-items: stretch;
	justify-content: space-between;
	/*border: 1px solid #ccc;*/
	padding: 8px 12px;
	/*border-radius: 8px;*/
	object-fit: cover;
	width: 100%;
	max-width: 400px;
	background: #fff6ef;
	gap: 8px;
	height: 84px;
	margin-top: 24px;
	position: relative;
	overflow: hidden;
	border-radius: 8px;
}

.course-vertical-card-content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	flex: 1;
	padding-right: 20px; /* Space between text and thumbnail */
}

.course-vertical-card-title {
	/*margin-bottom: 10px;*/
	color: #1b1625;
	font-family: Gordita-Medium;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 20px; /* 142.857% */
}

.course-vertical-card-subtitle {
	/*font-size: 18px;*/
	/*font-weight: 600;*/
	margin-top: 4px;
	color: #4a4a4a;
	font-family: Gordita-Regular;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 16px; /* 133.333% */
}

.course-vertical-card-subtitle span {
	color: #4a4a4a;
	font-family: Gordita-Medium;
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: 16px;
}

.course-vertical-card-description {
	font-family: Gordita-Bold;
	font-size: 12px;
	font-style: normal;
	font-weight: 700;
	margin-top: 10px;
	line-height: 16px; /* 133.333% */
	text-decoration-line: underline;
	color: #4462ef;
}

.course-vertical-card-thumbnail {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100px; /* Fixed width for the thumbnail */
	height: 100%; /* Ensure full height */
}

.course-nudge-image-banner {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.anger-nudge-l2 {
	width: 100%;
}
