.old-button {
	padding: 10px 20px;
	background-color: #007bff;
	color: white;
	border: none;
	width: 320px;
	height: 50px;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 6px;
	border-radius: 12px;
	margin-right: 4px;
	flex: 1 0 0;
}

.old-button.disabled {
	background: var(--Light-Grey-2, #e4e4e4);
	color: var(--Font-grey, #a4a4a4);
	cursor: not-allowed;
}

.old-button-title {
	color: #fff;
	text-align: center;
	align-self: center;
	font-feature-settings: 'dlig' on;
	font-family: Gordita-Medium;
	font-size: 16px;
	padding-top: 8px;
	padding-bottom: 8px;
	font-style: normal;
	font-weight: 500;
	line-height: 16px; /* 133.333% */
}

.old-button-title.disabled {
	color: var(--Font-grey, #a4a4a4);
}
