.leaderboard-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	align-self: center;
}

/* Media query for screen height of 812px and width of 375px */
@media screen and (max-width: 360px) {
	.leaderboard-container {
		width: auto;
		height: auto;
		align-items: center;
	}
}

/* Media query for screen height of 812px and width of 375px */
@media screen and (min-width: 360px) and (max-width: 414px) {
	.leaderboard-container {
		width: 100%;
		height: auto;
		align-items: center;
	}
}

/* Additional media queries for other screen sizes if needed */
@media screen and (min-width: 414px) {
	.leaderboard-container {
		width: 100%;
		height: auto;
		display: flex;
		align-self: center;
		align-items: center;
	}
}

.leaderboard-screen-header {
	color: #fff;
	text-align: center;
	font-family: Poppins-Regular;
	font-size: 40px;
	font-style: normal;
	font-weight: 700;
	line-height: 48px; /* 120% */
}

.leaderboard-screen-title {
	color: var(--Black, #1b1625);
	font-family: Galaxie-Copernicus-Book;
	font-size: 24px;
	font-style: normal;
	font-weight: 500;
	line-height: 32px; /* 133.333% */
	text-align: center;
	align-self: start;
}
.leaderboard-screen-subtitle {
	color: #2a2a2a;
	font-family: Gordita-Regular;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	margin-top: 4px;
	text-align: start;
	align-self: start;
	line-height: 24px; /* 150% */
}

.leaderboard-current-rank-container {
	display: flex;
	justify-content: space-around;
	align-items: center;
	width: 312px;
	height: 54px;
	flex-shrink: 0;
	border-radius: 8px;
	background: #deddff;
	align-self: center;
	margin-top: 12px;
}

.leaderboard-current-rank-title {
	color: #2a2a2a;
	font-family: Poppins-Regular;
	text-align: center;
	align-self: center;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

.leaderboard-rank-container {
	width: 343px;
	height: 58px;
	flex-shrink: 0;
	border-radius: 8px;
	margin-top: 8px;
	padding: 0px 12px 0px 12px;
	/*border-bottom: 1px solid #BAB8F3;*/
}

.leaderboard-rank-container-one {
	margin-top: 16px;
	background: linear-gradient(266deg, #febb25 30.06%, #d49300 52.97%);
}
.leaderboard-rank-container-two {
	background: linear-gradient(266deg, #7f7aea 30.06%, #534de0 52.97%);
}
.leaderboard-rank-container-three {
	background: linear-gradient(266deg, #b478ff 30.06%, #9a48fe 52.97%);
}

.leaderboard-rank-container-self {
	background: linear-gradient(266deg, #08ca56 30.06%, #00903a 52.97%);
}

.karma-points-guide {
	color: #1a1a1a;
	font-family: Poppins-Regular;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

.karma-points-guide-detail {
	color: #2a2a2a;
	font-family: Poppins-Regular;
	font-size: 14px;
	text-align: center;
	font-style: normal;
	font-weight: 400;
	line-height: 24px; /* 171.429% */
	margin-left: 4px;
}

.infinite-scroll-component__outerdiv {
	width: 100% !important;
}
