.class-card-container {
    max-width: 400px; /* Limit max width */
    margin: 0 auto; /* Center the container */
    overflow: hidden; /* Hide overflowing content */
    border-radius: 16px;
    display: flex;
    width: 100%;
    height: auto;
    position: relative;
}

.class-card-bg-image{
    width: 100%;
    height: auto;
    min-height: 194px;
    max-height: 214px;
    background-size: cover;
    /*background: rgba(0, 0, 0, 0.5); !* Transparent overlay *!*/
    position: relative;
    /*background: rgba(0, 0, 0, 1); !* Transparent overlay *!*/
    z-index: 0;
}


/* Media query for screen height of 812px and width of 375px */
@media screen and (max-width: 414px){
    .class-card-container{
        width: 90%;
        height: auto;
    }
    .class-card-bg-image {
        width: 100%;
        height: 100%;
        min-height: 213px;
        align-items: center;
        position: relative;
    }
}

/* Additional media queries for other screen sizes if needed */
@media screen and (min-width: 414px) {
    .class-card-container{
        width: 400px;
        height: auto;
    }
    .class-card-bg-image {
        width: 400px;
        height: 237px;
       position: relative;
    }
}

.class-card-content {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    padding: 16px;
    color: white;
    z-index: 10; /* Ensure the content is above the overlay */
}

.class-card-title {
    color: #FFF;
    font-family: Gordita-Medium;
    width: 284px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
}

.class-card-subtitle {
    color: #FFF;
    font-family: Gordita-Medium;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 133.333% */
    margin-top: 2px;
}
