.bottom-sheet-container {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	top: 0;
	width: 100%;
	max-width: 420px;
	z-index: 10000000000000;
	pointer-events: none;
	transition: opacity 0.3s ease-in-out;
	opacity: 0;
}

.bottom-sheet-container.open {
	pointer-events: auto;
	opacity: 1;
}

.backdrop {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	top: 0;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 999;
}

.bottom-sheet {
	position: fixed;
	bottom: -100%;
	left: 0;
	right: 0;
	height: 54%;
	max-width: 420px;
	width: 100%;
	align-self: center;
	/*background-image: url("../../assets/top_backgroun.svg"); !* Add the path to your SVG or background image *!*/
	border-top-right-radius: 16px;
	border-top-left-radius: 16px;
	background-size: cover; /* Ensure the image covers the whole background */
	background-position: center; /* Center the background image */
	background-repeat: no-repeat;
	background-color: white;
	box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
	transition: bottom 0.3s ease-in-out;
	z-index: 1000;
	overflow: hidden !important;
}

.bottom-sheet-container.open .bottom-sheet {
	bottom: 0;
}

.bottom-sheet-content {
	padding: 16px;
	overflow: hidden;
}

.close-button {
	background: none;
	border: none;
	font-size: 16px;
	cursor: pointer;
}
