.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.bold-text {
  font-weight: bold;
  font-size: 32px;
}

.bold-subtitle {
  font-weight: normal;
  font-size: 24px;
  margin-top: 12px;
}

.button{
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  padding: 6px;
  width: 180px;
  border-radius: 6px;
  background-color: white;
  align-items: center;
  justify-content: center;

}

/*!* For larger screens, fix width at 400px *!*/
/*@media only screen and (min-width: 768px) {*/
/*  img {*/
/*    width: 400px;*/
/*  }*/
/*}*/

/*!* For mobile browsers, set width to 100% *!*/
/*@media only screen and (max-width: 767px) {*/
/*  img {*/
/*    width: 100%;*/
/*  }*/
/*}*/





