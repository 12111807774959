.live-class-clot-container{
    display: flex;
    padding: 4px 0px;
    flex-direction: row;
    flex-wrap: wrap;
    height: auto;
    align-items: center;
    justify-content: start;
    min-height: 58px;
    /*margin: 16px;*/
    /*margin-top: 24px;*/
    align-self: center;
    border-radius: 12px;
    border: 1px solid var(--Light-Grey-2, #E4E4E4);
    background: var(--White, #FFF);
}


/* Media query for screen height of 812px and width of 375px */
@media screen and (max-width: 414px){
    .live-class-clot-container{
        width: 90%;
        height: auto;
    }

}

/* Additional media queries for other screen sizes if needed */
@media screen and (min-width: 414px) {
    .live-class-clot-container{
        width: 400px;
        height: auto;
    }
}
