.course-class-description-container {
	display: flex;
	width: 100vw; /* Use viewport width */
	justify-content: center;
	flex-direction: column;
	align-items: center;
	align-self: center;
	overflow-x: hidden; /* Prevent horizontal overflow */
}

.course-description-bg {
	width: 360px;
	height: 360px;
	flex-shrink: 0;
	border-radius: 0;
	z-index: -10;
}

.course-description-title {
	color: #000;
	font-family: Lora-Regular;
	font-size: 24px;
	font-style: normal;
	font-weight: 500;
	line-height: 32px; /* 133.333% */
}

.course-description-review {
	color: #2a2a2a;
	font-family: Poppins-Regular;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: 26px; /* 144.444% */
}

.course-description-review-count {
	color: #534de0;
	font-family: Poppins-Regular;
	font-size: 10px;
	font-style: normal;
	font-weight: 600;
	line-height: 14px; /* 140% */
	padding: 4px 8px;
	align-items: flex-start;
	border-radius: 200px;
	background: #e4e4ff;
}

.course-description-subtitle {
	color: #757575;
	font-family: Poppins-Regular;
	text-align: start;
	font-size: 16px;
	margin-top: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 28px; /* 175% */
}

.course-body-description-title {
	color: #2a2a2a;
	font-family: Poppins-Regular;
	width: 160px;
	text-align: start;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	margin: 0px 8px 0px 12px;
}

.course-body-container {
	display: flex;
	width: 343px;
	flex-direction: row;
	justify-content: start;
	align-items: center;
}

.course-enrolled-toggle-container {
	margin-top: 24px;
	border-radius: 8px;
	border: 1px solid #d6d6d6;
	background: #fff;
	padding: 12px;
	justify-content: space-between;
	align-items: center;
}

.course-plan-description-review-profile {
	display: flex;
	width: 64px;
	height: 64px;
	padding: 8px;
}

.course-plan-description-review-name {
	color: #2a2a2a;
	font-family: Poppins-Regular;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 24px; /* 150% */
}

.course-plan-description-review-period {
	color: #534de0;
	font-family: Poppins-Regular;
	font-size: 10px;
	font-style: normal;
	font-weight: 600;
	line-height: 16px; /* 160% */
}

.course-plan-description-review-bio {
	color: #868686;
	font-family: Poppins-Regular;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px; /* 166.667% */
}

.course-plan-description-faq-title {
	color: #000;
	font-family: Lora-Regular;
	font-size: 24px;
	font-style: normal;
	text-align: center;
	font-weight: 500;
	line-height: 32px; /* 133.333% */
}

.course-plan-description-instructor-image-container {
	position: relative;
	display: inline-block;
}

.course-plan-description-instructor-image {
	width: 100%;
	height: 100%;
	background-size: cover;
	background-position: center;
}

.course-plan-description-instructor-details {
	position: absolute;
	top: 10px;
	left: 10px;
	display: flex;
	flex-direction: column;
	color: white;
}

.course-plan-description-instructor-experience {
	font-size: 14px;
}

.course-plan-description-instructor-name {
	font-size: 18px;
	margin-top: auto;
	text-align: right;
}

.course-number-of-enrollment {
	color: #a4a4a4;
	font-family: Poppins-Regular;
	font-size: 10px;
	font-style: italic;
	margin-top: 4px;
	font-weight: 500;
	line-height: 16px; /* 160% */
}

.course-number-of-enrollment span {
	font-style: italic;
	margin-top: 4px;
	color: #2a2a2a;
	font-family: Poppins-Regular;
	font-size: 12px;
	font-weight: 400;
	margin-right: 4px;
	line-height: normal;
}

.upcoming-course-class-subheader-container {
	display: flex;
	flex-direction: row;
	width: 348px;
	height: 64px;
	margin-right: 16px;
	border-radius: 12px;
	border: 1px solid #f5eeff;
	background: #fff;
	padding: 12px;
	margin-top: 16px;
	justify-content: center;
	align-items: center;
	gap: 6px;
}

.upcoming-course-class-subheader-subContainer {
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 100%;
	justify-content: center;
	align-items: center;
}

.upcoming-course-class-subheader-title {
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 100%;
	justify-content: center;
	align-items: center;
	color: #2a2a2a;
	font-family: Poppins-Regular;
	align-self: start;
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	margin-top: 2px;
	line-height: 18px; /* 150% */
}

.upcoming-course-class-subheader-subtitle {
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 100%;
	justify-content: start;
	align-self: start;
	align-items: start;
	color: #2a2a2a;
	font-family: Poppins-Regular;
	align-self: start;
	font-size: 10px;
	font-style: normal;
	font-weight: 400;
	text-align: start;
	line-height: 16px; /* 160% */
}

.module-card-collapsed {
	display: flex;
	width: 345px;
	padding: 12px;
	margin-top: 12px;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
	gap: 8px;
	border-radius: 12px;
	background: #fff;
	box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.08);
}

.module-card-expanded {
	display: flex;
	width: 345px;
	padding: 12px;
	margin-top: 12px;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
	gap: 8px;
	border-radius: 12px;
	border: 1px solid #8b2eff;
	background: #f7f4ff;
}
.upcoming-course-highlights {
	color: #2a2a2a;
	font-family: Lora-Regular;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	margin-top: 8px;
	line-height: 24px; /* 142.857% */
}

.highlights-point {
	color: #2a2a2a;
	width: 312px;
	font-family: Poppins-Regular;
	font-size: 12px;
	padding-right: 12px;
	margin-left: 4px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px; /* 166.667% */
}
.upcoming-course-description {
	color: #2a2a2a;
	font-family: Poppins-Regular;
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

.upcoming-course-day-by-day-schedule {
	color: #2a2a2a;
	font-family: Lora-Regular;
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	margin-top: 32px;
	line-height: 28px; /* 140% */
}
.upcoming-course-about-kundalini {
	color: #2a2a2a;
	font-family: Lora-Regular;
	font-size: 20px;
	font-style: normal;
	text-align: start;
	font-weight: 400;
	line-height: 28px; /* 140% */
	margin-top: 32px;
}
.upcoming-course-details-kundalini {
	color: #2a2a2a;
	font-family: Lora-Regular;
	font-size: 14px;
	font-style: normal;
	text-align: start;
	font-weight: 400;
	margin-top: 10px;
	line-height: 24px; /* 171.429% */
}
.upcoming-course-target-title {
	color: #2a2a2a;
	font-family: Poppins-Medium;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	margin-top: 12px;
	line-height: 28px; /* 175% */
}
.upcoming-course-target-subtitle {
	color: #2a2a2a;
	font-family: Poppins-Regular;
	font-size: 14px;
	width: 286px;
	font-style: normal;
	font-weight: 400;
	margin-top: -12px;
	line-height: 24px; /* 171.429% */
}
.upcoming-course-enroll-now {
	color: #1a1a1a;
	text-align: center;
	font-family: Poppins-Light;
	font-size: 14px;
	font-style: normal;
	align-self: center;
	width: 100%;
	margin-top: 10px;
	font-weight: 300;
	line-height: 24px; /* 171.429% */
}

.upcoming-course-enroll-now span {
	color: #534de0;
	font-family: Poppins-SemiBold;
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: 24px;
	text-decoration-line: underline;
}

.course-image-banner {
	width: 100%;
	height: auto;
	/*max-height: 212px;*/
	object-fit: cover;
}

.course-video-player {
	height: 332px;
	object-fit: cover;
}

/* Styles for mobile devices with a width smaller than 420px */
@media only screen and (max-width: 419px) {
	.course-video-player {
		width: 100%;
	}
}

/* Styles for browsers with a width of 420px */
@media only screen and (min-width: 420px) {
	.course-video-player {
		width: 420px;
	}
}

@media only screen and (max-width: 400px) {
	.course-image-banner {
		width: 100%;
		height: auto;
		object-fit: cover;
	}
}

@media only screen and (min-width: 601px) and (max-width: 2000px) {
	.course-image-banner {
		/* Adjust styles for screens with a width between 601px and 900px */
		height: 332px; /* For example, set a different height */
		width: 400px;
		object-fit: cover;
	}
}
