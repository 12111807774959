.tile-swiper {
	width: 100%;
}

.tile-swiper .swiper-wrapper {
	transition-timing-function: linear !important;
}

.tile-gallery {
	display: flex;
	gap: 12px;
}

.tile-gallery-column {
	display: flex;
	flex-direction: column;
	gap: 12px;
}

.tile-gallery-column:nth-child(2n) {
	margin-top: 38px;
}

.alternate .tile-gallery-column:nth-child(2n + 1) {
	margin-top: 38px;
}

.alternate .tile-gallery-column:nth-child(2n) {
	margin-top: 0;
}

.tile-gallery-column-image {
	width: 170px;
}
