.know-your-instructor-container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin: 0 0 24px;
	width: 344px;
}
/*.instructor-card-image {*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    align-items: flex-start;*/
/*    width: 100%;*/
/*    margin-top: 12px;*/
/*    height: auto;*/
/*}*/

.instructor-card-image-modify {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;
	margin-top: 12px;
	position: relative; /* Make the container position relative */
	padding-bottom: 75%; /* Aspect ratio 4:3 (3/4 = 0.75) */
	height: 0; /* Set height to 0 to maintain the aspect ratio */
}

.instructor-card-image-modify img {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover; /* Ensure the image covers the container while maintaining aspect ratio */
}

.instructor-name-heading {
	color: var(--Body-Font, #4a4a4a);
	font-family: Gordita-Medium;
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: 28px; /* 140% */
}

/* Media query for screen height of 812px and width of 375px */
/* @media screen and (max-width: 414px) {
	.know-your-instructor-container {
		width: 100%;
		height: auto;
	}
	.instructor-card-image {
		width: 100%;
		max-height: 212px;
		align-items: center;
	}
} */

/* Additional media queries for other screen sizes if needed */
/* @media screen and (min-width: 414px) {
	.know-your-instructor-container {
		width: 400px;
		height: auto;
	}
	.instructor-card-image {
		width: 400px;
		height: 238px;
	}
} */
