/* styles.css */

.landing-page-container {
	display: flex;
	flex-direction: column;
	align-items: center; /* Center horizontally */
	justify-content: center; /* Center vertically */
	width: 375px;
	margin: auto;
	overflow: hidden;
}

.landing-header-container {
	width: 375px;
	display: flex;
	align-items: center;
	flex-direction: column;
}
.landing-body-container {
	width: 375px;
	display: flex;
	justify-content: center;
	align-items: center;
	align-self: center;
	padding-bottom: 120px;
	flex-direction: column;
}

.landing-title {
	color: var(--Black-Shade, #1b1625);
	text-align: center;
	/* Heading 1/Regular */
	font-family: Galaxie-Copernicus-Book;
	font-size: 24px;
	font-style: normal;
	font-weight: 500;
	line-height: 32px; /* 133.333% */
}

.landing-subtitle {
	color: #1a1a1a;
	text-align: center;
	font-family: Poppins-Regular;
	font-size: 14px;
	font-style: normal;
	font-weight: 300;
	margin-top: 12px;
	line-height: 24px; /* 171.429% */
}

.icon-with-title {
	display: flex;
	align-items: center;
	align-self: start;
	height: 56px;
	width: 100%;
	padding: 12px 16px;
	margin-top: 16px;
	gap: 8px;
	flex: 1 0 0;
	background: white;
}
.selected-icon-with-title {
	display: flex;
	align-items: center;
	align-self: start;
	height: 56px;
	width: 100%;
	padding: 12px 16px;
	margin-top: 16px;
	gap: 8px;
	flex: 1 0 0;
	border: 1px solid #8b2eff;
	background: #e7d3ff;
}

.icon-container {
	width: 30px;
	height: 30px;
	background-color: #3498db; /* Example background color for the icon */
	margin-right: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fff; /* Example color for the icon */
	border-radius: 50%;
}

.landing-row-title {
	color: #1a1a1a;
	font-family: Gordita-Medium;
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: 22px; /* 157.143% */
	text-transform: capitalize;
}

.landing-row-subtitle-one {
	color: #1a1a1a;
	font-family: Gordita-Medium;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 22px; /* 157.143% */
	text-transform: capitalize;
}

.landing-row-subtitle-two {
	color: #1a1a1a;
	font-family: Gordita-Medium;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 22px; /* 157.143% */
	text-transform: capitalize;
}

.ellipse-container {
	position: absolute;
	width: 300px; /* Adjust width as needed */
	height: 200px; /* Adjust height as needed */
	margin: 50px auto;
}

.text-animate {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	font-size: 24px; /* Adjust font size as needed */
}

.animate {
	animation: ellipse 2s infinite;
}

@keyframes ellipse {
	0% {
		transform: translate(-50%, -50%) rotate(0deg) translateX(150px) rotate(0deg);
	}
	100% {
		transform: translate(-50%, -50%) rotate(360deg) translateX(150px)
			rotate(-360deg);
	}
}

.input-placeholder {
	color: #d6d6d6;
	font-family: Gordita-Regular;
	font-size: 24px;
	opacity: 0.4;
	font-style: normal;
	border-bottom: 2px solid #d6d6d6;
	font-weight: 400;
	line-height: 32px; /* 133.333% */
	cursor: text; /* Add cursor */
}

.input-value {
	/* Style for the entered text */
	color: #1b1625;
	font-family: Gordita-Medium;
	font-size: 24px;
	opacity: 1;
	font-style: normal;
	border-bottom: 2px solid #4462ef;
	font-weight: 500;
	line-height: 32px; /* 133.333% */
	cursor: text; /* Add cursor */
}

.input-focus {
	opacity: 1;
	outline: none;
	cursor: pointer; /* Add cursor */
	border-bottom-color: #4462ef; /* Change border color on focus */
}
