.fixed-bottom-container {
    position: fixed;
    bottom: 0;
    /*left: 0;*/
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    align-items: center;
    border-radius: 12px 12px 0px 0px;
    background: #FFF;
    padding: 8px 16px 0px 16px;
    z-index: 1000;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.fixed-bottom-button {
    margin-bottom: 10px; /* Add some space below the button */
    padding: 10px 20px;
    /*border-radius: 12px;*/
    /*background: var(--Primary-Color, #4462EF);*/
    border-radius: 8px;
    background: var(--Primary-Gradient, linear-gradient(106deg, #4462EF 32.65%, #1B3DD8 105.18%));
    border: none;
    width: 100%;
    text-align: center;
    cursor: pointer;
    color: #FFF;
    font-family: Gordita-Medium;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
}

.fixed-bottom-button:hover {
    background-color: #0056b3;
}

.fixed-bottom-text {
    margin: 0;
    padding-bottom: 10px; /* Add some space below the text */
    text-align: center;
    color: #4A4A4A;
    font-family: Gordita-Regular;
    font-size: 12px;
    font-style: italic;
    font-weight: 400;
    line-height: 20px; /* 166.667% */
}
