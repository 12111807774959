.leaderNudge-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 12px;
	align-self: center;
	border-radius: 12px;
	border: 1px solid var(--Light-Grey-2, #e4e4e4);
	border-top: none; /* Removes the border color from the top */
	background: var(--White, #fff);
}

.leaderNudge-header-container {
	display: flex;
	padding: 8px 12px;
	height: 36px;
	width: 100%;
	align-items: center;
	gap: 4px;
	z-index: 5;
	align-self: stretch;
	flex: 5;
	border-radius: 12px 12px 0px 0px;
	border: 1px solid var(--Secondary-Dark-Background, #ffd39e);
	background: var(--Secondary-Color-LIght-Backgound, #fff3e4);
}

.leaderNudge-header-text {
	color: var(--Body-Font, #4a4a4a);
	font-family: Gordita-Medium;
	font-size: 12px;
	font-style: normal;
	display: flex;
	flex: 1;
	font-weight: 500;
	line-height: 16px; /* 133.333% */
}

/* Media query for screen height of 812px and width of 375px */
@media screen and (max-width: 414px) {
	.leaderNudge-container {
		width: 100%;
		height: auto;
	}
	/*.leaderNudge-header-container{*/
	/*    width: 100%;*/
	/*    height: auto;*/
	/*}*/
}

/* Additional media queries for other screen sizes if needed */
@media screen and (min-width: 414px) {
	.leaderNudge-container {
		width: 100%;
		height: auto;
	}
	/*.leaderNudge-header-container{*/
	/*    width: 400px;*/
	/*    height: auto;*/
	/*}*/
}

.nudge-title {
	color: #1a1a1a;
	font-family: Poppins-Regular;
	font-size: 16px;
	text-align: start;
	align-self: start;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}
