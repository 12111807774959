.tile-gallery-video {
	width: 170px;
	position: relative;
	margin-bottom: -5px;
}

.tile-gallery-video .text {
	width: 100%;
	height: 95%;
	top: 0;
	left: 0;
	border-radius: 12px;
	position: absolute;
	background-image: linear-gradient(to bottom, transparent 75%, #00000077);
}

.tile-gallery-video .text p {
	position: absolute;
	color: #fff;
	font-family: Gordita-Medium;
	font-size: 12px;
	font-weight: 500;
	letter-spacing: -0.24px;
	bottom: -10px;
	left: 10px;
	transform: translate3d(0, 0, 0);
}

.tile-gallery-video video {
	width: 100%;
	border-radius: 12px;
}
