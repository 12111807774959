.upcoming-session-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;

}

.upcoming-session-body-container {
    display: flex;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.image-banner {
    width: 90%;
    height: auto;
    /*max-height: 212px;*/
    object-fit: cover;

}

/* Media query for screen height of 812px and width of 375px */
@media screen and (max-width: 360px){
    .upcoming-session-container{
        width: 100%;
        height: auto;
        align-items: center;
    }
    .upcoming-session-body-container {
        width: 90%;
        height: auto;
        margin-left: 16px;
        margin-right: 16px;
        align-items: center;

    }
    .image-banner{
        width: 90%;
        min-height: 213px;
        height: auto;
        align-items: center;
        object-fit: cover;
    }
}

/* Media query for screen height of 812px and width of 375px */
@media screen and (min-width: 360px) and (max-width: 414px){
    .upcoming-session-container{
        width: 100%;
        height: auto;
        align-items: center;
    }
    .upcoming-session-body-container {
        width: 90%;
        height: auto;
        align-items: center;

    }
    .image-banner{
        width: 90%;
        min-height: 213px;
        height: auto;
        align-items: center;
        object-fit: cover;
    }
}

/* Additional media queries for other screen sizes if needed */
@media screen and (min-width: 414px) {
    .upcoming-session-container{
        width: auto;
        height: auto;
        display: flex;
        align-self: center;
        align-items: center;
    }
    .image-banner{
        width: 375px;
        height: 238px;
        align-items: center;
    }
    .upcoming-session-body-container {
        width: 375px;
        height: auto;
        display: flex;
        align-self: center;
        align-items: center;

    }
}



.upcoming-session-category-title {
    color: #2A2A2A;
    text-align: center;
    font-family: Gordita-Medium;
    font-size: 16px;
    margin-top: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 175% */
}

.upcoming-session-highlights-title{
    color: #1B1625;
    font-family: Gordita-Medium;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
}

.upcoming-session-highlights-subtitle{
    color: #4A4A4A;
    font-family: Gordita-Regular;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    align-self: start;
    line-height: 20px; /* 142.857% */
}



.upcoming-session-subheader-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 64px;
    margin-left: 16px;
    border-radius: 12px;
    border: 1px solid #F5EEFF;
    background: #FFF;
    padding: 12px;
    margin-top: 16px;
    justify-content: center;
    align-items: center;
    gap: 6px;
}


.upcoming-session-subheader-subContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.upcoming-session-subheader-title {
    color: #be11be;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    font-family: Gordita-Medium;
    align-self: start;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    margin-top: 6px;
    line-height: 18px; /* 150% */
}

.upcoming-join-liv-session-cta{
    display: flex;
    height: 40px;
    width: 90%;
    max-width: 375px;
    padding: 12px;
    justify-content: center;
    align-items: center;
    align-self: center;
    gap: 6px;
    border-radius: 8px;
    background: var(--Primary-Gradient, linear-gradient(106deg, #4462EF 32.65%, #1B3DD8 105.18%));
}
.upcoming-join-liv-session-cta.disable{
    cursor: none;
    border-radius: 8px;
    background: var(--Light-Grey-2, #E4E4E4);
    color: var(--Body-Font, #4A4A4A);
    text-align: center;
    font-feature-settings: 'dlig' on;
    font-family: Gordita-Medium;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 133.333% */
}

.upcoming-session-subheader-subtitle {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    justify-content: start;
    align-self: start;
    align-items: start;
    color: #4A4A4A;
    font-family: Gordita-Regular;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    text-align: start;
    line-height: 16px; /* 160% */
}

.upcoming-session-category-description {
    color: #2A2A2A;
    text-align: center;
    font-family: Poppins-Regular;
    margin-top: -8px;
    font-size: 14px;
    padding-bottom: 0px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
}

.upcoming-session-subheader-container {
    display: flex;
    flex-direction: row;
    flex: 3;
    width: 101%;
    /*height: 64px;*/
    margin-right: 16px;
    border-radius: 12px;
    border: 1px solid #F5EEFF;
    background: #FFF;
    padding: 12px;
    margin-top: 16px;
    justify-content: center;
    align-items: center;
    /*align-self: center;*/
    gap: 6px;
}

.upcoming-session-subheader-subContainer {
    display: flex;
    flex-direction: row;
    width: auto;
    height: 100%;
    flex: 1;
    justify-content: center;
    align-items: center;
}

.upcoming-session-subheader-title {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    color: #2A2A2A;
    font-family: Poppins-Regular;
    align-self: start;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    margin-top: 6px;
    line-height: 18px; /* 150% */
}

.upcoming-session-subheader-subtitle {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    justify-content: start;
    align-self: start;
    align-items: start;
    color: #2A2A2A;
    font-family: Poppins-Regular;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    text-align: start;
    line-height: 16px; /* 160% */
}


