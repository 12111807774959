/* styles.css */

.welcome-page-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    margin-top: 24px;
    width: 95%;
    max-width: 400px;
}

.welcome-header-container {
    display: flex;
    width: 95%;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
}


/* Media query for screen height of 812px and width of 375px */
@media screen and (max-width: 414px){
    .welcome-page-container{
        width: 100%;
        height: auto;
        align-items: center;
        max-width: 400px;
    }
    .welcome-header-container{
        width: 95%;
        align-items: center;
        flex-direction: row;
        max-width: 400px;

    }
}

/* Additional media queries for other screen sizes if needed */
@media screen and (min-width: 414px) {
    .welcome-page-container{
        width: 400px;
        height: auto;
        display: flex;
        align-self: center;
        align-items: center;
    }
    .welcome-header-container{
        width: 375px;
        display: flex;
        align-self: center;
        flex-direction: row;
        align-items: center;

    }
}

.welcome-title {
    color: #1A1A1A;
    text-align: center;
    font-family: Galaxie-Copernicus-Book;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 48px; /* 150% */
}

.welcome-subtitle {
    width: 375px;
    color: #1A1A1A;
    text-align: center;
    font-family: Poppins-Regular;
    font-size: 14px;
    font-style: normal;
    margin-top: 28px;
    font-weight: 300;
    line-height: 24px; /* 171.429% */
}

.welcome-subtitle span {
    color: #231DD7;
    font-family: Poppins-Regular;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

.icon-container {
    width: 30px;
    height: 30px;
    background-color: #3498db; /* Example background color for the icon */
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff; /* Example color for the icon */
    border-radius: 50%;
}

.title {
    color: #1A1A1A;
    font-family: Poppins-Regular;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
    text-transform: capitalize;
}

.welcome-row-title {
    color: #4A4A4A;
    font-family: Gordita-Medium;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal; /* 157.143% */
}

.welcome-row-subtitle-one {
    color: #1B1625;
    font-family: Gordita-Regular;
    margin-top: 4px;
    font-size: 14px;
    width: 100%;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 157.143% */
}

.welcome-row-subtitle-two {
    width: 100%;
    color: #1B1625;
    font-family: Gordita-Medium;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 157.143% */
}

.welcome-body-container {
    width: 95%;
    display: flex;
    max-width: 400px;
    justify-content: center;
    align-items: center;
    align-self: center;
    margin-top: 92px;
    margin-left: 24px;
    flex-direction: column;
}

.welcome-icon-with-title{
    width: 100%;
    display: flex;
    align-items: center;
    align-self: start;
    height: 56px;
    padding: 12px 8px;
    margin-top: -12px;
    flex: 1 0 0;
    background: white;
}