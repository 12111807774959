.poc-form-bold-subtitle {
    color: #2A2A2A;
    text-align: center;
    font-family: Lora-Regular;
    font-size: 28px;
    text-align: center;
    align-self: center;
    font-style: normal;
    font-weight: 400;
    margin-top: 2px;
    line-height: 38px; /* 135.714% */
}

.poc-form-bold-subtitle-text {
    color: #2A2A2A;
    text-align: center;
    font-family: Poppins-Regular;
    font-size: 14px;
    font-style: normal;
    align-self: center;
    margin-top: 24px;
    font-weight: 400;
    line-height: 18px; /* 171.429% */
}

.poc-form-login-button {
    margin-top: 18px;
    display: flex;
    flex-direction: row;
    padding: 6px;
    width: 180px;
    border-radius: 6px;
    background-color: white;
    align-items: center;
    justify-content: center;
}

.poc-form-container {
    display: flex;
    max-width: 400px;
    flex-direction: column;
    justify-content: start;
    align-items: flex-start;
    padding: 16px;
    margin: 38px auto 0 auto;
}

.header-container {
    display: flex;
    flex-direction: column;
    margin-right: 220px;
}

.poc-heading-title {
    color: #1B1625;
    font-family:Galaxie-Copernicus-Book;
    font-size: 24px;
    text-align: center;
    font-style: normal;
    font-weight: 500;
    line-height: 32px; /* 125% */
}



.login-form-subtitle {
    width: 320px;
    flex-shrink: 0;
    margin-top: 8px;
    text-align: center;
    color: #4A4A4A;
    font-family: Gordita-Regular;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
}

.poc-form-title {
    color: #4A4A4A;
    font-family: Gordita-Regular;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
}

.poc-buttons-container{
    position: fixed;
    bottom: 0;
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-self: center;
    align-items: center;
    padding: 12px 16px 0px 16px;
    z-index: 1000;
    border-radius: 12px 12px 0px 0px;
    background: #FFF;
    box-shadow: 0px -4px 12px 0px rgba(0, 0, 0, 0.1);
}

.poc-fixed-bottom-button {
    margin-bottom: 10px; /* Add some space below the button */
    padding: 10px 20px;
    border-radius: 12px;
    background: var(--Primary-Color, #4462EF);
    border: none;
    /*width: 40%;*/
    text-align: center;
    cursor: pointer;
    color: #FFF;
    font-family: Gordita-Medium;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
}
.poc-chat-later{
    margin-bottom: 10px; /* Add some space below the button */
    padding: 10px 20px;
    border-radius: 8px;
    display: flex;
    height: 40px;
    width: 50%;
    margin-right: 6px;
    justify-content: center;
    align-items: center;
    border: 1px solid #1B1625;
    background: #FFF;
    color: #1B1625;
    text-align: center;
    font-family: Gordita-Medium;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 133.333% */
}
.poc-chat-now{
    margin-bottom: 10px; /* Add some space below the button */
    padding: 10px 20px;
    border-radius: 8px;
    display: flex;
    height: 40px;
    width: 50%;
    justify-content: center;
    align-items: center;
    margin-left: 6px;
    background: var(--Primary-Gradient, linear-gradient(106deg, #4462EF 32.65%, #1B3DD8 105.18%));
    color: white;
    text-align: center;
    font-feature-settings: 'dlig' on;
    font-family: Gordita-Medium;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 133.333% */
}


.login-success-text {
    color: #1B1625;
    width: 100%;
    text-align: center;
    font-family: Gordita-Medium;
    font-size: 20px;
    margin-top: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
}


.login-success-subtitle {
    color: #4A4A4A;
    text-align: center;
    font-family: Gordita-Regular;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}


.login-failure-text {
    color: #1B1625;
    width: 100%;
    text-align: center;
    font-family: Gordita-Medium;
    font-size: 20px;
    margin-top: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
}

.login-failure-subtitle {
    color: #4A4A4A;
    text-align: center;
    font-family: Gordita-Regular;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

/* Responsive Media Queries */

/* Larger screens */
@media only screen and (max-width: 1200px) {
    .header-container {
        margin-right: 120px; /* Adjust as needed */
    }
}

/* Medium screens */
@media only screen and (max-width: 992px) {
    .login-container {
        /* ... */
    }

    .header-container {
        margin-right: 20px; /* Adjust as needed */
    }

    .poc-form-container {
        width: 90%; /* Adjust as needed */
        max-width: 400px;
    }
}

/* Small screens */
@media only screen and (max-width: 768px) {
    .login-container {
        /* ... */
    }

    .header-container {
        margin-right: 10px; /* Adjust as needed */
    }

    .poc-form-container {
        width: 100%; /* Full width */
        margin: 20px auto 0 auto;
        max-width: 400px;
    }
}

/* Extra small screens */
@media only screen and (max-width: 576px) {
    .login-container {
        /* ... */
    }

    .header-container {
        margin-right: 0; /* No margin */
    }

    .poc-form-container {
        width: 100%; /* Full width */
        margin: 20px auto 0 auto;
        max-width: 400px;
    }
}


.selected-slot{
    display: flex;
    height: 48px;
    padding: 8px 12px;
    align-items: center;
    flex: 1;
    border-radius: 12px;
    border: 1px solid var(--Primary-Color, #4462EF);
    background: var(--Primary-Background, #E6EAFF);
    color: var(--Black-Shade, #1B1625);
    text-align: center;
    align-self: center;
    /* Body 1/Medium */
    font-family: Gordita-Medium;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
}
.un-selected-slot{
    display: flex;
    height: 48px;
    flex: 1;
    padding: 8px 12px;
    align-items: center;
    border-radius: 12px;
    border: 1px solid #D6D6D6;
    background: var(--White, #FFF);
    color: var(--Black-Shade, #1B1625);
    text-align: center;
    align-self: center;
    align-items: center;
    /* Body 1/Regular */
    font-family: Gordita-Regular;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
}

.selected-slot-text{
    text-align: center;
    align-self: center;
    align-items: center;
    flex: 1;
    width: 100%;
}
