.poc-page-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    margin-top: 32px;
    width: 100%;
}


.poc-landing-container {
    justify-content: center;
    align-items: center;
    text-align: center;
    display: flex;
    flex-direction: column;
}
.poc-landing-subtitle{
    color: #4A4A4A;
    text-align: center;
    font-family: Gordita-Medium;
    font-size: 14px;
    font-style: normal;
    margin-top: 6px;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
}

.poc-landing-greetings-container{
    justify-content: center;
    align-items: center;
    text-align: center;
    display: flex;
    flex-direction: column;
    background: #F4F4F4;
    padding: 32px 16px;
    margin-top: 16px;
    /*gap: 24px;*/
    align-self: stretch;
}

.poc-landing-header{
    display: flex;
    width: 400px;
    margin-top: 2px;
    padding: 40px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    position: relative;
    background: #F4F4F4;
}
.poc-landing-header-image{
    width: 266px;
    height: 56px;
}
.poc-landing-header-title{
    text-align: center;
    font-style: normal;
    color: #1B1625;
    font-family: Galaxie-Copernicus-Book;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px; /* 140% */
}


.poc-landing-header-subtitle{
    width: 70%;
    color: #040057;
    text-align: center;
    font-family: Poppins-Bold;
    font-size: 32px;
    margin-top: -12px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px; /* 125% */
}

.poc-landing-header-description-one{
    color: #3A3A3A;
    text-align: center;
    font-family: Gordita-Medium;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    margin-top: -12px;
    line-height: 24px; /* 150% */
}


.poc-landing-header-description-two{
    color: #3A3A3A;
    text-align: center;
    font-family: Lora-Bold;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    margin-left: 16px;
    margin-right: 16px;
    align-self: stretch;
    line-height: 28px; /* 155.556% */
}

.poc-landing-header-description-three{
    color: #3A3A3A;
    text-align: center;
    font-family: Poppins-Medium;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    flex: 1 0 0;
    margin-left: 16px;
    margin-right: 16px;
    margin-top: 32px;
    line-height: 32px; /* 160% */
}

.poc-landing-testimonials-title{
    color: #000;
    text-align: center;
    font-family:Galaxie-Copernicus-Book;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 125% */
    letter-spacing: 0.2px;
}

.poc-landing-testimonials-why-us{
    color: #3A3A3A;
    text-align: center;
    align-self: stretch;
    font-family: Poppins-Bold;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* 155.556% */
}

.poc-landing-join-community-member{
    color: #3A3A3A;
    font-family: Poppins-Regular;
    font-size: 12px;
    margin-top: 12px;
    font-style: italic;
    font-weight: 400;
    line-height: 20px; /* 166.667% */
}
.poc-landing-greetings-title{
    text-align: center;
    font-style: normal;
    letter-spacing: 0.32px;
    color: #1B1625;
    font-family: Galaxie-Copernicus-Book;
    font-size: 20px;
    font-weight: 500;
    z-index: 5;
    line-height: 28px; /* 140% */
}
.poc-landing-greetings-subtitle{
    font-style: normal;
    letter-spacing: 0.32px;
    font-weight: 500;
    z-index: 5;
    color: #EF3A3A;
    text-align: center;
    font-family: Gordita-Medium;
    font-size: 28px;
    line-height: 36px; /* 128.571% */
}

.poc-landing-greetings-description{
    text-align: center;
    font-style: normal;
    letter-spacing: 0.32px;
    width: 324px;
    z-index: 5;
    color: #4A4A4A;
    font-family: Gordita-Regular;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px; /* 150% */
}

.poc-landing-greetings-description span{
    text-align: center;
    font-style: normal;
    letter-spacing: 0.32px;
    z-index: 5;
    color: #4A4A4A;
    font-family: Gordita-Medium;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px; /* 150% */
}

.poc-landing-join-community-learn{
    color: #000;
    text-align: center;
    width: 240px;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    z-index: 5;
    line-height: 28px; /* 125% */
    letter-spacing: 0.32px;
}

.poc-landing-faq-title{
    color: #000;
    text-align: center;
    font-family: Galaxie-Copernicus-Book;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 120% */
    letter-spacing: 0.4px;
}

.poc-vertical-info-card {
    display: flex;
    height: 100%;
    width: 90%;
    max-width: 400px;
    justify-content: center;
    flex-direction: column;
    align-items: start;
    border-radius: 12px;
    margin-top: 16px;
    padding-top: 16px;
    padding-left: 12px;
    background: #FFF6EC;
}
.poc-card-image {
    height: 224px;
    width: auto;
    align-self: center;
    max-width: 400px;
    margin-top: 12px;
    padding-bottom: 12px;
    z-index: 10;
    object-fit: cover;
    /*background: transparent;*/
}



.poc-landing-card-title {
    color: #1B1625;
    width: 75%;
    text-align: start;
    font-family: Gordita-Medium;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.2px;
}

.poc-landing-card-subtitle {
    text-align: start;
    width: 90%;
    margin-top:4px;
    /*text-transform: capitalize;*/
    color: var(--Body_Font, #4A4A4A);
    /* Body 2/Regular */
    font-family: Gordita-Regular;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
}


.testimonial-profile-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 12px;
    width: 95%;
    max-width: 400px;
    height: auto;
    background: white;
    border-radius: 12px;
    border: 1px solid #E4E4E4;
    overflow: hidden;
}


/* Media query for screen height of 812px and width of 375px */
@media screen and (max-width: 414px){
    .testimonial-profile-card{
        width: 95%;
        height: auto;
        border-radius: 12px;
        border: 1px solid #E4E4E4;
        background: #FFF;
    }
}

/* Additional media queries for other screen sizes if needed */
@media screen and (min-width: 414px) {
    .testimonial-profile-card{
        width: 400px;
        height: auto;
        border-radius: 12px;
        border: 1px solid #E4E4E4;
        background: #FFF;
    }
}

.testimonial-profile-top-half {
    display: flex;
    padding: 24px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
    height: 50%;
    border-radius: 12px 12px 0px 0px;
    border: 1px solid #E4E4E4;
    background: #FFF;
}
.testimonial-profile-comment-content{
    color: #1B1625;
    font-family: Gordita-Regular;
    text-align: start;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    z-index: 5;
    margin-top: 10px;
    /*line-height: 0px; !* 142.857% *!*/
}

.testimonial-profile-bottom-half {
    display: flex;
    padding: 12px;
    flex: 2;
    padding-top: 12px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: 0px 0px 12px 12px;
    width: 100%;
    background: #2B4BE1;
}

.testimonial-profile-icon {
    font-size: 2rem;
}

.testimonial-profile-name {
    color: #FFFFFF;
    font-family: Gordita-Medium;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    text-align: start;
    align-self: start;
    line-height: 20px; /* 142.857% */
}
.testimonial-profile-date {
    color: #E2E2E2;
    font-family: Gordita-Regular;
    font-size: 10px;
    font-style: normal;
    text-align: start;
    align-self: start;
    font-weight: 400;
    margin-top: 4px;
    line-height: normal;
}

.testimonial-profile-rating {
    display: flex;
    flex-direction: row;

}


