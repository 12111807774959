.faq-container{
    display: flex;
    flex-direction: column;
    width: 95%;
    max-width: 375px;
    justify-content: center;
    align-items: start;

}

.faq-container-selected{
    display: flex;
    flex-direction: column;
    width: 95%;
    max-width: 375px;
    justify-content: center;
    align-items: start;
}
.question{
    color: #4A4A4A;
    font-family: Gordita-Regular;
    width: 85%;
    font-size: 16px;
    font-style: normal;
    text-align: start;
    font-weight: 400;
    line-height: 24px; /* 125% */
}

.answer{
    width: 100%;
    color: #1A1A1A;
    font-family:Gordita-Regular;
    font-size: 14px;
    font-style: normal;
    text-align: start;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
}

.enrolled-toggle-container{
    margin-top: 24px;
    border-radius: 8px;
    border: 1px solid #D6D6D6;
    background: #FFF;
    padding: 12px;
    justify-content: space-between;
    align-items: center;
}
