.text-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 375px;
    /*position: relative;*/
}

.text {
    animation: readAnimation linear infinite;
    text-align: center;
    width: 100%;
    max-width: 343px;

}

@keyframes readAnimation {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-100%);
    }
}

.animation-unread {
    opacity: 0.4; /* Adjust opacity for unread characters */
}

.animation-read {
    opacity: 0.8;
    /*font-weight: bold; !* Add additional styling for read characters *!*/
}
