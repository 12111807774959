.settings-list-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    border-radius: 12px;
    border: 1px solid #E4E4E4;
    background: #FFF;
    margin-top: 32px;
    padding: 4px 14px;
    gap: 4px;
}


/*!* Media query for screen height of 812px and width of 375px *!*/
/*@media screen and (max-width: 360px){*/
/*    .settings-list-container{*/
/*        width: 100%;*/
/*        height: auto;*/
/*        align-items: center;*/
/*    }*/
/*}*/

/* Media query for screen height of 812px and width of 375px */
@media screen and (max-width: 414px){
    .settings-list-container{
        width: 90%;
        height: auto;
        align-items: center;
    }
}

/* Additional media queries for other screen sizes if needed */
@media screen and (min-width: 414px) {
    .settings-list-container{
        width: 400px;
        height: auto;
        display: flex;
        align-self: center;
        align-items: center;
        justify-content: space-between;
    }
}
