.how-to-join-container{
    display: flex;
    padding: 16px;
    margin: 24px auto 0 auto;
    width: 375px;
    flex-direction: column;
    justify-content: center;
}

.join-class-screen-title{
    color: #000;
    font-family: Lora-Regular;
    align-self: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px; /* 166.667% */
    letter-spacing: 0.48px;
}

.choose-slot-title{
    color: #060505;
    width: 343px;
    text-align: center;
    font-family: Lora-Regular;
    font-size: 20px;
    font-style: normal;
    line-height: 28px; /* 140% */
}

.choose-slot-subtitle{
    color: #2A2A2A;
    font-family: Poppins-Regular;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    width: 343px;
    text-align: center;
    align-self: center;
    /*line-height: 14px; !* 171.429% *!*/
}
