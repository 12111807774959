.poc-image-slider {
    position: relative;
    width: 100%;
    max-width: 420px;
    height: 84px;
    overflow: hidden;
    display: flex;
    justify-content: center;
}

.poc-slide-container {
    display: flex;
    height: 84px;
    max-width: 420px;
    transition: transform 1s ease-in-out;
    width: 250%; /* 2.5 times the width of the slider */
}

.poc-slide {
    width: 40%; /* Each image takes up 40% of the container, leaving 10% empty space to show part of the next image */
    height: 84px;
    object-fit: contain;
    flex-shrink: 0;

    /*position: absolute;*/
    /*width: 100%;*/
    /*height: 180px;*/
    /*object-fit: contain;*/
    /*opacity: 0;*/
    /*top: 0;*/
    /*left: 0;*/
    /*right: 0;*/
    /*bottom: 0;*/
    /*transition: opacity 1s ease-in-out;*/
}

.poc-slide-in {
    animation: slideIn 1s forwards;
}

.poc-slide-out {
    animation: slideOut 1s forwards;
}

@keyframes slideIn {
    0% {
        transform: translateX(100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideOut {
    0% {
        transform: translateX(0);
        opacity: 1;
    }
    100% {
        transform: translateX(-100%);
        opacity: 0;
    }
}
