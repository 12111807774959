.course-details-modal-close {
	position: absolute;
	top: 16px;
	right: 16px;
}

.course-details-modal-title {
	color: #ffffff;
	text-align: center;
	font-family: Gordita-Medium;
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	margin-bottom: 0;
	margin-top: 24px;
	width: 80%;
	margin-left: 10%;
	margin-bottom: 12px;
}

.course-details-modal-input-label {
	color: #ffffff;
	font-family: Gordita-Regular;
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
	margin-bottom: 8px;
	margin-top: 12px;
}

.course-details-modal div.flag-dropdown {
	margin-top: -10px;
}

.course-details-modal div.flag-dropdown {
	margin-top: -10px;
}

.course-details-modal div.flag-dropdown ul.country-list {
	height: 115px;
}
