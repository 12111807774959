.pause-title{
    color: var(--purple-gradient, #4A4A4A);
    font-family:Gordita-Medium;
    font-size: 20px;
    text-align: start;
    font-style: normal;
    font-weight: 500;
    align-self: start;
    width: 324px;
    margin-left: 12px;
    line-height: normal;
}

.pause-list-item{
    color: var(--Body-Font, #4A4A4A);
    text-align: start;
    font-family: Gordita-Regular;
    font-size: 12px;
    font-style: normal;
    align-items:center;
    align-self: center;
    font-weight: 400;
    line-height: 20px; /* 171.429% */
}

.pause-list-item span{
    color: var(--Body-Font, #4A4A4A);
    font-family: Gordita-Bold;
    font-size: 12px;
    text-align: center;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
}
