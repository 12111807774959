.course-list-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	align-self: center;
	width: 100%;
	max-width: 400px;
	position: relative;
	margin: 0 auto;
}

.course-list-component-wrapper {
	display: flex;
	width: 100%;
	/*padding: 0px 16px;*/
	padding-top: 20px;
	padding-bottom: 20px;
	flex-direction: column;
	align-items: center;
	background: #fff;
	margin-top: 24px;
}

.course-list-title {
	color: #fff;
	font-family: Gordita-Bold;
	font-size: 24px;
	font-weight: 700;
	line-height: 28px;
	text-align: left;
	width: calc(100% - 32px);
	margin: 22px 16px 4px;
}

.course-list-title span {
	color: #f6d798;
}

.course-list-subtitle {
	color: #e4e4e4;
	font-family: Gordita-Regular;
	font-size: 12px;
	font-weight: 400;
	line-height: 20px;
	text-align: left;
	width: calc(100% - 32px);
	margin-bottom: 0;
}

.course-list-subtitle span {
	color: #f6d798;
}

.course-list-divider {
	width: calc(100% - 32px);
	margin-top: 34px;
}

.course-list-filters {
	display: flex;
	width: calc(100% - 32px);
	align-items: start;
	gap: 8px;
	margin: 12px 0;
}

.course-list-filters-item {
	border-radius: 1000px;
	padding: 8px 12px;
	font-family: Gordita-Regular;
	font-size: 14px;
	border: 1px solid #4a4a4a;
	color: #e4e4e4;
	cursor: pointer;
	transition: all 0.2s;
	display: flex;
	justify-content: center;
	align-items: center;
}

.course-list-filters-item p {
	margin-bottom: 0;
}

.course-list-filters-item span {
	margin-right: 8px;
}

.course-list-filters-item.selected {
	border: 1px solid #4462ef;
	background-color: rgba(68, 98, 239, 0.22);
}
.dark-background-image {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	width: 100%;
	z-index: -1;
	background: #1b1625;
}
