.testimonial-page-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow-x: hidden;
    align-items: center;
    align-self: center;
    height: 100%;
    margin-top: 24px;
}

.testimonial-header-container {
    display: flex;
    width: 95%;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
}
.testimonial-body-container {
    display: flex;
    width: 93%;
    height: 100%;
    margin-top: 50%;
    position:relative;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.testimonial-body-animation{
    width: 124px;
    height: 48px;
    position: absolute;
    bottom: 48px;
    z-index: -1;
    left: 12px;
}

@media screen and (max-width: 372px) and (max-height: 640px){
    .testimonial-body-animation{
        width: 120px;
        height: 48px;
        z-index: -1;
        position: absolute;
        bottom: 48px;
        left: -12px;
    }
}

/* Media query for screen height of 812px and width of 375px */
@media screen and  (min-width: 372px) and (max-width: 414px) and (min-height: 640px) and (max-height: 740px){
    .testimonial-page-container{
        width: 100%;
        height: 100%;
        align-items: center;
    }
    .testimonial-body-container {
        width: 95%;
        height: 100%;
        position:relative;
        margin-top: 50%;
        align-items: center;

    }
    .testimonial-header-container{
        width: 95%;
        align-items: center;
    }
    .testimonial-body-animation{
        width: 124px;
        height: 48px;
        z-index: -1;
        position: absolute;
        bottom: 48px;
        left: 0px;
    }
}


/* Media query for screen height of 812px and width of 375px */
@media screen and (min-width: 414px) and (min-height: 740px) and (max-height: 912px){
    .testimonial-page-container{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .testimonial-body-container {
        width: 95%;
        height: 100%;
        position:relative;
        margin-top: 50%;
        /*margin-top: 50%;*/
        display: flex;
        justify-content: center;
        align-items: center;

    }
    .testimonial-header-container{
        width: 95%;
        align-items: center;
    }
    .testimonial-body-animation{
        width: 124px;
        height: 48px;
        z-index: -1;
        position: absolute;
        bottom: 48px;
        left: -14px;
    }
}


/* Media query for screen height of 812px and width of 375px */
@media screen and (min-width: 414px) and (min-height: 912px){
    .testimonial-page-container{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .testimonial-body-container {
        width: 95%;
        height: 100%;
        position:relative;
        /*margin-top: 20%;*/
        margin-top: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

    }
    .testimonial-header-container{
        width: 95%;
        align-items: center;
    }
}


/* Additional media queries for other screen sizes if needed */
@media screen and (min-width: 540px) {
    .testimonial-page-container{
        width: 400px;
        height: 100%;
        display: flex;
        align-self: center;
        align-items: center;
    }
    .testimonial-body-container{
        width: 375px;
        height: 100%;
        margin-top: 20%;
        position:relative;
        display: flex;
        align-self: center;
        align-items: center;

    }
    .testimonial-header-container {
        width: 375px;
        display: flex;
        align-self: center;
        align-items: center;

    }
    .testimonial-body-animation{
        width: 124px;
        height: 48px;
        position: absolute;
        bottom: 48px;
        z-index: -1;
        left: 12px;
    }
}


.testimonial-title {
    color: #231DD7;
    text-align: center;
    font-family: Lora-Regular;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.testimonial-subtitle {
    color: #1A1A1A;
    text-align: center;
    font-family: Poppins-Regular;
    font-size: 14px;
    margin-top: 8px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
}

.testimonial-description {
    text-align: start;
    color: #1A1A1A;
    font-family: Poppins-Regular;
    font-size: 14px;
    margin-top: 12px;
    font-style: italic;
    font-weight: 400;
    padding: 0px 8px 0px 0px;
    line-height: 20px; /* 142.857% */
}


