.testimonial-card-container {
	width: 172px;
	height: 220px;
	border-radius: 12px;
	border: 1px solid #e4e4e4;
	overflow: hidden;
}

.testimonial-customer-info {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 8px;
	font-family: Gordita-Medium;
	font-size: 14px;
	margin-top: 6px;
}

.testimonial-customer-name {
	overflow: hidden;
	color: var(--Black, #1b1625);
	text-overflow: ellipsis;
	font-style: normal;
	line-height: 24px;
}

.testimonial-customer-exp {
	color: var(--Font-grey, #a4a4a4);
	text-overflow: ellipsis;
	font-family: Gordita-Regular;
	font-size: 12px;
	text-align: start;
	font-weight: 400;
	padding: 0 8px;
	line-height: 16px; /* 133.333% */
}
