.live-class-card-container {
	display: flex;
	padding: 12px;
	flex-direction: column;
	align-items: flex-start;
	gap: 12px;
	border-radius: 12px;
	background: var(--White, #fff);
	box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);
}

.live-class-card-image-container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	max-width: 72px;
	max-height: 72px;
	border-radius: 4px;
	/*height: auto;*/
}

.live-class-card-horizontal {
	display: flex;
	align-items: start;
}

.live-class-card-image-overlay {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	max-width: 84px;
	min-height: 84px;
	height: auto;
	opacity: 0.5;
}

/* Media query for screen height of 812px and width of 375px */
@media screen and (max-width: 414px) {
	.live-class-card-container {
		width: 90%;
		height: auto;
	}
	.live-class-card-image-container {
		width: 84px;
		height: 84px;
		align-items: center;
	}
	.live-class-card-image-overlay {
		width: 84px;
		height: 84px;
		align-items: center;
		background: rgba(0, 0, 0, 0.5); /* Transparent overlay */
	}
}

/* Additional media queries for other screen sizes if needed */
@media screen and (min-width: 414px) {
	.live-class-card-container {
		width: 400px;
		height: auto;
	}
	.live-class-card-image-container {
		width: 102px;
		height: 102px;
	}
	.live-class-card-image-overlay {
		width: 102px;
		height: 102px;
		background: rgba(0, 0, 0, 0.5); /* Transparent overlay */
	}
}

.live-class-card-content {
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-left: 12px;
	align-self: start;
	/*margin-top: 12px;*/
}

.live-class-card-title {
	color: var(--Black, #1b1625);
	font-family: Gordita-Medium;
	font-size: 16px;
	width: 90%;
	font-style: normal;
	font-weight: 500;
	/*line-height: 24px; !* 150% *!*/
}

.live-class-card-subtitle {
	color: #2a2a2a;
	font-family: Gordita-Regular;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	margin-top: 2px;
}

.live-class-card-class-tag {
	color: #2a2a2a;
	font-family: Gordita-Medium;
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: 18px; /* 150% */
}

.live-class-card-icons-container {
	display: flex;
	flex-direction: row;
	width: 100%;
	gap: 8px;
}

.live-class-card-buttons-container {
	display: flex;
	justify-content: space-around;
	margin-top: 4px;
	width: 100%;
}

.live-class-card-button {
	padding: 10px 20px;
	background-color: #007bff;
	color: white;
	border: none;
	border-radius: 5px;
	cursor: pointer;
}

.live-class-card-button:hover {
	background-color: #0056b3;
}
.top-left-content {
	position: absolute;
	top: 24px; /* Adjust this value to position vertically */
	left: 24px; /* Adjust this value to position horizontally */
	border-radius: 4px;
	padding: 5px 10px; /* Adjust padding as needed */
	color: #faa53d;
	font-family: Gordita-Medium;
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: 16px; /* 133.333% */
}

.live-class-centre-most-content {
	position: absolute;
	top: 25%;
	left: 16%;
	z-index: 10;
	display: flex;
	justify-content: center;
	align-items: center;
	align-self: center;
	transform: translate(-50%, -50%);
	border-radius: 4px;
	padding: 5px 10px; /* Adjust padding as needed */
	color: #000; /* Text color */
	font-size: 14px; /* Font size */
}

.live-class-card-zoom-time {
	font-family: Gordita-Medium;
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	margin-bottom: 0;
	color: #4462ef;
}
