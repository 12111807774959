.checkout-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	max-width: 400px;
}

.checkout-details {
	color: #1b1625;
	font-family: Galaxie-Copernicus-Book;
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	width: 100%;
	max-width: 375px;
	margin-top: 24px;
	text-align: start;
	line-height: normal;
}

.checkout-plan-start-date {
	color: var(--Body-Font, #4a4a4a);
	font-family: Gordita-Medium;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 20px; /* 142.857% */
}

.checkout-plan-start-date-value {
	color: var(--Black, #1b1625);
	text-align: right;
	font-family: Gordita-Medium;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

.checkout-plan-details-container {
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: space-between;
}

.checkout-plan-billing-container {
	display: flex;
	padding: 12px;
	flex-direction: column;
	align-items: center;
	border-radius: 12px;
	max-width: 375px;
	width: 100%;
	margin-top: 12px;
	border: 1px solid #4a4a4a;
}

.checkout-plan-billing-row-container {
	display: flex;
	flex-direction: row;
	width: 100%;
	border-radius: 12px;
}

.checkout-total-amount-pay {
	color: #2a2a2a;
	align-self: center;
	font-family: Poppins-Regular;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}

.checkout-plan-name {
	/*width: 60%;*/
	color: #1a1a1a;
	font-family: Poppins-Regular;
	font-size: 14px;
	width: 220px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

.checkout-plan-discount {
	color: #6a6a6a;
	font-family: Poppins-Regular;
	font-size: 10px;
	font-style: italic;
	font-weight: 500;
	line-height: normal;
}

.checkout-monthly-plan-price {
	color: #3a3a3a;
	font-family: Poppins-Regular;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}
.checkout-monthly-plan-price span {
	color: #3a3a3a;
	font-family: Poppins-Regular;
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

.checkout-plan-price {
	color: #3a3a3a;
	text-align: right;
	/*font-family: DM Sans;*/
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

.checkout-plan-price-total {
	color: #534de0;
	text-align: right;
	/*font-family: DM Sans;*/
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}
.checkout-plan-offer {
	color: var(--Black, #1b1625);
	font-family: Gordita-Medium;
	font-size: 14px;
	font-style: normal;
	align-self: center;
	margin-left: 12px;
	font-weight: 500;
	line-height: 20px; /* 142.857% */
}

.billing-details {
	color: #1a1a1a;
	font-family: Poppins-Regular;
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	margin-top: 36px;
	margin-left: 12px;
	text-align: center;
	align-self: center;
	line-height: normal;
}

.checkout-title {
	color: #2a2a2a;
	font-family: Poppins-Regular;
	margin-left: 4px;
	font-size: 14px;
	text-align: start;
	font-style: normal;
	font-weight: 400;
}

.toolbar-title {
	color: #1a1a1a;
	font-family: Poppins-Medium;
	font-size: 17px;
	font-style: normal;
	white-space: nowrap;
	font-weight: 500;
	line-height: 24px; /* 141.176% */
}

.icon {
	border-radius: 4px;
}
