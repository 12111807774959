.referral-page-container{
    display: flex;
    padding: 16px;
    margin: 32px auto 0 auto;
    width: 375px;
    flex-direction: column;
    justify-content: start;
    align-items: flex-start;
}
.referral-page-header{
    color: #FFF;
    text-align: center;
    font-family: Poppins-Regular;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px; /* 120% */
}

.referral-page-subheader{
    color: #FFF;
    text-align: left;
    align-self: start;
    font-family: Poppins-Regular;
    margin-left:25px;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 150% */
}

.referral-page-title{
    color: #000;
    font-family: Lora-Regular;
    text-align: center;
    align-self: center;
    font-size: 24px;
    font-style: normal;
    margin-top: 26px;
    font-weight: 500;
    line-height: 40px; /* 166.667% */
    letter-spacing: 0.48px;
}

.referral-page-subtitle{
    color: #2A2A2A;
    margin-top: 6px;
    text-align: center;
    font-family: Poppins-Regular;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
}
.referral-page-subtitle span{
    color: #2A2A2A;
    font-family: Poppins-Regular;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.referral-page-code-bg{
    height: 50px;
    width: 150px;
    align-self: center;
    margin-top: 18px;
}
.referral-page-code{
    color: #000;
    text-align: center;
    font-family: Poppins-Regular;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
}
