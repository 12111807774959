/* AppLayout.css */

.grey-app-layout {
	position: relative;
}

.grey-background-image {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	width: 100%;
	z-index: -1;
	background: #f4f4f4;
}
