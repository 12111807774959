.profile-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
}

.profile-component-wrapper {
    display: flex;
    width: 100%;
    /*padding: 0px 16px;*/
    padding-top: 20px;
    padding-bottom: 20px;
    flex-direction: column;
    align-items: center;
    background: #FFF;;
    margin-top: 24px;
}
.profile-setting-tnc{
    color: var(--Body-Font, #4A4A4A);
    font-family: Gordita-Medium;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    text-decoration-line: underline;
}

.profile-successful-title {
    color: #2A2A2A;
    width: 100%;
    text-align: center;
    font-family: Poppins-Regular;
    font-size: 24px;
    margin-top: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.profile-successful-subtitle {
    color: #2A2A2A;
    text-align: center;
    font-family: Poppins-Regular;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.profile-popup-title {
    color: #1A1A1A;
    text-align: center;
    font-family: Lora-Regular;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    margin-top: 16px;
    line-height: 28px; /* 133.333% */
}

.profile-popup-subtitle {
    color: #1A1A1A;
    text-align: center;
    font-family: Poppins-Regular;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
}

.profile-to-renew {
    color: #534DE0;
    text-align: center;
    font-family: Poppins-Regular;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px; /* 150% */
}

.profile-to-renew-subtitle {
    color: #757575;
    text-align: center;
    font-family: Poppins-Regular;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
}

.profile-join-class-through-popup {
    display: flex;
    padding: 16px 12px;
    width: 157px;
    height: 54px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    flex: 1 0 0;
    border-radius: 8px;
    border: 1px solid #534DE0;
    background: #FFF;
    color: #534DE0;
    margin-right: 4px;
    text-align: center;
    font-family: Poppins-Regular;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.profile-renew-plan-through-popup {
    display: flex;
    padding: 16px 12px;
    width: 157px;
    height: 54px;
    color: white;
    justify-content: center;
    align-items: center;
    gap: 4px;
    flex: 1 0 0;
    margin-left: 4px;
    border-radius: 8px;
    background: #534DE0;
    text-align: center;
    font-family: Poppins-Regular;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}


.profile-karma-points {
    color: #FFFFFF;
    font-family: Lora-Regular;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 125% */
}

.profile-karma-points-title {
    color: #534DE0;
    font-family: Poppins-Regular;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}

.profile-home-username {
    color: #FFF;
    text-align: center;
    font-family: Lora-Regular;
    margin-top: 26px;
    align-self: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px; /* 155.556% */
    letter-spacing: 0.36px;
    text-transform: capitalize;
}

.profile-greetings {
    color: #FFF;
    text-align: center;
    font-family: Poppins-Regular;
    align-self: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
}

.profile-empty-Attendance {
    color: #1A1A1A;
    font-family: Poppins-Regular;
    font-size: 16px;
    font-style: normal;
    text-align: center;
    align-self: center;
    font-weight: 600;
    line-height: normal;
}
